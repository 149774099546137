import React, { useState, useContext, useEffect, useRef, useCallback, useMemo  } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from '../WalletContext';
import { DataContext } from '../DataProvider';
import { useImage } from './ImageContext';
import CurtainComponent from './CurtainComponent';
import ImageViewer from './ImageViewer';
import NFTPreviewImage from './NFTPreviewImage';
import { formatAmount, applyFerSpeakForg } from '../utils/formatUtils';
import LegalDisclaimer from './LegalDisclaimer'; // Adjust the path as necessary
import ConnectedIcon from './ConnectedIcon';


import ferCoinLogo from '../fer_coin_logo.png'


const BuyTokens = ({ setIsImageViewerOpen, presaleActive, presaleEnded }) => {
  const [amount, setAmount] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isValidAmount, setIsValidAmount] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const { isAuthenticated,
          isCorrectChain,
          signer,
          contractsReady,
         contracts,
        connect,
        isConnected
      } = useContext(WalletContext);

      const {  
        refreshNFTData,
        allTierImages,
        imagesLoaded,
        refreshData,
        optimizedTierImages,
        ethBalance,
        nftData } = useContext(DataContext);



  const [isLoading, setIsLoading] = useState(true);

  const [referrerName, setReferrerName] = useState('');
  const [tierCounts, setTierCounts] = useState({ 1: 0, 2: 0, 3: 0 });
  const [presaleRate, setPresaleRate] = useState('0');
  const [ethRaised, setEthRaised] = useState('0');

  const [viewerOpen, setViewerOpen] = useState(false);
const [currentImageIndex, setCurrentImageIndex] = useState(0);
const [showPreview, setShowPreview] = useState(false);
const [isHovering, setIsHovering] = useState(false);

const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
const openDisclaimer = () => {
  setIsDisclaimerOpen(true);

};

const closeDisclaimer = () => {
  setIsDisclaimerOpen(false);
};

const [hasConfirmedPurchase, sethasConfirmedPurchase] = useState(false);

const [checkboxes, setCheckboxes] = useState({
  checkbox1: false,
  checkbox2: false,
  checkbox3: false,
  checkbox4: false,
  checkbox5: false,
  checkbox6: false,
});


const handleCheckboxChange = (event) => {
  const { name, checked } = event.target;
  setCheckboxes(prevState => ({
    ...prevState,
    [name]: checked,
  }));
};


const allChecked = Object.values(checkboxes).every(Boolean);


const [closing, setClosing] = useState(false);
useEffect(() => {
  setIsImageViewerOpen(viewerOpen);

}, [viewerOpen, setIsImageViewerOpen]);


let messageLine1 = 'Prefale, Fer?';
let messageLine2 = '';


useEffect(() => {
  if (contractsReady) {
   
    fetchPresaleData();
  }
}, [isAuthenticated, isCorrectChain, contractsReady, contracts]);

const fetchPresaleData = async () => {
  setIsLoading(true);
  try {
    if (!contracts) {
      console.error('ERC20 contract not initialized');
      return;
      
    }
  
    const constantData = await contracts.tokenomicsManagerContract.getConstantTokenomics();
    setPresaleRate(constantData[0].toString());
   
    const dynamicData = await contracts.presaleManagerContract.getDynamicValues();
   
    setEthRaised(ethers.formatEther(dynamicData[0]));
  
  } catch (error) {
    console.error('Failed to fetch presale data:', error);

  } finally {
    setIsLoading(false);
  }
};

  useEffect(() => {
    validateAmount(amount);
    calculateNFTs(amount);
  }, [amount]);

  const { setImage } = useImage();

  useEffect(() => {
    let imageName = 'fer.png';
    if(amount === '4.20'){
      imageName = 'fer_lafer_eyef_fpliff.png';
    }else if (amount >= 1) {
      imageName = 'fer_lafer_eyef.png';
    } else if (amount >= 0.1) {
      imageName = 'fer_big_fmile.png';
    } else if (amount >= 0.01) {
      imageName = 'fer_fmile.png';
    }
    else{
      imageName = 'fer.png';
    }
    setImage(imageName);
  }, [amount, setImage]);

  const validateAmount = (amount) => {
    const isValid = /^\d+(\.\d{1,18})?$/.test(amount) && Number(amount) > 0 && Number(amount) <= 9998.9999;
    setIsValidAmount(isValid);
  };

  const handleAmountChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    if (Number(value) > 9998.9999) {
      value = '9998.9999';
    }
    setAmount(value);
  };



  const calculateNFTs = (amount) => {
    const ethAmount = parseFloat(amount);
    const epsilon = 1e-9;
    if (!isNaN(ethAmount)) {
      let remainingEth = ethAmount;
      const counts = { 1: 0, 2: 0, 3: 0 };

      counts[1] = Math.floor(remainingEth / 1);
      remainingEth = remainingEth - counts[1] * 1;

      counts[2] = Math.floor((remainingEth + epsilon) / 0.1);
      remainingEth = remainingEth - counts[2] * 0.1;

      counts[3] = Math.floor((remainingEth + epsilon) / 0.01);
      remainingEth = remainingEth - counts[3] * 0.01;

      setTierCounts(counts);
    } else {
      setTierCounts({ 1: 0, 2: 0, 3: 0 });
    }
  };

  const updateAmount = (tier, operation) => {
    let newAmount = parseFloat(amount) || 0;
    if (tier === 1) {
      newAmount = operation === 'increase' ? newAmount + 1 : newAmount - 1;
    } else if (tier === 2) {
      newAmount = operation === 'increase' ? newAmount + 0.1 : newAmount - 0.1;
    } else if (tier === 3) {
      newAmount = operation === 'increase' ? newAmount + 0.01 : newAmount - 0.01;
    }
    newAmount = Math.max(newAmount, 0);
    newAmount = Math.min(newAmount, 9998.9999); // Ensure the amount does not exceed the maximum limit
    setAmount(newAmount.toFixed(2));
  };

  const connectWalletAndBuyTokens = async (event) => {
    if (event) event.preventDefault();
    setShowPreview(false);
    if (!isAuthenticated || !signer) {
      alert('Please connect your wallet first');
      return;
    }
  
    setIsProcessing(true);
    setTransactionHash(null);
  
    try {
      const transactionResponse = await contracts.presaleManagerContract.buyTokens(referrerName, {
        value: ethers.parseEther(amount),
      });

      
      setTransactionHash(transactionResponse.hash);
    } catch (error) {
      console.error('Error buying tokens', error);
      if (error.reason) {
        console.error('Error reason:', error.reason);
      }
      if (error.data) {
        console.error('Error data:', error.data);
      }
    }
  };


  const tierNames = {
    1: "Gold Memberfip",
    2: "Filver Memberfip",
    3: "Bronf Memberfip"
  };


  const handlePreview = (event) => {
    event.preventDefault();
    setShowPreview(true);
  };


  
  const handleClosePreview = () => {
    if (!isProcessing && (transactionStatus === 'Confirmed' || transactionStatus === 'Failed')) {
      setClosing(true);
      setIsHovering(false);
      setTimeout(() => {
        setShowPreview(false);
        setClosing(false);
        setTransactionHash(null);
        setTransactionStatus(null);
        setAmount('');
        sethasConfirmedPurchase(true);
      

      }, 500);
  
    } else {
      setClosing(true);
      setIsHovering(false);
      setTimeout(() => {
      setShowPreview(false);
      setClosing(false);
    }, 500);

    }
  };



  const handleConfirmPurchase = async () => {
    if (!allChecked) {
      alert('Please agree to all terms before confirming the purchase.');
      return;
    }

    setIsProcessing(true);
    setTransactionHash(null);
    setTransactionStatus(null);

    try {
      const transactionResponse = await contracts.presaleManagerContract.buyTokens(referrerName, {
        value: ethers.parseEther(amount),
      });
      setTransactionHash(transactionResponse.hash);
      setTransactionStatus('Pending');

      const receipt = await transactionResponse.wait();
      if (receipt.status === 1) {
        setTransactionStatus('Confirmed');
        await refreshData(); // Call the new refreshData function after successful purchase

        
      } else {
        setTransactionStatus('Failed');
      }
    } catch (error) {
      console.error('Error buying tokens', error);
      setTransactionStatus('Failed');
    } finally {
      setIsProcessing(false);
    }
  };






if (viewerOpen) {
    if (currentImageIndex === 0) {
      messageLine1 = "+100 FER FCORE, x1 FREE GOLD FERFONA, FULL MORFER ACFEF";
      messageLine2 = "Very Ekfpenifve!";
    } else if (currentImageIndex === 1) {
      messageLine1 = "Filver Memberfip, Fer!";
      messageLine2 = "Fhiny!";
    } else if (currentImageIndex === 2) {
      messageLine1 = "Bronf Memberfip, Fer!";
      messageLine2 = "Flafhy!";
    }
  }

  else if (amount === '9.99') {
    messageLine1 = 'LOCKING IN,';
    messageLine2 = 'FER?';

    
  } else if (amount === '6.66') {
    messageLine1 = 'Careful, Fer...';
    messageLine2 = ''
   
  } else if (amount === '4.20') {
    messageLine1 = 'Will you take the hit, Fer?';
    messageLine2 = ''
   
  }else if (amount === '4.04') {
    messageLine1 = '<ferComment miffing>';
    messageLine2 = ''
  }
  else if (amount === '3.14') {
    messageLine1 = 'Pie, Fer?';
    messageLine2 = ''
  }
  
  
  else if (amount === '1.23') {
    messageLine1 = 'A, B, Fee';
    messageLine2 = ''
  }else if (amount === '1.11') {
    messageLine1 = 'Gonna caf em all, Fer?';
    messageLine2 = ''
  }else if (amount ==='0.99') {
    messageLine1 = 'Interefting, Fer..';
    messageLine2 = ''
  

  } else if (amount ==='0.90') {
    messageLine1 = 'Full Filver, Fer? Ekfellent!';
    messageLine2 = ''

  }else if (amount ==='0.42') {
    messageLine1 = 'The Anfer,';
    messageLine2 = 'Fer'

  } else if (amount ==='0.09') {
    messageLine1 = 'Full Bronf, Fer? Nife!';
    messageLine2 = ''

  } else if (amount >= 1) {
    messageLine1 = 'Are you worfy, Fer?';
    messageLine2 = ''
  } else if (amount >= 0.1) {
    messageLine1 = 'Ekfellent, Fer!';
     messageLine2 = ''
  } else if (amount >= 0.01) {
    messageLine1 = 'Nife, Fer';
    messageLine2 = ''
  }

  else if (hasConfirmedPurchase){
        messageLine1='Welcome to Order of Fer!'

  } else {
    messageLine1 = 'Prefale, Fer?';
    messageLine2 = '';
  }

  if(isLoading){
    messageLine1 = 'Connecting to fmart contractf...';
    messageLine2 = 'Pafienf, Fer...';

  }else if(!isLoading && !imagesLoaded){
    messageLine1 = '...one fecond pleafe,';
    messageLine2 = 'Fer...';

  }else if(presaleEnded){

      messageLine1 = 'Hmm fomefing wrong,';
      messageLine2 = 'pleafe reload page, Fer';
    
  } else if(!presaleActive && !presaleEnded) {

    messageLine1 = 'Prefale not ftarted yet, Fer';
    messageLine2 = '';

  } 



  const [renderSpeechBubble, setRenderSpeechBubble] = useState(false);

  useEffect(() => {
    setRenderSpeechBubble(true);
    const timer = setTimeout(() => {
      setRenderSpeechBubble(false);
    }, 500); // Duration of the animation
  
    return () => clearTimeout(timer);
  }, [messageLine1, messageLine2]);

  useEffect(() => {
    if (isDisclaimerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isDisclaimerOpen]);

  useEffect(() => {
  const handleEsc = (event) => {
    if (event.key === 'Escape') {
      closeDisclaimer();
    }
  };
  if (isDisclaimerOpen) {
    window.addEventListener('keydown', handleEsc);
  }
  return () => {
    window.removeEventListener('keydown', handleEsc);
  };
}, [isDisclaimerOpen]);

const handleTouchMove = (e) => {
  e.stopPropagation();
};
  
  return (
    <>


{isDisclaimerOpen && (
  <>
    <div
      className={`blurer ${
        closing ? 'close' : 'open'
      } ${
        tierCounts[1] > 0
          ? 'gold'
          : tierCounts[2] > 0
          ? 'silver'
          : tierCounts[3] > 0
          ? 'bronze'
          : ''
      }`}
    />
    <div
  className="preview-window disclaimer"
  onClick={(e) => e.stopPropagation()}
  role="dialog"
  aria-modal="true"
  onTouchMove={handleTouchMove}
  aria-labelledby="disclaimer-title"
>
  <button
    className="close-preview-button"
    onClick={closeDisclaimer}
    aria-label="Close Disclaimer"
  >
    &times;
  </button>
  <div className="disclaimer-content" id="disclaimer-title">
    <LegalDisclaimer />
  </div>
</div>
  </>
)}


{showPreview && !isDisclaimerOpen && (
        <>  
          <div className={`blurer ${closing ? 'close' : 'open'} ${tierCounts[1] > 0 ? 'gold' : tierCounts[2] > 0 ? 'silver' : tierCounts[3] > 0 ? 'bronze' : 'null'}`} />

          <div className={`preview-window ${closing ? 'close' : 'open'}`}>






           
            <div className={`preview-content ${tierCounts[1] > 0 ? 'gold' : tierCounts[2] > 0 ? 'filver' : tierCounts[3] > 0 ? 'bronf' : 'null'}`}>
            <h2 className={`header smol preview ${tierCounts[1] > 0 ? 'gold' : tierCounts[2] > 0 ? 'filver' : tierCounts[3] > 0 ? 'bronf' : 'null'}`} >Preview</h2>
                

                      <div className='tierholder'>
                      {Object.entries(tierCounts).map(([tier, count]) => (
                          <div key={tier} className="tier-preview">
                            <h3 className="textmedium">{tierNames[tier]}: x{count}</h3>
                            {count > 0 && (
                            <div className="nft-grid">
                            {[...Array(count)].map((_, index) => (
                              <NFTPreviewImage key={`${tier}-${index}`} src={optimizedTierImages[tier]} alt={`Tier ${tier}`} />
                            ))}
                            </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="token-summary">
                <p><span className={`titletext ${tierCounts[1] > 0 ? 'gold' : tierCounts[2] > 0 ? 'filver' : tierCounts[3] > 0 ? 'bronf' : 'null'}text`} >
                  
                  +{formatAmount(presaleRate * amount)}
                  <img src={ferCoinLogo} style={{ height: '0.8em', filter:'drop-shadow(0.05vw 0.05vw 2px black)', verticalAlign: 'middle', position:'relative', left:'4px', bottom: '0.1em' }} />
                  <span className='textmedium' style={{marginLeft: '1%', color:'#fff'}}>$FER </span>
                  </span>
                  </p>

                <p className="textmediumsmall">50% received now: {formatAmount(presaleRate * amount * 0.5)}</p>
                <p className="textmediumsmall">50% vested: {formatAmount(presaleRate * amount * 0.5)}</p>
                {transactionStatus && (
                  <p className={`textmedium ${transactionStatus.toLowerCase()}`}>
                    Transaction {transactionStatus}
                    {transactionHash && (
                      <a href={`https://etherscan.io/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer">
                        View on Etherscan
                      </a>
                    )}
                  </p>
                )}
              </div>
            </div>

             {/* Checkboxes Section */}

      
             <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '20px',
          padding: '10px',
          
        
     
          backgroundColor: 'rgb(28 10 0 / 42%)',
          alignItems: 'flex-start'
        }}
         className=' textmediumsmall'
      >
              <span
            onClick={openDisclaimer}
            style={{
            
            
              cursor: 'pointer',
             
              width: 'auto',
              alignSelf: 'center'
            }}
          >
            <u>Read full Difclaimer here</u>
          </span>

        {/* Checkbox 1 */}
        <label className='checkbox-label textmediumsmall' >
        <input
          type="checkbox"
          name="checkbox1"
          checked={checkboxes.checkbox1}
          onChange={handleCheckboxChange}
          className={!checkboxes.checkbox1 ? 'checkbox-red' : ''}
          style={{ marginRight: '10px', cursor: 'pointer' }}
        />
          Memberfip NFTf are "foulbound" and I am NOT ALLOWED to fell, fend or diftribute them.
        </label>

        {/* Checkbox 2 */}
        <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
            className={!checkboxes.checkbox2 ? 'checkbox-red' : ''}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          Af a member of Order of Fer, I will act end behave like a FER both within the community and to otherf.
        </label>

        {/* Checkbox 3 with Disclaimer Link */}
        <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            className={!checkboxes.checkbox3 ? 'checkbox-red' : ''}
            onChange={handleCheckboxChange}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          $FER if a non-fecurity digital token that if diftributed for free when buying Order of Fer Memberfipf, af well af other automatic diftribufion mechanicf within the ecofyftem.
          
        </label>
  
        {/* Checkbox 4 */}
        <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox4"
            checked={checkboxes.checkbox4}
            onChange={handleCheckboxChange}
            className={!checkboxes.checkbox4 ? 'checkbox-red' : ''}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          50% of my free $FER will be vefted for 2 months + 20% of the elapfed prefale time.
        </label>

        {/* Checkbox 5 */}
        <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox5"
            checked={checkboxes.checkbox5}
            className={!checkboxes.checkbox5 ? 'checkbox-red' : ''}
            onChange={handleCheckboxChange}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          Order of Fer fyftem if built on fmart contractf that are completely defentralifed, and carry the rifk of not being fikfable. If there if a problem, the problem if likely forever.
        </label>
          {/* Checkbox 6 */}
                <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox6"
            checked={checkboxes.checkbox6}
            className={!checkboxes.checkbox6 ? 'checkbox-red' : ''}
            onChange={handleCheckboxChange}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
      I have read the Difclaimer
      </label>
      </div>

      {nftData && nftData.length > 0 ? null : (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    
      padding: '10px',
      marginBottom: '1%',
      borderRadius: '0.4vw',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      backgroundColor: 'rgba(28, 10, 0, 0.42)',
    }}
  >
    <label
    className='textmedium'
      htmlFor="referrerName"
      style={{
        marginRight: '10px',
        fontWeight: 'bold',
        fontSize: '16px',
        width: '100px',
      }}
    >
      Referred?
    </label>
    <span
    className='textmedium'
      style={{
        marginRight: '5px',

      }}
    >
      FER
    </span>
    <input
      id="referrerName"
      type="text"
      value={referrerName}
      onChange={(e) => setReferrerName(applyFerSpeakForg(e.target.value.toUpperCase()))}
      placeholder="ENTER FERFONA"
      className='textmedium goldtext'
      style={{
        flex: '1',
        padding: '8px 12px',
        backgroundColor: 'rgba(28, 10, 0, 0.42)',
        borderRadius: '0.15vw',
        textTransform: 'uppercase',
        outline: 'none',
        border:'none',
        maxWidth:'48%',
      }}
    />
  </div>
)}
         
            <div className="preview-buttons">
              {!isProcessing && (transactionStatus === 'Confirmed' || transactionStatus === 'Failed') ? (
                <button className="butn close" onClick={handleClosePreview}>Close</button>
              ) : (
                <>
                  <button className="butn close" onClick={handleClosePreview} disabled={isProcessing}>Close</button>

                  
                  <button 
                    className="butn confirm" 
                    onClick={handleConfirmPurchase} 
                    disabled={isProcessing || transactionStatus === 'Confirmed' || transactionStatus === 'Failed' || !allChecked || ethBalance<amount || !isAuthenticated}
                  >
                    {isProcessing ? 'Processing...' : 
                    !isAuthenticated ? 'Not Connected':
                    ethBalance<amount ? `${ethBalance} / ${amount} ETH` 
                    : `Pay ${amount} ETH`}
                  </button>

                  <ConnectedIcon 
                isConnected={isConnected} 
                isAuthenticated={isAuthenticated} 
               
                onClick={async (e) => {
                  e.stopPropagation();
            
                    await connect();
                  
                }}
              /> 
                </>
              )}
            </div>
          </div>
        </>
      )} 
      {viewerOpen ? (
        <ImageViewer
        images={[optimizedTierImages[1], optimizedTierImages[2], optimizedTierImages[3]]}
        initialIndex={currentImageIndex}
        onClose={() => setViewerOpen(false)}
      />
      ) : (
        <>
           <div  className={`prefalefer ${renderSpeechBubble ? 'bounce' : ''}`}>
  {messageLine1} <br /> {messageLine2}
</div>
   <CurtainComponent startAnimation={(!isLoading && imagesLoaded && contractsReady && presaleActive)} Standby = {false} />


        <div className='row'>
        
      
        </div>
        <div className='row'>
          <div className='col-sm signin'>
          
            <div className='prefale'>
            {isLoading || !imagesLoaded ? (
                <p></p>
              ) : presaleActive ? (
                <>
                  <div className='input-unit-wrapper'>
                  
                  <div className='total' style={{
                    backgroundColor: 
                      tierCounts[1] > 0 ? 'rgba(255, 221, 0, 0.65)' :  
                      tierCounts[2] > 0 ? 'rgba(227, 227, 227, 0.65)' :  
                      tierCounts[3] > 0 ? 'rgba(114, 53, 10, 0.65)' :  
                      'rgba(94, 94, 94, 0.65)'  
                  }}>
                                  
                    <div className='nftpreview'>
                      {Object.keys(tierCounts).map(tier => (
                        <span key={tier} className='nfttier'>
                          <img
                            src={optimizedTierImages[tier]}
                            alt={`Tier ${tier}`}
                            className={`previewimg ${tierCounts[tier] === 0 ? 'disabled' : ''}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentImageIndex(parseInt(tier) - 1);
                              setViewerOpen(true);
                            }}
                          />
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <button className='butn mini minus'
                              onClick={() => updateAmount(parseInt(tier), 'decrease')}
                              disabled={tierCounts[tier] === 0}
                            >
                              -
                            </button>
                            <span className={`totalnft textmega ${tierCounts[tier] === 0 ? 'disabled' : ''}`}>{tierCounts[tier]}</span>
                            <button className='butn mini plus'
                              onClick={() => updateAmount(parseInt(tier), 'increase')}
                              disabled={tierCounts[tier] === (tier === 1 ? 9 : tier === 2 ? 9 : 9)}
                            >
                              +
                            </button>
                          </div>
                        </span>
                      ))}
                    </div>
                 
                
              
                    <div className={`header mini ${!isValidAmount && amount ? 'disabled' : ''} ${tierCounts[1] > 0 ? 'gold' : tierCounts[2] > 0 ? 'silver' : tierCounts[3] > 0 ? 'bronze' : 'null'}`}
 >
                                <span className='textlarge'>
                +{formatAmount(presaleRate * amount)} 
                <img src={ferCoinLogo} style={{ height: '0.8em', filter:'drop-shadow(0.05vw 0.05vw 2px black)', verticalAlign: 'middle', position:'relative', marginBottom: '1.5%', marginLeft:'1%' }} /> 
                <span className={parseFloat(amount) === 0 ? 'textmedium graytext' : 'textmedium'} style={{position: 'absolute', right: '3.5%', bottom: '10%', textShadow: '0.1vw 0.1vw 2px black'}}> $FER</span>
              </span>


                      </div>
                  </div>
                  </div>
                  <div className='input-unit-wrapper'>
                    
                  <button
                      type='button'
                      className={`butn buyfer ${isProcessing || parseFloat(amount) === 0 || parseFloat(amount) > 9998.99 || !isValidAmount || showPreview ? 'disabled' : ''}`}
                      onClick={() => setShowPreview(true)}
                      onMouseEnter={() => !showPreview && setIsHovering(true)}
                      onMouseLeave={() => !showPreview && setIsHovering(false)}
                      disabled={isProcessing || parseFloat(amount) === 0 || parseFloat(amount) > 9998.99 || !isValidAmount}
                    >
                      <span className={parseFloat(amount) === 0 ? 'textmedium graytext' : 'textmedium'}>
                        {isHovering && !showPreview ? 'Preview' : showPreview ? 'Previewing...' : `Total ${amount} ETH`}
                      </span>
                    </button>
                  </div>
      
                </>
              ) : ''}
            </div>
          </div>
        </div>
        
        </>)}
    
        

    </>
  );
  
};

export default BuyTokens;