// WaterMarkText.jsx
import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { isVisible } from '@testing-library/user-event/dist/utils';

const WaterMarkText = ({ fabricCanvas, isAuthenticated, frameVisible }) => {
    const watermarkText = "Edited with MorFER on fer.meme";
    const authenticatedText = "Edited with MorFER on fer.meme"; // Customize as needed

    // Positions for unauthenticated watermarks
    const watermarkPositions = [
        {
            left: fabricCanvas?.width / 2,
            top: fabricCanvas?.height * 0.05,
            originX: 'center',
            originY: 'top',
            angle: 0, // Horizontal
        },
        {
            left: fabricCanvas?.width * 0.05,
            top: fabricCanvas?.height * 0.95,
            originX: 'left',
            originY: 'bottom',
            angle: 0, // Horizontal
        },
        {
            left: fabricCanvas?.width * 0.05,
            top: fabricCanvas?.height / 2,
            originX: 'left',
            originY: 'center',
            angle: -90, // Vertical
        },
    ];

    // Position for authenticated user without frame (Bottom Left)
    const authenticatedTextPosition = {
        left: fabricCanvas?.width * 0.05,
        top: fabricCanvas?.height * 0.95,
        originX: 'left',
        originY: 'bottom',
        angle: 0, // Horizontal
    };

    const watermarkRefs = useRef([]);

    useEffect(() => {
        if (fabricCanvas) {
            const addWatermarks = () => {
                // Remove existing watermarks and authenticated texts
                const existingWatermarks = fabricCanvas.getObjects().filter(obj => obj.isWatermark === true);
                existingWatermarks.forEach(mark => fabricCanvas.remove(mark));

                const existingAuthTexts = fabricCanvas.getObjects().filter(obj => obj.isAuthenticatedText === true);
                existingAuthTexts.forEach(text => fabricCanvas.remove(text));

                // Clear previous references
                watermarkRefs.current = [];

                // Recompute positions based on current canvas size
                const updatedWatermarkPositions = [
                    {
                        left: fabricCanvas.width / 2,
                        top: fabricCanvas.height * 0.05,
                        originX: 'center',
                        originY: 'top',
                        angle: 0,
                        show: !isAuthenticated
                    },
                    {
                        left: fabricCanvas.width * 0.05,
                        top: fabricCanvas.height * 0.95,
                        originX: 'left',
                        originY: 'bottom',
                        angle: 0,
                        show: isAuthenticated
                    },
                    {
                        left: fabricCanvas.width * 0.05,
                        top: fabricCanvas.height * 0.85,
                        originX: 'left',
                        originY: 'center',
                        angle: -90,
                         show: !isAuthenticated
                        
                    },
                ];

                // Add new watermarks if user is not authenticated
                if (!isAuthenticated) {
                    updatedWatermarkPositions.forEach(position => {
                        if (!position.show && !isAuthenticated) return;
                        const text = new fabric.IText(watermarkText, {
                            left: position.left,
                            top: position.top,
                            fontSize: Math.min(fabricCanvas.width * 0.05, 24), // Adjust font size as needed
                            fontFamily: 'Arial',
                            fill: 'rgba(255, 255, 255, 0.2)', // 20% opacity
                            textAlign: 'center',
                            originX: position.originX || 'left',
                            originY: position.originY || 'top',
                            selectable: false,
                            evented: false,
                            isWatermark: true, // Custom property
                            shadow: {
                                color: 'rgba(0, 0, 0, 0.3)',
                                offsetX: 1,
                                offsetY: 1,
                                blur: 2
                            },
                            angle: position.angle || 0,
                        });

                        fabricCanvas.add(text);
                        watermarkRefs.current.push(text);
                    });
                }

                // Add authenticated text if user is authenticated and frame is not visible
                if (isAuthenticated && !frameVisible) {
                    const text = new fabric.IText(authenticatedText, {
                        left: authenticatedTextPosition.left,
                        top: authenticatedTextPosition.top,
                        fontSize: Math.min(fabricCanvas.width * 0.05, 24), // Adjust font size as needed
                        fontFamily: 'Arial',
                        fill: 'rgba(255, 255, 255, 0.2)', // 20% opacity
                        textAlign: 'left',
                        originX: authenticatedTextPosition.originX || 'left',
                        originY: authenticatedTextPosition.originY || 'bottom',
                        selectable: false,
                        evented: false,
                        isAuthenticatedText: true, // Custom property
                        shadow: {
                            color: 'rgba(0, 0, 0, 0.3)',
                            offsetX: 1,
                            offsetY: 1,
                            blur: 2
                        },
                        angle: authenticatedTextPosition.angle || 0,
                    });

                    fabricCanvas.add(text);
                    watermarkRefs.current.push(text);
                }

                // Bring all watermarks to front
                watermarkRefs.current.forEach(watermark => {
                    watermark.bringToFront();
                });

                fabricCanvas.renderAll();
            };

            // Initial addition of watermarks/authenticated text
            addWatermarks();

            // Event handler to keep watermarks and authenticated text on top
            const handleObjectAdded = () => {
                watermarkRefs.current.forEach(watermark => {
                    watermark.bringToFront();
                });
                fabricCanvas.renderAll();
            };

            // Also handle object:modified in case properties change
            const handleObjectModified = () => {
                watermarkRefs.current.forEach(watermark => {
                    watermark.bringToFront();
                });
                fabricCanvas.renderAll();
            };

            // Attach the event listeners
            fabricCanvas.on('object:added', handleObjectAdded);
            fabricCanvas.on('object:modified', handleObjectModified);

            // Cleanup function
            return () => {
                // Remove watermarks and authenticated texts
                watermarkRefs.current.forEach(watermark => {
                    fabricCanvas.remove(watermark);
                });
                watermarkRefs.current = [];

                // Remove event listeners
                fabricCanvas.off('object:added', handleObjectAdded);
                fabricCanvas.off('object:modified', handleObjectModified);
            };
        }
    }, [isAuthenticated, frameVisible, fabricCanvas]);

    return null; // Logical component
};

export default WaterMarkText;
