// NameComponent.jsx

import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'; 
import { ethers, formatUnits } from 'ethers';
import { WalletContext } from '../WalletContext';
import { DataContext } from '../DataProvider';
import { Filter } from 'bad-words';
import notAllowedWordsJson from './notAllowedWords.json';
import { formatAmount, formatAddress } from '../utils/formatUtils';
import './NameComponentCSS.css'; // Import the CSS file
import NameCard from './NameCard';
import RegisterNameCard from './RegisterNameCard';
import ListedNameCard from './ListedNameCard';
import SoldNameCard from './SoldNameCard'; // Import the SoldNameCard component
import CollectedNameCard from './CollectedNameCard';
import MintingOverlay from './MintingOverlay';


// Import tier icons for filters
import filverIcon from '../filvermember.png';
import bronfIcon from '../bronfmember.png';
import goldIcon from '../goldmember.png'

const filter = new Filter();

filter.addWords(...notAllowedWordsJson.notAllowedWords);
// Optional: Add custom bad words
// filter.addWords('customBadWord1', 'customBadWord2');

// Optional: Remove words from the default blacklist
// filter.removeWords('someWord');

// Utility Functions

const getTierName = (tier) => {
  switch(tier) {
    case 1: return 'Gold';
    case 2: return 'Filver';
    case 3: return 'Bronf';
    default: return 'Unknown';
  }
};

// Utility function to safely convert values to numbers
const toNumberSafe = (value) => {
  if (value && typeof value.toNumber === 'function') {
    return value.toNumber();
  } else if (typeof value === 'string') {
    return Number(value);
  } else if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'bigint') {
    return Number(value);
  } else {
    console.error('Unknown type for value:', value);
    return 0;
  }
};

// Utility function to determine the highest tier the user holds
const getHighestTier = (nftData) => {
  if (!nftData || nftData.length === 0) return null; // No tier held

  // Assuming tiers are numeric and lower numbers represent higher tiers
  const tiers = nftData.map(nft => nft.tier);
  return Math.min(...tiers); // Returns the lowest tier number
};

const NameComponent = () => {
  const { 
    isConnected,
    signer,
    isAuthenticated,
    contracts,
    contractsReady,
    isCorrectChain,
  } = useContext(WalletContext);

  const {  
    userAddress,
    optimizedTierImages, 
    dynamicTierImages,
    refreshData,
    imagesLoaded,
    nftData,
    refreshNameData,
    userNamesForSale,
    userNamesSold,
    userNames, 

  } = useContext(DataContext);

  // Existing States
  const [updatedPrices, setUpdatedPrices] = useState({});
  const [isUpdatingPrice, setIsUpdatingPrice] = useState({});
  const [activeSection, setActiveSection] = useState('collected'); // 'liftedFold' or 'soldNames'

  const [newName, setNewName] = useState('');
  const [newTier, setNewTier] = useState(2);
  const [newPrice, setNewPrice] = useState('');
  const [isAddingName, setIsAddingName] = useState(false);
  const [isProcessingMint, setIsProcessingMint] = useState({}); // Updated to object
  const [isProcessingSetMainName, setIsProcessingSetMainName] = useState({}); // Updated to object
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [namesList, setNamesList] = useState([]);
  const [hasMintedTier, setHasMintedTier] = useState({});
  // State to track if user holds Tier1 NFT
  const [holdsTier1, setHoldsTier1] = useState(false);

  const [canMintName, setCanMintName] = useState(false);
  const [eligibleTiers, setEligibleTiers] = useState([]);

  const [mintingNameInfo, setMintingNameInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // New State Variables
  const [maxUnsoldNamesPerUser, setMaxUnsoldNamesPerUser] = useState(null);
  const [unsoldNamesCount, setUnsoldNamesCount] = useState(0);
  const [listingCooldown, setListingCooldown] = useState(0); // in seconds
  const [listingCooldownTimeLeft, setListingCooldownTimeLeft] = useState(); // in seconds
  const [lastNameAddedTime, setLastNameAddedTime] = useState(null);
  const [allowedPricesPerTier, setAllowedPricesPerTier] = useState({}); // Updated from minPricePerTier

  const [isDelistingName, setIsDelistingName] = useState({}); // New state for delisting

  const [currentPage, setCurrentPage] = useState(1);
  const namesPerPage = 25;


  // New State for Hover
  const [hoveredNameId, setHoveredNameId] = useState(null);

  const [isProcessingPriceUpdate, setIsProcessingPriceUpdate] = useState({});

  const highestTier = useMemo(() => getHighestTier(nftData), [nftData]);

  // === New Filter States ===
  const [filterBronf, setFilterBronf] = useState(false);
  const [filterFilver, setFilterFilver] = useState(false);
  const [filterGold, setFilterGold] = useState(false); // Added for Gold

  const [filterFree, setFilterFree] = useState(false);
  const [filterPaid, setFilterPaid] = useState(false);
  const [filterEligible, setFilterEligible] = useState(false);
  const [searchText, setSearchText] = useState('');
  // Add this state near your other state declarations
  const [isSearchActive, setIsSearchActive] = useState(false);
  // === End of New Filter States ===


  const ferminatorName = useMemo(() => {
    return namesList.find(n => n.name === 'FERMINATOR');
  }, [namesList]);
  

  // Fetch new contract parameters
// NameComponent.jsx
const fetchContractParameters = useCallback(async () => {
  if (contractsReady && contracts) {

    try {
      // Fetch maxUnsoldNamesPerUser and listingCooldown
      const [maxUnsold, cooldown] = await Promise.all([
        contracts.nameRegistryContract.maxUnsoldNamesPerUser(),
        contracts.nameRegistryContract.listingCooldown(),
      ]);

      const maxUnsoldNumber = toNumberSafe(maxUnsold);
      const cooldownNumber = toNumberSafe(cooldown);

      // Fetch allowedPricesPerTier for tiers 1, 2, and 3
      const allowedPrices = {};
      for (let tier = 1; tier <= 3; tier++) {
        const prices = await contracts.nameRegistryContract.getAllowedPrices(tier);
        // Convert each price from BigNumber to number with decimals
        allowedPrices[tier] = prices.map(price => Number(ethers.formatUnits(price, 18)));
      }

      setMaxUnsoldNamesPerUser(maxUnsoldNumber);
      setListingCooldown(cooldownNumber);
      setAllowedPricesPerTier(allowedPrices);

    } catch (error) {
      console.error("Error fetching contract parameters:", error);
      setErrorMessage("Failed to fetch contract parameters.");
    } finally {
   
    }
  }
}, [contracts, contractsReady]);


  // Fetch unsold names count and last added time
  const fetchUserAdditionalData = useCallback(async () => {
 
    if (contractsReady && contracts) {
      try {
     
        
        if(isAuthenticated){
        const unsoldCount = await contracts.nameRegistryContract.unsoldNamesCount(userAddress);
        const unsoldCountNumber = toNumberSafe(unsoldCount);
        setUnsoldNamesCount(unsoldCountNumber);

        const lastAddedTime = await contracts.nameRegistryContract.lastNameAddedTime(userAddress);
        const lastAddedTimeNumber = toNumberSafe(lastAddedTime);
        setLastNameAddedTime(lastAddedTimeNumber);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
        // **Use the locally fetched lastAddedTimeNumber instead of the state variable**
        if (lastAddedTimeNumber && (currentTime - lastAddedTimeNumber) < listingCooldown) {
          const remainingTime = listingCooldown - (currentTime - lastAddedTimeNumber);
          setListingCooldownTimeLeft(remainingTime);
        }
        
        }else{
          setUnsoldNamesCount('not connected')
          setLastNameAddedTime(0);
        }

    
      } catch (error) {
        console.error("Error fetching user additional data:", error);
        setErrorMessage("Failed to fetch user data.");
    
      } finally{
    
      }
    }
  }, [isAuthenticated, contracts, contractsReady, userAddress, isCorrectChain, listingCooldown]);
  

  const fetchMintingStatusAndNames = useCallback(async () => {
   
    
    if (contractsReady && contracts) {
      try {
        // Fetch total number of names from NameRegistry
        const totalNames = await contracts.nameRegistryContract.getTotalNames();
        const totalNamesNumber = toNumberSafe(totalNames);
        
        // Fetch names in chunks
        const chunkSize = 100;
        const namesArray = [];
        
        for (let start = 0; start < totalNamesNumber; start += chunkSize) {
          const count = Math.min(chunkSize, totalNamesNumber - start);
          try {
            const nameChunk = await contracts.nameRegistryContract.getNames(BigInt(start), BigInt(count));
            namesArray.push(...nameChunk.map((nameInfo, index) => ({
              tokenId: start + index + 1,
              name: nameInfo.name,
              tier: toNumberSafe(nameInfo.tier),
              minted: nameInfo.minted,
              price: formatAmount(formatUnits(nameInfo.price, 18)),
              priceInt: formatUnits(nameInfo.price, 18),
              creator: nameInfo.creator,
              delisted: nameInfo.delisted,
            })));
          } catch (error) {
            console.error(`Error fetching chunk starting at ${start}:`, error);
            break;
          }
        }
        
        setNamesList(namesArray);
  
        if (userAddress && isAuthenticated && isCorrectChain) {
          try {
            // Fetch hasMintedTier for each tier from NameNFT
            const mintedTiers = {};
            for (let tier = 1; tier <= 3; tier++) {
              const hasMinted = await contracts.nameNFTContract.userMintedTier(userAddress, tier);
              mintedTiers[tier] = hasMinted;
            }
            setHasMintedTier(mintedTiers);
  
            // Determine eligibility for minting based on ownership and minting status
            const eligible = [];
            for (let tier = 1; tier <= 3; tier++) {
              const ownsTierNFT = nftData.some(nft => nft.tier === tier);
              
              if (ownsTierNFT && !mintedTiers[tier]) {
                eligible.push(tier);
              }
            }
            
            setEligibleTiers(eligible);
            setCanMintName(eligible.length > 0);
  
            // Fetch additional user data
            await fetchUserAdditionalData();
          } catch (error) {
            console.error("Error fetching user-specific data:", error);
            setErrorMessage("Failed to fetch user-specific data.");
          }
        } else {
          setHasMintedTier(null);
        }
      } catch (error) {
        console.error("Error fetching minting status and names from NameRegistry:", error);
        setErrorMessage("Failed to fetch names data.");
      } finally {
      
      }
    } else {
      // If contracts aren't ready, still need to reset loading state
     
    }
  }, [
    isAuthenticated,
    contracts,
    contractsReady,
    userAddress,
    isCorrectChain,
    nftData
  ]);

  useEffect(() => {
    const initializeComponent = async () => {
      setIsLoading(true); // Set loading at the very start
      try {
        // Combine all initialization calls into a single Promise.all
        await Promise.all([
          refreshNameData(),
          fetchContractParameters(),
          fetchMintingStatusAndNames()
        ]);
      } catch (error) {
        console.error("Error initializing NameComponent:", error);
        setErrorMessage("Failed to initialize the component. Please try again.");
      } finally {
        setIsLoading(false); // Only unset loading once everything is done
      }
    };
  
    if (contractsReady && contracts) {
      initializeComponent();
    }
  }, [contractsReady, contracts]);

// Modify the Tier1 checking effect to only handle tier status
useEffect(() => {
  if (!isLoading && imagesLoaded && Array.isArray(nftData)) {
    const hasGoldTier = nftData.some(nft => getTierName(nft.tier) === 'Gold');
    setHoldsTier1(hasGoldTier);
  } else {
    setHoldsTier1(false);
  }
}, [nftData, isLoading, imagesLoaded]);

     
  const setMainName = async (tokenId) => {
    setIsProcessingSetMainName(prev => ({ ...prev, [tokenId]: true }));
    try {
      const transaction = await contracts.nameRegistryContract.setMainName(tokenId);
  
      await transaction.wait();
      await refreshNameData();
      await fetchMintingStatusAndNames();
    } catch (error) {
      console.error("Error setting main name:", error);
    } finally {
      setIsProcessingSetMainName(prev => ({ ...prev, [tokenId]: false }));
    }
  };

  
  

  // Function// NameComponent.jsx

// NameComponent.jsx

const handleUpdatePrice = async (tokenId, newPrice) => {
 
  setIsProcessingPriceUpdate((prev) => ({ ...prev, [tokenId]: true }));
  setErrorMessage('');
  setSuccessMessage('');
  try {
    // Input validation and price enforcement
    const parsedPrice = parseFloat(newPrice);
    if (isNaN(parsedPrice) || parsedPrice < 0) {
      setErrorMessage('Price must be a non-negative number.');
      return;
    }

    const name = namesList.find((n) => n.tokenId === tokenId);
    if (name) {
      const tier = name.tier;
      const allowedPrices = allowedPricesPerTier[tier] || [];
      if (!allowedPrices.includes(parsedPrice)) {
        setErrorMessage(
          `Price for Tier ${tier} must be one of the allowed prices: ${allowedPrices.join(
            ', '
          )} $FER.`
        );
        return;
      }
    }

    if (!contracts.nameRegistryContract || !signer) {
      throw new Error('Contracts not loaded or signer not available.');
    }

    const priceInWei = ethers.parseUnits(parsedPrice.toFixed(18), 18);
    const connectedNameRegistry = contracts.nameRegistryContract.connect(signer);
    const transaction = await connectedNameRegistry.updateNamePrice(tokenId, priceInWei);
    
    await transaction.wait();
    

    await refreshNameData();
    fetchMintingStatusAndNames();

    const updatedName =
      userNamesForSale.find((n) => n.tokenId === tokenId)?.name || 'Unknown';
    setSuccessMessage(`Successfully updated the price for "${updatedName}"!`);
  } catch (error) {
    console.error('Error updating name price:', error);
    if (error.code === 4001) {
      setErrorMessage('Transaction rejected by the user.');
    } else {
      setErrorMessage(`Failed to update price: ${error.message}`);
    }
  } finally {
    setIsProcessingPriceUpdate((prev) => ({ ...prev, [tokenId]: false }));
  }
};



  // Function to mint a name
  const [showMintingOverlay, setShowMintingOverlay] = useState(false);

// Inside NameComponent.jsx
const mintName = async (tokenId) => {
  // Guard: Prevent multiple minting processes for the same tokenId
  if (isProcessingMint[tokenId]) {
    console.warn(`Minting already in progress for tokenId: ${tokenId}`);
    return;
  }

  const nameToMint = namesList.find(n => n.tokenId === tokenId);
  const isFerminator = nameToMint?.name === 'FERMINATOR';

  // Quick eligibility check before starting any process
  if (isFerminator && (!highestTier || highestTier > nameToMint.tier)) {
  
    return; // Exit early without showing overlay or starting mint process
  }
  
  setIsProcessingMint(prev => ({ ...prev, [tokenId]: true }));
  setMintingNameInfo(nameToMint);
  setErrorMessage('');
  setSuccessMessage('');

  let overlayTimer = null;

  try {
    // Validate Signer and Contracts first
    if (!signer) {
      throw new Error("Signer is not available. Please connect your wallet.");
    }
    if (!contracts.nameNFTContract || !contracts.nameRegistryContract || !contracts.coreERC20Contract) {
      throw new Error("Contracts are not initialized.");
    }

    if (isFerminator) {
      // For Ferminator, start overlay timer only after confirming we should proceed
      overlayTimer = setTimeout(() => {
        setShowMintingOverlay(true);
      }, 3000);
    } else {
      // For non-Ferminator names, show overlay immediately
      setShowMintingOverlay(true);
    }

    // Rest of minting logic
    const nameInfo = await contracts.nameRegistryContract.getName(tokenId);
    if (!nameInfo) {
      throw new Error("Failed to fetch name information.");
    }
    
    const price = nameInfo.price;

    if (price > 0) {
      const allowance = await contracts.coreERC20Contract.allowance(userAddress, contracts.nameNFTContract);
      if (allowance < price) {
        const approveTx = await contracts.coreERC20Contract.connect(signer).approve(contracts.nameNFTContract, price);
        await approveTx.wait();
      }
    }

    const connectedNameNFTContract = contracts.nameNFTContract.connect(signer);
    const transaction = await connectedNameNFTContract.mintName(tokenId);
    await transaction.wait();

    await refreshNameData();
    await refreshData();
    fetchMintingStatusAndNames();
    setSuccessMessage(`Successfully minted name!`);
    
  } catch (error) {
    // Clear the timer if there's an error
    if (overlayTimer) {
      clearTimeout(overlayTimer);
    }
    console.error("Error minting name:", error);
    if (error.code === 4001) {
      setErrorMessage("Transaction rejected by the user.");
    } else {
      setErrorMessage(`Failed to mint name: ${error.message}`);
    }
  } finally {
    // Clear the timer if it still exists
    if (overlayTimer) {
      clearTimeout(overlayTimer);
    }
    setIsProcessingMint(prev => ({ ...prev, [tokenId]: false }));
    setMintingNameInfo(null);
    setShowMintingOverlay(false);
  }
};


  // Function to register a new name (Paid Tiers)
  const registerNewName = async (e) => {

    setErrorMessage('');
    setSuccessMessage('');
    

    // Input Validation
    if (!/^[A-Z]{1,14}$/.test(newName)) { // Updated to match Solidity's uppercase requirement and max 14 chars
      setErrorMessage("Name must be 1-14 uppercase alphabetic characters.");
      return;
    }

    // Check for profanity
    if (isProfane(newName)) {
      setErrorMessage("The name contains inappropriate language. Please choose a different name.");
      return;
    }

    if (![2, 3].includes(Number(newTier))) { // Updated to reflect tiers 2 and 3
      setErrorMessage("Invalid tier selected.");
      return;
    }

    // Ensure user holds Tier1 (Gold) NFT
    if (!holdsTier1) {
      setErrorMessage("You must hold a Gold NFT to register Filver or Bronf names.");
      return;
    }

    // Ensure a price has been selected
    if (!newPrice) {
      setErrorMessage("Please select a price for your name.");
      return;
    }

    // Enforce allowed price per tier
    const allowedPrices = allowedPricesPerTier[newTier] || [];
    if (!allowedPrices.includes(newPrice)) {
      setErrorMessage(`Selected price must be one of the allowed prices: ${allowedPrices.join(', ')} $FER.`);
      return;
    }

    // Enforce max unsold names per user
    if (maxUnsoldNamesPerUser !== null && unsoldNamesCount >= maxUnsoldNamesPerUser) {
      setErrorMessage(`You cannot have more than ${maxUnsoldNamesPerUser} unsold names.`);
      return;
    }

    // Enforce listing cooldown
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (lastNameAddedTime && (currentTime - lastNameAddedTime) < listingCooldown) {
      const remainingTime = listingCooldown - (currentTime - lastNameAddedTime);
      setListingCooldownTimeLeft(remainingTime)
      setErrorMessage(`You must wait ${remainingTime} seconds before listing a new name.`);
      return;
    }

    setIsAddingName(true);
    try {
      // Log contract and signer
   
      // Check if user is blacklisted
      const isBlacklisted = await contracts.nameRegistryContract.blacklist(userAddress);

      if (isBlacklisted) {
        setErrorMessage("You are blacklisted and cannot register names.");
        setIsAddingName(false);
        return;
      }
     
      const priceInWei = ethers.parseUnits(newPrice.toString(), 18);
  
      
   
      const connectedNameRegistry = contracts.nameRegistryContract.connect(signer);
      const transaction = await connectedNameRegistry.addNameByTier1(newName, Number(newTier), priceInWei);
      
      await transaction.wait();
    

      await refreshNameData();
      await fetchMintingStatusAndNames();
      setSuccessMessage(`Successfully registered the name "${newName}"!`);
      setNewName('');
      setNewTier(2);
    } catch (error) {
      console.error("Error registering new name:", error);
      if (error.code === 4001) {
        setErrorMessage("Transaction rejected by the user.");
      } else if (error.message.includes("Address is blacklisted")) {
        setErrorMessage("You are blacklisted and cannot register names.");
      } else if (error.message.includes("Exceeded maximum unsold names")) {
        setErrorMessage(`You cannot have more than ${maxUnsoldNamesPerUser} unsold names.`);
      } else if (error.message.includes("Listing cooldown not met")) {
        setErrorMessage("You must wait before listing another name.");
      } else {
        setErrorMessage(`Failed to register name: ${error.message}`);
      }
    } finally {
      setIsAddingName(false);
    }
  };

  // Function to register a new name (Free Tier - Gold)
  const registerNewNameOwner = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
  

    // Input Validation
    if (!/^[A-Z]{1,14}$/.test(newName)) { // Updated to match Solidity's uppercase requirement and max 14 chars
      setErrorMessage("Name must be 1-14 uppercase alphabetic characters.");
      return;
    }

    // Check for profanity
   
    if (filter.isProfane(newName)) {
      setErrorMessage("The name contains inappropriate language. Please choose a different name.");
      return;
    }

    if (Number(newTier) !== 1) { // Updated to reflect tier 1
      setErrorMessage("Invalid tier selected for free registration.");
      return;
    }

    // Enforce max unsold names per user
    if (maxUnsoldNamesPerUser !== null && unsoldNamesCount >= maxUnsoldNamesPerUser) {
      setErrorMessage(`You cannot have more than ${maxUnsoldNamesPerUser} unsold names.`);
      return;
    }

    // Enforce listing cooldown
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (lastNameAddedTime && (currentTime - lastNameAddedTime) < listingCooldown) {
      const remainingTime = listingCooldown - (currentTime - lastNameAddedTime);
      setErrorMessage(`You must wait ${remainingTime} seconds before listing a new name.`);
      return;
    }

    setIsAddingName(true);
    try {
      // Log contract and signer

      // Check if user is blacklisted
      const isBlacklisted = await contracts.nameRegistryContract.blacklist(userAddress);
   
      if (isBlacklisted) {
        setErrorMessage("You are blacklisted and cannot register names.");
        setIsAddingName(false);
        return;
      }

 
      const connectedNameRegistry = contracts.nameRegistryContract.connect(signer);
      const transaction = await connectedNameRegistry.addNameByOwner(newName, Number(newTier));
     
      await transaction.wait();
    

      await refreshNameData();
      fetchMintingStatusAndNames();
      setSuccessMessage(`Successfully registered the name "${newName}"!`);
      setNewName('');
      setNewTier(2);
    } catch (error) {
      console.error("Error registering new name:", error);
      if (error.code === 4001) {
        setErrorMessage("Transaction rejected by the user.");
      } else if (error.message.includes("Address is blacklisted")) {
        setErrorMessage("You are blacklisted and cannot register names.");
      } else if (error.message.includes("Exceeded maximum unsold names")) {
        setErrorMessage(`You cannot have more than ${maxUnsoldNamesPerUser} unsold names.`);
      } else if (error.message.includes("Listing cooldown not met")) {
        setErrorMessage("You must wait before listing another name.");
      } else {
        setErrorMessage(`Failed to register name: ${error.message}`);
      }
    } finally {
      setIsAddingName(false);
    }
  };

  

  useEffect(() => {
    setCurrentPage(1);
  }, [
    filterBronf,
    filterFilver,
    filterGold,
    filterFree,
    filterPaid,
    filterEligible,
    searchText,
  ]);

  // === New Filtering Logic ===
  // Within the useMemo for filteredOthers
const filteredOthers = useMemo(() => {
  let filtered = namesList.filter(name => !name.minted && !name.delisted && name.name !== 'FERMINATOR');

  // Apply Tier Filters (Gold, Filver, Bronf)
  if (filterGold || filterFilver || filterBronf) {
    filtered = filtered.filter(name => {
      if (filterGold && name.tier === 1) return true;
      if (filterFilver && name.tier === 2) return true;
      if (filterBronf && name.tier === 3) return true;
      return false;
    });
  }

  // Apply Listing Type Filters (Free and Paid)
  if (filterFree || filterPaid) {
    filtered = filtered.filter(name => {
      if (filterFree && name.price === '0') return true;
      if (filterPaid && name.price !== '0') return true;
      return false;
    });
  }

  // Apply Eligibility Filter
  if (filterEligible) {
    filtered = filtered.filter(name => {
      // Include free names that user is eligible to mint
      const isFreeAndEligible = (
        eligibleTiers.includes(name.tier) && // User is eligible for this tier
        name.price === '0' &&               // The tier is free
        !name.minted                        // The name hasn't been minted yet
      );

      // Include paid names that user can mint (has appropriate tier NFT)
      const isPaidAndEligible = (
        name.price !== '0' &&              // The name is paid
        highestTier <= name.tier &&        // Corrected comparison
        !name.minted                       // The name hasn't been minted yet
      );

      return isFreeAndEligible || isPaidAndEligible;
    });
  }

  // Apply Search Filter
  if (searchText.trim() !== '') {
    const lowerSearch = searchText.toLowerCase();
    filtered = filtered.filter(name => {
      const nameLower = name.name.toLowerCase();
      return (
        nameLower.startsWith(lowerSearch) ||
        nameLower.includes(lowerSearch)
      );
    });
  }

  // Shuffle the filtered names
  const shuffled = [...filtered].sort(() => Math.random() - 0.5);

  return shuffled;
}, [
  namesList,
  filterGold,
  filterFilver,
  filterBronf,
  filterFree,
  filterPaid,
  filterEligible,
  searchText,
  highestTier,
  eligibleTiers, // Ensure eligibleTiers is included in dependencies
]);

  

  
  const paginatedNames = useMemo(() => {
    const indexOfLastName = currentPage * namesPerPage;
    const indexOfFirstName = indexOfLastName - namesPerPage;
    return filteredOthers.slice(indexOfFirstName, indexOfLastName);
  }, [filteredOthers, currentPage]);


  const totalPages = Math.ceil(filteredOthers.length / namesPerPage);
  
  // === End of New Filtering Logic ===

  const isProfane = (name) => {

    return filter.isProfane(name);
  };
  // NameComponent.jsx (Within the return statement)

  // NameComponent.jsx

  const handleRegister = async () => {
    if (newTier === 1) {
      await registerNewNameOwner();
    } else {
      await registerNewName();
    }
  };


   // Handle Delist Name
   const handleDelistName = async (tokenId) => {
 
    setIsDelistingName((prev) => ({ ...prev, [tokenId]: true }));
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      if (!contracts.nameRegistryContract || !signer) {
        throw new Error('Contracts not loaded or signer not available.');
      }
      
      const connectedNameRegistry = contracts.nameRegistryContract.connect(signer);
      const transaction = await connectedNameRegistry.delistName(tokenId);
     
      
      await transaction.wait();
  
      
      await refreshNameData();
      fetchMintingStatusAndNames();
      
      const delistedName = namesList.find((n) => n.tokenId === tokenId)?.name || 'Unknown';
      setSuccessMessage(`Successfully delisted the name "${delistedName}"!`);
    } catch (error) {
      console.error('Error delisting name:', error);
      if (error.code === 4001) {
        setErrorMessage('Transaction rejected by the user.');
      } else {
        setErrorMessage(`Failed to delist name: ${error.message}`);
      }
    } finally {
      setIsDelistingName((prev) => ({ ...prev, [tokenId]: false }));
    }
  };


  return (
    <div className='largecomponentholder nameholder'>
     {showMintingOverlay && Object.values(isProcessingMint).some(Boolean) && mintingNameInfo && (
  <MintingOverlay 
    name={mintingNameInfo}
    tier={mintingNameInfo.tier}
    />
  )}
      <div className="title ferfona">
  <span className="titletext large">FERFona</span>
  
  
  <div className='ferminatorholder'>
  {ferminatorName && !ferminatorName.minted && (
  <NameCard
    key={ferminatorName.tokenId}
    name={ferminatorName}
    tier={ferminatorName.tier}
    eligible={highestTier !== null && highestTier <= ferminatorName.tier && !ferminatorName.minted}
    onMint={(tokenId) => {
      // Only proceed if eligible
      if (highestTier !== null && highestTier <= ferminatorName.tier && !ferminatorName.minted) {
        mintName(tokenId);
      }
      // If not eligible, do nothing - this prevents the popup
    }}
    isProcessing={isProcessingMint[ferminatorName.tokenId]}
    isFerminator={true}
  />
)}
  </div>
</div>

      <div className='name-component'>
        {/* Loading Indicator */}
        {isLoading && (
          <div className='loading-overlay'>
            <p>Loading...</p>
          </div>
        )}

        {/* Display error and success messages 
        {errorMessage && (
          <div className='error-message'>
            <p>{errorMessage}</p>
          </div>
        )}
        {successMessage && (
          <div className='success-message'>
            <p>{successMessage}</p>
          </div>
        )}
      */}
        {/* Main Content */}
        <div className='main-content' style={{ display: 'flex' }}>

          {/* Gold Names Section (20%) */}
          <div className='gold-names-section' style={{ flex: '1 1 20%', paddingRight: '20px' }}>
            {/* Form to Register New Name */}
       
          {/* Register New Name Section */}
          <RegisterNameCard
            newName={newName}
            setNewName={setNewName}
            newTier={newTier}
            setNewTier={setNewTier}
            newPrice={newPrice}
            setNewPrice={setNewPrice}
            handleRegister={handleRegister}
            isAddingName={isAddingName}
            allowedPricesPerTier={allowedPricesPerTier}
            holdsTier1={holdsTier1}
            userNamesForSale={userNamesForSale}
            listingCooldownTimeLeft={listingCooldownTimeLeft}
          />


<div className='user-listed-names-section'>
  <div className='listed-names-tab'>


  <span
    className={`textmedium tab ${activeSection === 'collected' ? 'active' : ''}`}
    onClick={() => setActiveSection('collected')}
    style={{ cursor: 'pointer' }}
    
    >Collected</span>


<span
  className={`textmedium tab ${activeSection === 'lifted' ? 'active' : ''}`}
  onClick={() => setActiveSection('lifted')}
  style={{ cursor: 'pointer' }}
>
  Lifted </span>
  
  <span
    className={`textmedium tab ${activeSection === 'fold' ? 'active' : ''}`}
    onClick={() => setActiveSection('fold')}
    style={{ cursor: 'pointer' }}
    
    >Fold</span>



  </div>

  {/* Collected Names Tab */}
  {activeSection === 'collected' && ( 
    <div className='names-grid tab collected'>
      {userNames.length > 0 ? (
        userNames.map((name) => (
          <CollectedNameCard
            key={name.tokenId}
            name={name}
            setMainName={setMainName}
            isProcessing={isProcessingSetMainName[name.tokenId]}
          />
        ))
      ) : (
        <span className='textmedium noferfona'>No FERFonaf</span>
      )}
    </div>
  )}

  {activeSection === 'lifted' && ( 
  <div className='names-grid tab'>
    {userNamesForSale.length > 0 ? (
      userNamesForSale.map((name) => (
      <ListedNameCard
        key={name.tokenId}
        name={name}
        allowedPricesPerTier={allowedPricesPerTier}
        onUpdatePrice={handleUpdatePrice}
        onDelist={handleDelistName} // Pass the delist handler
        isProcessing={isProcessingPriceUpdate[name.tokenId]}
        isDelisting={isDelistingName[name.tokenId]} // Pass the delisting state
      />
      ))
    ) : (
      <span className='textmedium noferfona'>No lifted FERFonaf</span>
    )}
  </div>
  )}

</div>


{activeSection === 'fold' && ( 
    <div className='user-sold-names-section'>

              <div className='names-grid tab'>
                {userNamesSold.length > 0 ? (
                  userNamesSold.map((name) => (
                    <SoldNameCard
                      key={name.tokenId}
                      name={name}
                    />
                  ))
                ) : (
                  <span className='textmedium noferfona'>No fold FERFonaf</span>
                )}
              </div>
            </div>
)}
            
          </div> 

          {/* Other Names Section (70%) */}
          <div className='other-names-section' style={{ flex: '1 1 70%' }}>
            <div className='header-filter-holder'>
              <h3 className='other-names-header textlarge'>Who are you, Fer?</h3>
              
              {/* === New Filter Options UI === */}
              <div className='filter-options'>
              <div className='tier-filters'>

                    {/* Gold Filter Icon */}
                    <div
                className={`filter-icon ${filterGold ? 'active' : ''}`}
                onClick={() => setFilterGold(!filterGold)}
                title='Gold'
              >
                <img src={goldIcon} alt='Gold Filter' width='100%' />
              </div>

              {/* Filver Filter Icon */}
              <div
                className={`filter-icon ${filterFilver ? 'active' : ''}`}
                onClick={() => setFilterFilver(!filterFilver)}
                title='Filver'
              >
                <img src={filverIcon} alt='Filver Filter' width='100%' />
              </div>

              {/* Bronf Filter Icon */}
              <div
                className={`filter-icon ${filterBronf ? 'active' : ''}`}
                onClick={() => setFilterBronf(!filterBronf)}
                title='Bronf'
              >
                <img src={bronfIcon} alt='Bronf Filter' width='100%' />
              </div>
            </div>


                {/* === Replace Checkbox Group with Filter Labels === */}
                <div className='filter-buttons' style={{ display: 'flex', gap: '1vw' }}>
                {/* Free Filter */}
                <div
                  className={`filter-button ${filterFree ? 'active' : ''}`}
                >
                  <span
                    className='textmedium filtertext'
                    onClick={() => setFilterFree(!filterFree)}
                  >
                    Free
                  </span>
                </div>

                {/* Paid Filter */}
                <div
                  className={`filter-button ${filterPaid ? 'active' : ''}`}
                >
                  <span
                    className='textmedium filtertext'
                    onClick={() => setFilterPaid(!filterPaid)}
                  >
                    Paid
                  </span>
                </div>

                {/* Eligible Filter */}
                <div
                  className={`filter-button filtertext ${filterEligible ? 'active' : ''}`}
                >
                  <span
                    className='textmedium'
                    onClick={() => setFilterEligible(!filterEligible)}
                    title={eligibleTiers.length === 0 ? 'No eligible names' : 'Toggle Eligible Filter'}
                  >
                    Eligible
                    {eligibleTiers.length === 0 && (
                      <span style={{ color: '#aaa', marginLeft: '5px' }}></span>
                    )}
                  </span>
                </div>
              </div>

                {/* === End of Filter Labels === */}

                {/* Search Input */}
                <div className={`search-group ${isSearchActive ? 'active' : ''}`}>
  {!isSearchActive ? (
    <button
      className='butn medium search-button'
      onClick={() => setIsSearchActive(true)}
      title='Search Names'
    >
      🔍
    </button>
  ) : (
    <div className='search-input-container' style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type='text'
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder='Fearf FERFona'
        className='textmedium search-input'
        style={{ width: '200px', padding: '5px' }}
      />
      <button
        className='butn smol close textmediumsmall'
        onClick={() => {
          setSearchText('');
          setIsSearchActive(false);
        }}
        title='Clear Search'
        style={{ marginLeft: '0.3vw', padding: '0.3vw' }}
      >
        X
      </button>
    </div>
  )}
</div>

              </div>
              {/* === End of New Filter Options UI === */}
            </div>

            {/* Names Grid */}
            {/* Names Grid */}
            {/* Pagination Controls */}
  
            <div className='filter-buttons' style={{ display: 'flex', gap: '1vw' }}>
  {/* Previous Button */}
  <div
    className={`filter-button ${currentPage === 1 ? 'disabled' : ''}`}
    style={{ opacity: currentPage === 1 ? 0.5 : 1 }}
  >
    <span
      className='textmedium filtertext'
      onClick={() => {
        if (currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }
      }}
      title={currentPage === 1 ? 'Already on the first page' : 'Go to previous page'}
    >
      &lt;
    </span>
  </div>

  {/* Page Numbers */}
  {[...Array(totalPages)].map((_, index) => (
    <div
      key={index}
      className={`filter-button ${currentPage === index + 1 ? 'active' : ''}`}
    >
      <span
        className='textmedium filtertext'
        onClick={() => setCurrentPage(index + 1)}
      >
        {index + 1}
      </span>
    </div>
  ))}

  {/* Next Button */}
  <div
    className={`filter-button ${currentPage === totalPages ? 'disabled' : ''}`}
    style={{ opacity: currentPage === totalPages ? 0.5 : 1 }}
  >
    <span
      className='textmedium filtertext'
      onClick={() => {
        if (currentPage < totalPages) {
          setCurrentPage(prev => prev + 1);
        }
      }}
      title={currentPage === totalPages ? 'Already on the last page' : 'Go to next page'}
    >
      &gt;
    </span>
  </div>
</div>



            <div className='names-grid'>
            {paginatedNames.length > 0 ? (
    paginatedNames.map(name => {
      const isFreeAndEligible = 
        eligibleTiers.includes(name.tier) && // Must have exactly that tier
        name.price === '0' &&                // It's a free name
        !name.minted;                        // Not minted yet

        const isPaidAndEligible = 
        name.price !== '0' &&               // It's a paid name
        highestTier <= name.tier &&         // Corrected comparison
        !name.minted;                       // Name hasn't been minted yet

      const eligible = isFreeAndEligible || isPaidAndEligible;
      
      return (
        <NameCard
          key={name.tokenId}
          name={name}
          tier={name.tier}
          eligible={eligible}
          onMint={mintName}
          isProcessing={isProcessingMint[name.tokenId]}
        />
      );
    })
  ) : (
    <p>No names available.</p>
  )}
            </div>


            
            {/* Names Grid */}
            {/* Names Grid */}
            {/* Pagination Controls */}
  
            <div className='filter-buttons' style={{ display: 'flex', gap: '1vw' }}>
  {/* Previous Button */}
  <div
    className={`filter-button ${currentPage === 1 ? 'disabled' : ''}`}
    style={{ opacity: currentPage === 1 ? 0.5 : 1 }}
  >
    <span
      className='textmedium filtertext'
      onClick={() => {
        if (currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }
      }}
      title={currentPage === 1 ? 'Already on the first page' : 'Go to previous page'}
    >
      &lt;
    </span>
  </div>

  {/* Page Numbers */}
  {[...Array(totalPages)].map((_, index) => (
    <div
      key={index}
      className={`filter-button ${currentPage === index + 1 ? 'active' : ''}`}
    >
      <span
        className='textmedium filtertext'
        onClick={() => setCurrentPage(index + 1)}
      >
        {index + 1}
      </span>
    </div>
  ))}

  {/* Next Button */}
  <div
    className={`filter-button ${currentPage === totalPages ? 'disabled' : ''}`}
    style={{ opacity: currentPage === totalPages ? 0.5 : 1 }}
  >
    <span
      className='textmedium filtertext'
      onClick={() => {
        if (currentPage < totalPages) {
          setCurrentPage(prev => prev + 1);
        }
      }}
      title={currentPage === totalPages ? 'Already on the last page' : 'Go to next page'}
    >
      &gt;
    </span>
  </div>
</div>


           
          </div>

        </div>
      </div>
    </div>
  );

    
};

export default NameComponent;
