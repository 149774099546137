// WalletContext.js

import React, { createContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { ethers } from 'ethers';
import web3Modal from './web3modal'; // Ensure this is correctly imported
import { SiweMessage } from 'siwe';
import config from './config';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { decodeToken, setAuthToken, getAuthToken, removeAuthToken, clearAllAuthData } from './authUtils';
import { DataContext } from './DataProvider';

import MemeNFTABI from './abis/MemeNFT.json';
import { initializeContracts } from './contractHelper';
import { svgToPng } from './utils/imageUtils'; // Assuming you have this utility
import { isMobile } from 'web3modal';

// Create a WalletContext for global state management
export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  // ------------------- State Variables -------------------
  const [userAddress, setUserAddress] = useState('');
  const [signer, setSigner] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [userTier, setUserTier] = useState('');
  const [connectionError, setConnectionError] = useState(null);
  const [contracts, setContracts] = useState({});
  const [contractsReady, setContractsReady] = useState(false);
  const [isCorrectChain, setIsCorrectChain] = useState(true);

  // Ref to track authentication attempts
  const authAttemptedRef = useRef(false);

  // ------------------- Web3Modal Hooks -------------------
  const { walletProvider } = useWeb3ModalProvider();
  const { address: modalAddress, isConnected, chainId } = useWeb3ModalAccount();

  // ------------------- Read-Only Provider -------------------
  // Initialize a read-only provider using a default RPC URL from config
  const readOnlyProvider = useMemo(() => {
    if (config.readOnlyRpcUrl) {
      return new ethers.JsonRpcProvider(config.readOnlyRpcUrl, config.chain);
    } else {
      console.warn("Read-only RPC URL not defined in config. Contracts will not be initialized in read-only mode.");
      return null;
    }
  }, []);

  // ------------------- Utility Functions -------------------

  const isMobileDevice = () => {
    if (typeof navigator === 'undefined') return false;
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  // Function to reset background (if needed)
  const resetBackground = useCallback(() => {
    const navigation = document.querySelector('.navigafon');
    if (navigation) {
      navigation.querySelectorAll('.navigafon-background').forEach(bg => bg.remove());
      navigation.querySelectorAll('.useraddress').forEach(bg => {
        bg.className = 'useraddress textmedium';
      });
      navigation.className = 'navigafon not-a';
    }
  }, []);

  // Error handling function
  const handleError = useCallback((error, context) => {
    console.error(`Error in ${context}:`, error);
    setConnectionError(`${context}: ${error.message}`);
  }, []);

  // ------------------- Authentication Functions -------------------

  const connect = useCallback(async () => {
    try {
      await web3Modal.open();
    } catch (error) {
      handleError(error, 'connect');
    }
  }, [web3Modal, handleError]);

  const logout = useCallback(async () => {
    clearAllAuthData();
    setIsAuthenticated(false);
    setUserAddress('');
    setSigner(null);
    setAuthError(null);
    setUserTier('');
    setContracts({});
    setContractsReady(false);
    setIsCorrectChain(false);
    resetBackground();
    authAttemptedRef.current = false; // Reset authentication attempt
    removeAuthToken();

    try {
      await web3Modal.disconnect();
    } catch (error) {
      console.error("Error during disconnect:", error);
    }
    localStorage.removeItem('isCurtainAnimated', 'false');
    
    // Add a slight delay to ensure all state updates and cleanup are completed
    setTimeout(() => {
      window.location.reload();
    }, 1000);
}, [web3Modal, resetBackground]);

  /**
   * Fetch the authentication challenge from the server.
   * @param {string} address - The user's Ethereum address.
   * @returns {object} - An object containing the message and challengeData.
   */
  const getChallengeFromServer = useCallback(async (address) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/public/get_challenge.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to get challenge: ${errorText}`);
      }
      const data = await response.json();
      return { message: data.message, challengeData: data.challengeData };
    } catch (error) {
      console.error('Error getting challenge:', error);
      throw error;
    }
  }, []);

  /**
   * Verify the signature with the server.
   * @param {string} address - The user's Ethereum address.
   * @param {string} signature - The signature generated from signing the SIWE message.
   * @param {string} siweMessage - The SIWE message that was signed.
   * @param {string} challengeData - The challenge nonce data.
   * @returns {object} - The server's verification response.
   */
  const verifySignatureWithServer = useCallback(async (address, signature, siweMessage, challengeData) => {
    try {
      ////console.log("Sending to server:", { address, signature, siweMessage, challengeData });
      const response = await fetch(`${process.env.REACT_APP_API_URL}/public/verify_signature.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          address, 
          signature, 
          challengeData,
          message: siweMessage, // Include the SIWE message
          abi: JSON.stringify(MemeNFTABI.abi)
        }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server response:", errorText);
        throw new Error(`Failed to verify signature: ${errorText}`);
      }
      const data = await response.json();
      //console.log("Server response:", data);

      if (data.isValid && data.token) {
        // Store the new token format
        setAuthToken(data.token, address);
        
        // Decode and log the token to ensure correctness
        const decoded = decodeToken(data.token);
        //console.log("Decoded Token After Setting:", decoded);
      }

      return data;
    } catch (error) {
      console.error('Error verifying signature:', error);
      throw error;
    }
  }, []);

  const authenticateUser = useCallback(async (ethersSigner) => {
    if (isAuthenticating || authAttemptedRef.current) {
      //console.log("Authentication already in progress or attempted");
      return false;
    }
  
    setIsAuthenticating(true);
    authAttemptedRef.current = true; // Prevent further attempts
  
    try {
      if (!ethersSigner) {
        throw new Error("No signer available");
      }
  
      const address = await ethersSigner.getAddress();
      //console.log("Signer address:", address);
  
      if (!address || typeof address !== 'string' || !ethers.isAddress(address)) {
        throw new Error("Invalid Ethereum address");
      }
  
      // Fetch the challenge from the server
      const { message, challengeData } = await getChallengeFromServer(address);
      //console.log("Challenge data:", { message, challengeData });
  
      // Create a SiweMessage instance
      const siweMessage = new SiweMessage({
        domain: window.location.host,
        address: address,
        statement: "Sign in to the application to prove you own this address.",
        uri: window.location.origin,
        version: "1",
        chainId: config.chain,
        nonce: atob(challengeData), // Decode the base64 nonce
        issuedAt: new Date().toISOString(),
      });
  
      // Sign the SIWE message
      const signature = await ethersSigner.signMessage(siweMessage.prepareMessage());
      //console.log("Generated signature:", signature);
  
      // Send the signed message and signature to the server for verification
      const verificationResult = await verifySignatureWithServer(
        address,
        signature,
        siweMessage.prepareMessage(),
        challengeData
      );
  
      if (verificationResult.isValid) {
        setIsAuthenticated(true);
        setUserAddress(address);
        setAuthError(null);
        setAuthToken(verificationResult.token, address);
        //console.log("User authenticated successfully");
  
        const decodedToken = decodeToken(verificationResult.token);
        if (decodedToken) {
          //console.log("Decoded token data:", decodedToken);
          setUserTier(decodedToken.userTier.toString());
        }
  
        return true;
      } else {
        //console.log("Server validation failed:", verificationResult.message || "No message provided");
        throw new Error("Server signature verification failed");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      setIsAuthenticated(false);
      setAuthError(error.message || "An error occurred during authentication.");
      await logout(); // Reset states
      authAttemptedRef.current = false; // Allow retry
      return false;
    } finally {
      setIsAuthenticating(false);
    }
  }, [isAuthenticating, logout, getChallengeFromServer, verifySignatureWithServer, logout]);

  /**
   * Check if the user is authenticated by validating the stored token.
   * This should be called every time the user enters the site.
   */
  const checkAuthentication = useCallback(async () => {
    const storedTokenObj = getAuthToken();
    //console.log("Stored Token Object:", storedTokenObj);
  
    if (storedTokenObj) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/public/check_session.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            address: storedTokenObj.address,
            token: storedTokenObj.value
          }),
        });
  
        const data = await response.json();
        //console.log("check_session response:", data);
  
        if (data.isValid) {
          const decodedToken = decodeToken(storedTokenObj.value);
          //console.log("Decoded Token:", decodedToken);
          if (decodedToken) {
            setIsAuthenticated(true);
            setUserAddress(decodedToken.address);
            setUserTier(decodedToken.userTier.toString());
            return true;
          }
        } else {
          //console.log("Session is invalid:", data.message || "No message provided");
          // Remove the invalid token
          removeAuthToken();
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    } else {
      //console.log("No stored token found.");
    }
  
    setIsAuthenticated(false);
    setUserAddress('');
    setUserTier('NOT A');
    return false;
  }, []);

  // ------------------- Helper Functions -------------------

  /**
   * Initialize contracts with the given provider.
   * @param {ethers.Provider | ethers.Signer} provider - The provider or signer to use.
   */
  const initializeContractsWithProvider = useCallback(async (provider) => {
    try {
      const initializedContracts = await initializeContracts(provider);
      const contractsObject = initializedContracts.reduce((acc, contract) => {
        acc[contract.name] = contract.contract;
        return acc;
      }, {});
      setContracts(contractsObject);
      setContractsReady(true);
      //console.log("Contracts initialized successfully with the provided provider.");
    } catch (error) {
      console.error("Error initializing contracts:", error);
      setContracts({});
      setContractsReady(false);
      throw error;
    }
  }, []);

  // ------------------- Provider Setup -------------------

  /**
   * Set up the ethers provider, signer, initialize contracts, and authenticate the user.
   */
  const setupProvider = useCallback(async () => {
    try {
      //console.log("Setting up provider, isConnected:", isConnected);

      if (isConnected && walletProvider) {
        //console.log("Wallet is connected.");

        const ethersProvider = new ethers.BrowserProvider(walletProvider);
        const network = await ethersProvider.getNetwork();
        const currentChainId = BigInt(network.chainId);

        //console.log("Connected chain:", currentChainId.toString());
        //console.log("Expected chain:", BigInt(config.chain).toString());

        if (currentChainId !== BigInt(config.chain)) {
          //console.log("Connected to wrong chain.");
          setIsAuthenticated(false);
          setUserTier('NOT A');
          setAuthError("Incorrect network");
          setIsCorrectChain(false);
          setContractsReady(false);
          setContracts({});
          return;
        }

        setIsCorrectChain(true);

        const ethersSigner = await ethersProvider.getSigner();
        const currentAddress = await ethersSigner.getAddress();

        if (!ethersSigner) {
          throw new Error("Failed to obtain signer from provider");
        }

        //console.log("ethersSigner is valid:", ethersSigner);

        setSigner(ethersSigner);
        setUserAddress(currentAddress);

        // Initialize contracts with signer (read-write)
        await initializeContractsWithProvider(ethersSigner);

        setAuthError(null);

        // First, check for existing auth token
        const isAuthValid = await checkAuthentication();

        if (!isAuthValid && !authAttemptedRef.current) {
          // Authenticate user if not already authenticated
          await authenticateUser(ethersSigner);
        }
      } else {
        //console.log("Wallet is not connected. Initializing contracts with read-only provider.");
        setIsAuthenticated(false);
        setUserAddress('');
        setUserTier('NOT A');
        setIsCorrectChain(true); // Assuming read-only provider is on the correct chain
        setAuthError(null);
        setSigner(null);

        if (readOnlyProvider) {
          await initializeContractsWithProvider(readOnlyProvider);
        } else {
          console.warn("Read-only provider is not available. Contracts cannot be initialized.");
          setContracts({});
          setContractsReady(false);
        }
      }
    } catch (error) {
      handleError(error, 'setupProvider');
      setIsAuthenticated(false);
      setIsCorrectChain(false);
      setContractsReady(false);
      setContracts({});
    }
  }, [
    isConnected,
    walletProvider,
    config.chain,
    authenticateUser,
    handleError,
    checkAuthentication,
    initializeContractsWithProvider,
    readOnlyProvider
  ]);

  // ------------------- Event Handlers -------------------

  /**
   * Handle chain changes by reinitializing the provider and authentication.
   * @param {string|number} newChainId - The new chain ID.
   */
  const handleChainChange = useCallback(async (newChainId) => {
    //console.log('Chain changed to:', newChainId);
    const numericChainId = Number(newChainId);
    //console.log("New chain ID:", numericChainId);
    //console.log("Expected chain ID:", config.chain);

    if (numericChainId === config.chain) {
      //console.log("Correct network detected. Setting up provider.");
      await setupProvider();
    } else {
      //console.log("Incorrect network detected. Resetting states.");
      setIsAuthenticated(false);
      setUserTier('NOT A');
      setAuthError("Incorrect network");
      setIsCorrectChain(false);
      setContractsReady(false);
      setContracts({});
      setSigner(null);
    }
  }, [setupProvider, config.chain]);

  /**
   * Handle wallet disconnection by logging out the user.
   */
  const handleDisconnect = useCallback(async () => {
    //console.log('Wallet disconnected');
    await logout();
  }, [logout]);

  /**
   * Handle modal-specific events.
   * @param {object} data - The event data.
   */
  const handleModalEvents = useCallback(({ data }) => {
    try {
      //console.log("Modal event received:", data);
      // Handle modal-specific events if necessary
      // Example: If you have specific events, handle them here
    } catch (error) {
      handleError(error, 'handleModalEvents');
    }
  }, [handleError]);

  /**
   * Handle provider changes by logging out if disconnected.
   * @param {object} param0 - The provider change event data.
   */
  const handleProviderChange = useCallback(({ provider, providerType, address, error, chainId, isConnected }) => {
    try {
      //console.log('Provider change detected:', { provider, providerType, address, chainId, isConnected });
      if (!isConnected) {
        //console.log('Disconnection detected');
        logout();
      } else {
        // If connected, re-setup the provider
        setupProvider();
      }
    } catch (error) {
      handleError(error, 'handleProviderChange');
    }
  }, [logout, handleError, setupProvider]);

  // ------------------- useEffect Hooks -------------------

  /**
   * Set up event listeners for the wallet provider.
   */
  useEffect(() => {
    if (walletProvider) {
      // Subscribe to modal events
      const unsubscribeModal = web3Modal.subscribeEvents(handleModalEvents);
      //console.log("Web3Modal subscription set up:", unsubscribeModal ? "successful" : "failed");

      // Subscribe to provider changes
      const unsubscribeProvider = web3Modal.subscribeProvider(handleProviderChange);
      //console.log("Provider subscription set up");

      // Listen to chain changes
      walletProvider.on('chainChanged', handleChainChange);
      
      // Listen to disconnect event instead of 'close'
      walletProvider.on('disconnect', handleDisconnect);

      return () => {
        try {
          //console.log("Cleanup function called");
          if (unsubscribeModal) {
            //console.log("Unsubscribing from web3Modal events");
            unsubscribeModal();
          }
          if (unsubscribeProvider) {
            //console.log("Unsubscribing from provider changes");
            unsubscribeProvider();
          }
          if (walletProvider) {
            walletProvider.removeListener('chainChanged', handleChainChange);
            walletProvider.removeListener('disconnect', handleDisconnect);
          }
        } catch (error) {
          handleError(error, 'Cleanup function');
        }
      };
    }
  }, [walletProvider, handleChainChange, handleDisconnect, handleModalEvents, handleProviderChange]);

  /**
   * Initialize the provider and authentication on component mount.
   */
  useEffect(() => {
    let isMounted = true;
  
    const initialize = async () => {
      try {
        //console.log("Initializing...");
        await setupProvider();
      } catch (error) {
        handleError(error, 'initialize');
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
  
    if (isConnected || readOnlyProvider) {
      initialize();
    } else {
      setIsLoading(false);
    }
  
    return () => {
      isMounted = false;
    };
  }, [isConnected, walletProvider, setupProvider, readOnlyProvider, handleError]); // Added readOnlyProvider and setupProvider to dependencies

  // ------------------- Memoize Context Value -------------------
  const contextValue = useMemo(() => ({
    isConnected,
    signer,
    userAddress,
    isAuthenticated,
    authenticateUser,
    logout,
    connect,
    userTier,
    authError,
    isLoading,
    contracts, // Share contracts with other components if needed
    contractsReady,
    connectionError,
    isCorrectChain,
    isMobileDevice,
    isAuthenticating,
    // ...contracts // Spread contracts if you need individual contract properties
    // Add other states and functions as needed
  }), [
    isConnected,
    signer,
    userAddress,
    isAuthenticated,
    authenticateUser,
    logout,
    connect,
    userTier,
    authError,
    isLoading,
    contracts,
    contractsReady,
    connectionError,
    isCorrectChain,
    isMobileDevice,
  ]);

  // ------------------- Return the Context Provider -------------------
  return (
    <WalletContext.Provider value={contextValue}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
