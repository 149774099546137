import React, { useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import { ethers } from 'ethers';


import noMemberImg from '../nomember.png';
import basicMemberImg from '../baficmember.png';
import bronzeMemberImg from '../bronfmember.png';
import silverMemberImg from '../filvermember.png';
import goldMemberImg from '../goldmember.png';
import ConnectedIcon from './ConnectedIcon';
import NameComponent from './NameComponent';
import Alerts from './Alerts';


import { formatAmount, formatDate, formatTimeLeft, FERIcon, ETHIcon } from '../utils/formatUtils';
import { DataContext } from '../DataProvider';





const MiniDashboard = ({ handleMenuClick, disconnectWallet, connectWallet, isFolio, setIsFolio, toggleFolio, isExpanded, setIsExpanded, handleSectionChange }) => {
  const { 
    isConnected,
    signer,
    userAddress,
    isAuthenticated,


    contracts,

    isLoading

					
	
  } = useContext(WalletContext);

  const {  
    optimizedTierImages, 
    dynamicTierImages,
    imagesLoaded,
    nftData,
    userTier,
    setUserTier,
    ferBalance,
    vestingDetails,
    totalVested,
    userNames,
    userMainName,
    ferScore,
    claimTimes,
    refreshData,
    ethBalance,
  } = useContext(DataContext);



const [isProcessingMint, setIsProcessingMint] = useState(false);
const [showMintPopup, setShowMintPopup] = useState(false);


  const [animationSequence, setAnimationSequence] = useState([]);
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);
  const [animationComplete, setAnimationComplete] = useState(false);


  const [activeTab, setActiveTab] = useState('overview');


  const [isProcessingSetMainName, setIsProcessingSetMainName] = useState(false);
  const tierImages = {
    'NOT A': noMemberImg,
    'BAFIC': basicMemberImg,
    'BRONF': bronzeMemberImg,
    'FILVER': silverMemberImg,
    'GOLD': goldMemberImg
  };



  const getTierColor = (tier) => {
    switch(tier) {
      case '1':
        return 'gold';
      case '2':
        return 'silver';
      case '3':
        return 'bronze';
      default:
        return 'black';
    }
  };

 

  const toggleExpand = () => {
    if(isFolio){
      return;
    }
    setIsExpanded(!isExpanded);
  };



  const tierOrder = ['NOT A', 'BAFIC', 'BRONF', 'FILVER', 'GOLD'];
  const getTierClassName = (tier) => {
    if (!tier) return 'not-a'; // Fallback if tier is undefined
    return tier.toLowerCase().replace(' ', '-');
  };


  function updateMembershipTier(newTier, newImageSrc) {
    try {
        // Handle not connected state
        if (!isAuthenticated) {
            newTier = 'NOT A';
            newImageSrc = noMemberImg;
        }

        const ferfolio = document.querySelector('.ferfolio-content');
        const navigation = document.querySelector('.navigafon');
        const iconHolder = document.querySelector('.membericonholder');
        const userAddress = document.querySelector('.useraddress');
      
        if(!iconHolder || !navigation){
          return;
        }

        const currentIcons = iconHolder.querySelectorAll('.membericon');
        
        if (userAddress) {
          userAddress.className = `useraddress textmediumsmall ${getTierClassName(newTier)}text`;
        }
 
        const newIcon = document.createElement('img');
        newIcon.className = `membericon new-icon ${getTierClassName(newTier)}`;
        newIcon.src = newImageSrc;
        newIcon.alt = `${newTier} Member`;

        iconHolder.appendChild(newIcon);

        void newIcon.offsetWidth;

        currentIcons.forEach(icon => {
            icon.classList.remove('fade-in');
            icon.classList.add('fade-out');
        });

        newIcon.classList.remove('new-icon');
        newIcon.classList.add('fade-in');
      
        if (ferfolio) {
          ferfolio.classList.add('fade-out');
        }

        const newBackground = document.createElement('div');
        newBackground.className = `navigafon-background ${getTierClassName(newTier)} ${isFolio ? 'maximized' : ''}`;
        navigation.appendChild(newBackground);

        setTimeout(() => {
            newBackground.style.opacity = '1';
        }, 50);

        setTimeout(() => {
          if (ferfolio) {
            ferfolio.className = `ferfolio-content ${isFolio ? 'folio' : ''} ${getTierClassName(newTier)}`;
          }
        
          currentIcons.forEach(icon => icon.remove());

          const oldBackgrounds = navigation.querySelectorAll('.navigafon-background:not(:last-child)');
          oldBackgrounds.forEach(bg => bg.remove());
        }, 1000);
    } catch (error) {
        console.error('Error updating membership tier:', error);
        return;
    }
  }

  // Add cleanup effect for wallet disconnection
useEffect(() => {
  if (!isAuthenticated) {
    // Set the tier first
     setUserTier('NOT A');
     updateMembershipTier('NOT A', noMemberImg);
    
    // Reset other states
    setAnimationSequence([]);
    setCurrentAnimationIndex(0);
    setAnimationComplete(false);  // Changed to true to prevent animation
    setActiveTab('overview');

    // Handle view states
    if (isFolio) {
      toggleFolio();
    }
    if (isExpanded) {
      setIsExpanded(false);
    }
  }
}, [isAuthenticated]);
 
useEffect(() => {
  if (!imagesLoaded || isLoading || !isAuthenticated) return;

  const userTiers = new Set(['NOT A']);

  if (nftData) {
    nftData.forEach(nft => {
      if (nft && nft.tier != null) {
        // Convert to an integer
        const tierAsNumber = parseInt(nft.tier, 10);
        // Check if it's in [1..4]
        if (!isNaN(tierAsNumber) && tierAsNumber >= 1 && tierAsNumber <= 4) {
          const nftTierName = ['GOLD', 'FILVER', 'BRONF', 'BAFIC'][tierAsNumber - 1] || 'BAFIC';
          userTiers.add(nftTierName);
        }
      }
      
    });
  }

  const tokenBalanceTier = parseFloat(ferBalance) >= 1 ? 'BAFIC' : 'NOT A';
  userTiers.add(tokenBalanceTier);

  let newSequence = Array.from(userTiers).sort((a, b) => tierOrder.indexOf(a) - tierOrder.indexOf(b));

  // **Filter out** any numeric or invalid items
  newSequence = newSequence.filter(item =>
    tierOrder.includes(item) // tierOrder = ['NOT A','BAFIC','BRONF','FILVER','GOLD']
  );

  setAnimationSequence(newSequence);
  setCurrentAnimationIndex(0);
  setAnimationComplete(false);

  // Highest tier is always the last in sorted array
  setUserTier(newSequence[newSequence.length - 1]);
}, [nftData, ferBalance, imagesLoaded, isLoading, isAuthenticated]);



																 
																		
																	
														   
	



  
  const setMainName = async (tokenId) => {
    setIsProcessingSetMainName(true);
    try {
      const transaction = await contracts.nameRegistryContract.setMainName(tokenId);
   
      await transaction.wait();
              
      await refreshData();
    } catch (error) {
      console.error("Error setting main name:", error);
    } finally {
      setIsProcessingSetMainName(false);
    }
  };

  


  

  

  useEffect(() => {
    if (animationSequence.length > 0 && currentAnimationIndex < animationSequence.length && !animationComplete) {
    const rawTier = animationSequence[currentAnimationIndex];
    const currentTier = typeof rawTier === 'string' ? rawTier : 'NOT A';
      updateMembershipTier(currentTier, tierImages[currentTier]);

      const timer = setTimeout(() => {
        if (currentAnimationIndex < animationSequence.length - 1) {
          setCurrentAnimationIndex(prevIndex => prevIndex + 1);
        } else {
          setAnimationComplete(true);
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [animationSequence, currentAnimationIndex, animationComplete]);

  const formatAddress = (address) => {
    if (address && address.length > 6) {
      return `${address.substring(0, 2)}..${address.substring(address.length - 4)}`;
    }
    return address;
  };


  const renderMembershipCards = () => {

    const tiers = [1, 2, 3, 4]; // 1: GOLD, 2: FILVER, 3: BRONF

    
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
        }}>
          {tiers.map((tier) => {
            const tierName = ['GOLD', 'FILVER', 'BRONF'][tier - 1];
            const nftForTier = nftData.find(nft => nft.tier === tier);
            const count = nftForTier ? nftForTier.balance : 0;
            const isEmpty = count === 0;
            
            // Use allTierImages if the user doesn't have an NFT of this tier
            const imageToUse = isEmpty ? optimizedTierImages[tier] : (dynamicTierImages[tier] || optimizedTierImages[tier]);

        
            const backgroundImageStyle = imageToUse 
              ? `url("${imageToUse}")` 
              : 'none';

            return (
              <div key={tier} style={{ 
                width: '30%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '0 3%',
                opacity: isEmpty ? 0.5 : 1,
              }}>
                <div className='textmediumsmall'>
                   {tierName} x {count}  
                </div>
                <div style={{
                  width: '100%',
                  height: '3vw',
                  position: 'relative',
                  overflow: 'visible',
                }}>
                  {isEmpty ? (
                    <div style={{
                      width: '100%',
                      height: '100%',
                      backgroundImage: backgroundImageStyle,
                      backgroundColor: imageToUse ? 'transparent' : 'gray',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }} />
                  ) : (
                    [...Array(Math.min(count, 5))].map((_, i) => (
                      <div key={i} style={{
                        width: '100%',
                        height: '100%',
                        backgroundImage: backgroundImageStyle,
                        backgroundColor: imageToUse ? 'transparent' : 'gray',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        transform: `translate(${i * 5}%, 0)`,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }} />
                    ))
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };



useEffect(() => {
  setActiveTab('overview');
}, [isAuthenticated]);

useEffect(() => {
  function updateContainerHeight() {
    const navigafon = document.querySelector('.navigafon');
    if (navigafon) {
      const navigafonHeight = navigafon.offsetHeight;
      const viewportHeight = window.innerHeight;
      const protrusion = Math.max(0, navigafonHeight - viewportHeight);
      document.documentElement.style.setProperty('--navigafon-protrusion', `${protrusion*1.5}px`);
    }
  }

  // Run on load and whenever the window is resized
  window.addEventListener('load', updateContainerHeight);
  window.addEventListener('resize', updateContainerHeight);

  // Run when the component mounts or updates
  updateContainerHeight();

  // Cleanup
  return () => {
    window.removeEventListener('load', updateContainerHeight);
    window.removeEventListener('resize', updateContainerHeight);
  };
}, [isExpanded, activeTab]); // Add dependencies that might affect the height

return (
  <>

   
      
    <div className='titleheader'
    onClick={
  
      async () => {
        
        if(isAuthenticated && animationComplete){

          toggleFolio()
      
        }
      
      }}
    >
      <span className='titletext small'>ORDER OF</span>
      <span className='titletext large'>FER</span>

      
    </div>

    
      <div className='minidashboard'
      
      >
 <div className={`membericonholder ${isFolio ? 'maximized' : ''}`}>
  <img 
    className={`membericon ${!isAuthenticated ? 'bafic' : userTier.toLowerCase()} ${animationComplete ? '' : 'fade-in'}`}
    src={
      !isAuthenticated
        ? tierImages['BAFIC']
        : !animationComplete && animationSequence.length > 0
          // If it's a string, use it; otherwise use 'NOT A'
          ? tierImages[
              typeof animationSequence[currentAnimationIndex] === 'string'
                ? animationSequence[currentAnimationIndex]
                : 'NOT A'
            ] || noMemberImg
          : animationComplete && typeof userTier === 'string'
            ? tierImages[userTier] || noMemberImg
            : noMemberImg
    }
    
    alt={
      !isAuthenticated
        ? 'BAFIC Member'
        : !animationComplete && animationSequence.length > 0
          ? typeof animationSequence[currentAnimationIndex] === 'string'
            ? `${animationSequence[currentAnimationIndex]} Member`
            : 'NOT A Member'
          : animationComplete && typeof userTier === 'string'
            ? `${userTier} Member`
            : 'NOT A Member'
    }
    onError={(e) => {
      e.target.onerror = null; 
      e.target.src = noMemberImg;
    }}
  />

 <span className={`membertext ${
    !isAuthenticated
      ? 'bafic'
      : !animationComplete && animationSequence.length > 0
        ? animationSequence[currentAnimationIndex].toLowerCase()
        : animationComplete
          ? userTier.toLowerCase()
          : 'not-a'
  }`}>
<span className='large'>
  {!isAuthenticated
    ? 'FER'
    : !animationComplete && animationSequence.length > 0
      ? typeof animationSequence[currentAnimationIndex] === 'string'
        ? animationSequence[currentAnimationIndex]
        : 'NOT A'
      : animationComplete && typeof userTier === 'string'
        ? userTier
        : 'NOT A'
  }
</span>
 <br />{isAuthenticated ? 'MEMBER' : 'NOT CONNECTED'}
  </span>
</div>


{isFolio ? '' : 
        <Alerts
          setIsExpanded={setIsExpanded}
          setActiveTab={setActiveTab}
          setShowMintPopup={setShowMintPopup}
          handleSectionChange={handleSectionChange} 
          isFolio={isFolio}
          toggleFolio={toggleFolio}
        />
}


<div 
disabled={!isAuthenticated}
  className={`ferfolio-content ${
    !isAuthenticated 
      ? 'not-a'
      : animationComplete 
        ? userTier.toLowerCase() 
        : (animationSequence[currentAnimationIndex]?.toLowerCase() || userTier.toLowerCase())
  } ${isFolio ? 'maximized' : ''} ${isExpanded ? 'expanded' : ''} ${animationComplete ? '' : 'fade-in'}`}
    onClick={async (e) => {
                  e.stopPropagation();
    if(isAuthenticated && animationComplete) {
      toggleFolio();
    }else if(!isAuthenticated){
      await connectWallet();
    }
  }}
>
          {/* Folio Buttons Container */}
          
        
            
            <div className={`textmediumsmall useraddress ${getTierClassName(userTier)}text`}  style={{zIndex:'2'}}>
              {isAuthenticated && (
            <ConnectedIcon 
                isConnected={isConnected} 
                isAuthenticated={isAuthenticated} 
               
                onClick={async (e) => {
                  e.stopPropagation();
                  if (isAuthenticated) {
                    await disconnectWallet();
                    if(isFolio){
                    toggleFolio();
                  }
                  } else {
                    await connectWallet();
                  }
                }}
              /> 
            )}
              {formatAddress(userAddress)} 


            </div>             
 {!isExpanded &&(
  <>
            <div className={`textmediumsmall useraddress ${getTierClassName(userTier)}text`}  style={{zIndex:'2', width: '94%', zIndex:'1', textAlign: 'right', lineHeight: '0.6'}}>

              <FERIcon /> {formatAmount(Number(ferBalance))}
              {totalVested>0 ? (
              <i className='textsmall gray'><br />{'('}+{formatAmount(Number(totalVested)) }{')'}</i>
            )
             : '' }
            </div>
            <div className={`textmediumsmall useraddress ${getTierClassName(userTier)}text`}  style={{zIndex:'2', bottom:'8%', zIndex:'1', width: '94%', textAlign: 'right', pointerEvents:'none'}}>

         
<ETHIcon /> {formatAmount(ethBalance)}
</div>
</>
)}
  
        

  {!isFolio ? 
  (userMainName ? (
    <>
      <span className='textmedium fertitle'>FER</span>
      <div  className={`mainferfona darkbglarge ${
        userMainName.length > 12 
          ? 'small' 
          : userMainName.length > 10 
            ? 'medium' 
            : ''
      } ${
        !isAuthenticated 
          ? 'NOT A'
          : animationComplete 
            ? userTier.toLowerCase() 
            : (animationSequence[currentAnimationIndex]?.toLowerCase() || userTier.toLowerCase())
      }text`}>
        {userMainName}
      </div>
    </>
  ) : (<span className='darkbglarge'>{isAuthenticated ? <span>FerFolio</span> : <span style={{color:'gray'}}>CONNECT</span>}</span>)) : 'Back' }
  
{!isFolio && isAuthenticated &&(
  <div
    style={{
      position: 'absolute',
      ...(isExpanded 
        ? { top: '5%', bottom: 'auto', color: 'rgba(255,255,255,1)' } 
        : { bottom: '-10%', top: 'auto' }),
      left: '0%',
      padding: '1% 2%',
      color: 'rgba(0,0,0,0.3)',
      backgroundColor: 'rgba(0,0,0,0)',
      transition: 'background-color 0.3s ease, top 0.3s ease, bottom 0.3s ease, opacity 0.3s ease',
    
    }}
    className='stophover'
    onMouseEnter={(e) => e.currentTarget.style.color = 'rgba(255,255,255,1)'}
    onMouseLeave={(e) => {
      if (!isExpanded) {
        e.currentTarget.style.color = 'rgba(0,0,0,0.3)';
      }
    }}
  >
    <button 
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '100%',
        color: 'inherit',
      }}
      onClick={(e) => {
        e.stopPropagation();
        toggleExpand();
      }}
      aria-label={isExpanded ? "Collapse dashboard" : "Expand dashboard"}
    >
      {isExpanded ? '▲' : '▼'}
    </button>
  </div>
)}
          {isExpanded && (
            <>
              <div className='ekonomif'>
      <div className='tab-container'>
        <button
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setActiveTab('overview');
          }}
        >
          Holding
        </button>
        <button
          className={`tab-button ${activeTab === 'vesting' ? 'active' : ''} ${vestingDetails.length > 0 ? '' : 'disabled'}`}
          onClick={(e) => {
            e.stopPropagation();
            if (vestingDetails.length > 0) {
              setActiveTab('vesting');
            }
          }}
          disabled={vestingDetails.length === 0}
        >
          Vefting
        </button>
        <button
          className={`tab-button ${activeTab === 'namef' ? 'active' : ''} ${userNames.length > 0 ? '' : 'disabled'}`}
          onClick={(e) => {
            e.stopPropagation();
            if (userNames.length > 0) {
              setActiveTab('namef');
            }
          }}
          disabled={userNames.length === 0}
        >
          FERFona
        </button>
      </div>
      {activeTab === 'overview' && (
        <div className='summary-container'>
          <div style={{display: 'flex', flexDirection: 'row', gap: '0.5vw', alignItems: 'center', justifyContent: 'space-evenly'}}>
  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
    <span className={`textmedium flex-align ${parseFloat(ferBalance) > 0 ? '' : 'graytext'}`}>
    <FERIcon style={{position: 'relative', right: '5%'}} /> {formatAmount(ferBalance)} 
    </span>
    {totalVested >= '1' && (
      <span className='textmediumsmall goldtext' style={{position:'relative', left:'3%'}}>
        {'  +'} {formatAmount(totalVested)} vefted
      </span>
    )}
  </div>
  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
    <span className="textmedium flex-align" >
    <ETHIcon style={{position: 'relative', right: '5%'}} /> {formatAmount(ethBalance)} 
    </span>
  </div>
</div>
          <div style={{ display: 'flex', flexDirection:'column', position: 'relative' }}>
            {renderMembershipCards()}
            <span className={`textmedium ${parseFloat(ferBalance) > 0 ? '' : 'graytext'} ${
  parseInt(ferScore) >= 100 ? 'gold' :
  parseInt(ferScore) >= 10 ? 'filver' :
  parseInt(ferScore) > 0 ? 'bronf' : ''
}text`} style={{marginBottom: '0.3vw'}}>
          <span className='ff'>F</span>er<span className='ff'>F</span>core:  <span className={`textmedium ${
  parseInt(ferScore) >= 100 ? 'gold' :
  parseInt(ferScore) >= 10 ? 'filver' :
  parseInt(ferScore) > 0 ? 'bronf' : ''
}text`}>
  {ferScore}
</span>
          </span>
          </div>
 
        </div>
      )}
      {activeTab === 'vesting' && (
        <div className='vested-container'>
          {vestingDetails.slice().reverse().map((detail, index) => (
            <div key={index} className='time-left-item'>
              <span className='textmedium flex-align'> <FERIcon style={{position: 'relative', marginRight: '7%', marginLeft: '2%'}} />  {formatAmount(detail.amount)} </span>
              <div className='textmediumsmall'>
                <span>
                  {formatTimeLeft(detail.releaseTime, detail.claimed)}
                  {detail.claimed && (
                    <>
                      :<br />
                      {formatDate(claimTimes[vestingDetails.length - 1 - index])}
                    </>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      {activeTab === 'namef' && (
  <div className='names-container'>
    {userNames.map(name => (
       <div key={name.tokenId} className={`name-item ${getTierColor(name.tier)}`}>

        <div className='textmediumsmall name-entry'>
          {/* Name with tier-based color */}
          <span 
            className='textmedium name-text' 
           
          >
            {`Fer ${name.name}`}
          </span>

          {/* Indicator for Main Name */}
          {name.name === userMainName ? (
            <span className='main-name-indicator'>(Main Name)</span>
          ) : (
        <span 
          className={`checkbox-indicator ${name.name === userMainName ? 'checked' : ''}`} 
          onClick={(e) => {
            e.stopPropagation();
            if (!isProcessingSetMainName) {
              setMainName(name.tokenId);
            }
          }}
          title="Set as Main Name"
          aria-label={`Set ${name.name} as your main aliaf`}
          style={{ cursor: isProcessingSetMainName ? 'not-allowed' : 'pointer', opacity: isProcessingSetMainName ? 0.6 : 1 }}
        >
          {name.name === userMainName ? '\u2714' : '\u2610'}
        </span>


          )}
        </div>
      </div>
    ))}
  </div>
)}

    </div>
  </>
)}
        </div>
      </div>
    

{/*
<NameComponent
  userNamesForSale={userNamesForSale}
  namesList={namesList}
  eligibleTiers={eligibleTiers}
  isProcessingMint={isProcessingMint}
  showMintPopup={showMintPopup}
  setShowMintPopup={setShowMintPopup}
  nameRegistryContract={nameRegistryContract}
  nameNFTContract={nameNFTContract} // Added
  coreERC20Contract={coreERC20Contract} // Added
  refreshData={refreshData} // Ensure this is correctly passed
  formatAmount={formatAmount}
  formatAddress={formatAddress}
  hasTier1NFT={nftData.some(nft => nft.tier === 1)}
  userAddress={userAddress} // Added
  signer={signer} // Added
/>
*/}

  </>
);
};

export default MiniDashboard;