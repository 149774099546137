
import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import ImageEditor from './ImageEditor';
import FabricCanvas from './FabricCanvas';
import ImageUploader from './ImageUploader';
import EditorToolbar from './EditorToolbar';
import WebGLLiquify from './WebGLLiquify';
import CanvasLiquify from './CanvasLiquify';
import { fabric } from 'fabric';
import ImageLibraryModal from './ImageLibraryModal';
import { contain } from 'three/src/extras/TextureUtils.js';
import ClosetToolbar from './ClosetToolbar';
import { WalletContext } from '../../WalletContext';
import { getAuthToken } from '../../authUtils';  
import { addFrameAndText } from './frameUtils';
import LayerList from './LayerList'; // Import the new LayerList component
import { DataContext } from '../../DataProvider';
import OrientationOverlay from './OrientationOverlay';

import { initializeFrameOverlay, updateFrameOverlay, getStoredText } from './frameOverlayUtils'; // Adjust the path as needed
import { isMobile } from 'web3modal';
import WaterMarkText from './WaterMarkText';


const ImageEditorContainer = () => {
    const [activeLayer, setActiveLayer] = useState("liquify");
    const [imageUploaded, setImageUploaded] = useState(false);
    const [triggerDeselect, setTriggerDeselect] = useState(false);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [src, setSrc] = useState(null);
    const [originalSrc, setOriginalSrc] = useState(null);
    const [morferBrushSize, setMorferBrushSize] = useState(100);
    const [paintBrushSize, setPaintBrushSize] = useState(10);
    const [smoothing, setSmoothing] = useState(0.1);
    const [selectedText, setSelectedText] = useState(null);
    const [textContent, setTextContent] = useState('');
    const [textFontSize, setTextFontSize] = useState(24);
    const [textColor, setTextColor] = useState('#000000');
    const [textFontFamily, setTextFontFamily] = useState('Segoe UI');
    const [brushColor, setBrushColor] = useState('#000000');
    const [paintEnabled, setPaintEnabled] = useState(false);
    const [textboxVisible, setTextboxVisible] = useState(false);
    const [textboxPosition, setTextboxPosition] = useState({ top: 0, left: 0 });
    const [textboxValue, setTextboxValue] = useState('');
    const [isWebGLAvailable, setIsWebGLAvailable] = useState(true);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
    const [strength, setStrength] = useState(0.0025); // Add state for strength
    const [frameVisible, setFrameVisible] = useState(true);

    const { userAddress, isAuthenticated, isMobileDevice } = useContext(WalletContext);
    const { userMainName, userTier, isLoadingData } = useContext(DataContext);
    const [imageCache, setImageCache] = useState({});

    const [canvasWidthPercentage, setCanvasWidthPercentage] = useState(80); // Default to 80%
    const [toolbarWidthPercentage, setToolbarWidthPercentage] = useState(10); // Default to 80%


    const frameThickness = 35; // You can adjust this value or make it dynamic

    const holderRef = useRef(null)

    const [areaPercentages, setAreaPercentages] = useState({ canvasPercentage: 80, toolbarPercentage: 20 });

    const [layers, setLayers] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);

    const [activeButton, setActiveButton] = useState('morfer');
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const canvasFrameRef = useRef(null);




    const [loadedImagesCount, setLoadedImagesCount] = useState(0);
    const [totalImagesCount, setTotalImagesCount] = useState(0);
    const [isLibraryLoaded, setIsLibraryLoaded] = useState(false);
    

    const handleLayersChange = useCallback((newLayers) => {
        // Exclude watermark and authenticated text objects by checking fabricObject properties
        const filteredLayers = newLayers.filter(layer => {
            const fabricObj = layer.fabricObject;
            return !(fabricObj.isWatermark || fabricObj.isAuthenticatedText);
        });
        setLayers([...filteredLayers].reverse());
    }, []);
    

    // **Corrected handleSelectionChange Function**
    const handleSelectionChange = useCallback((selectedObjects) => {
        if (!selectedObjects) {
            setSelectedLayers([]);
            return;
        }

        // Exclude watermark and authenticated text objects
        const filteredSelectedObjects = selectedObjects.filter(obj => {
            return !(obj.isWatermark || obj.isAuthenticatedText);
        });
        setSelectedLayers(filteredSelectedObjects);
    }, []);


    


    const handleLayerSelect = useCallback((fabricObject, isMultiSelect = false) => {
        if (fabricCanvasRef.current) {
            setPaintEnabled(false);
            setSelectedText(null);
            setTextboxVisible(false);
            setActiveLayer('fabric');
            setActiveButton('select')
        
            if (isMultiSelect) {
                const updatedSelection = selectedLayers.includes(fabricObject)
                    ? selectedLayers.filter(obj => obj !== fabricObject)
                    : [...selectedLayers, fabricObject];
                fabricCanvasRef.current.selectObjects(updatedSelection);
            } else {
                fabricCanvasRef.current.selectObjects([fabricObject]);
            }
        }
    }, [selectedLayers]);

    const handleToggleLayerVisibility = useCallback((fabricObject) => {
        if (fabricCanvasRef.current) {
            fabricCanvasRef.current.toggleObjectVisibility(fabricObject);
        }
    }, []);

    const handleDeleteLayers = useCallback(() => {
        if (fabricCanvasRef.current && selectedLayers.length > 0) {
            fabricCanvasRef.current.deleteObjects(selectedLayers);
            setSelectedLayers([]);
        }
    }, [selectedLayers]);


    const checkWebGLAvailability = useCallback(() => {
        try {
            const canvas = document.createElement('canvas');
            const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
            setIsWebGLAvailable(!!gl);
        } catch (e) {
            setIsWebGLAvailable(false);
        }
    }, []);

    useEffect(() => {
        checkWebGLAvailability();
    }, [checkWebGLAvailability]);


  
    



    const canvasRef = useRef(null);
    const imageRef = useRef(null);
    const fabricCanvasRef = useRef(null);
    const containerRef = useRef(null);
    const webGLCanvasRef = useRef(null);
    const frameCanvasRef = useRef(null);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false);
    const [libraryData, setLibraryData] = useState([]);


    const handleUndo = () => {
        if (webGLCanvasRef.current && webGLCanvasRef.current.undo) {
            webGLCanvasRef.current.undo();
        }
    };

    const handleRedo = () => {
        if (webGLCanvasRef.current && webGLCanvasRef.current.redo) {
            webGLCanvasRef.current.redo();
        }
    };


    const handleWebGLAvailability = useCallback((available) => {
        setIsWebGLAvailable(available);
    }, []);





    
    const calculateCanvasWidthPercentage = useCallback((imgWidth, imgHeight, containerWidth, holderWidth, containerHeight) => {


        
        const aspectRatio = imgWidth / imgHeight;
        
    
        const maxWidth = holderWidth ? (holderWidth * 0.9) : (containerWidth * 0.9); // 90% of container width
     
        const maxHeight = window.innerHeight * 0.7;
    
        let newWidth = maxWidth;
        let newHeight = newWidth / aspectRatio;
    
        // If the new height exceeds the max height, scale down
        if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
        }

        // Calculate the width percentage based on the new dimensions
        const widthPercentage = (newWidth / containerWidth) * 100;
    
        // Ensure the toolbar has at least 20% width
        const toolbarPercentage = Math.max(25, 100 - widthPercentage);
       
        const adjustedWidthPercentage = 100 - toolbarPercentage;
    
        return {
            width: Math.floor(newWidth),
            height: Math.floor(newHeight),
            widthPercentage: adjustedWidthPercentage
        };
    }, []);
    
    

    
    
    const handleMouseMove = useCallback((e) => {
        const rect = containerRef.current.getBoundingClientRect();
        const x = (e.clientX - rect.left) / rect.width;
        const y = (e.clientY - rect.top) / rect.height; // Keep this line as is for a normal coordinate system
    
        setCursorPosition({ x, y });
    
        if (activeLayer === 'fabric' && paintEnabled) {
            // Handle brush drawing logic here
        } else if (activeLayer === 'webGL' && isWebGLAvailable) {
            // Handle WebGL-specific logic if needed
        }
    
       
    }, [activeLayer, paintEnabled]);
    
    
    

    
    const calculateAreaPercentages = (imgWidth, imgHeight, containerWidth, containerHeight, holderWidth) => {
        const aspectRatio = imgWidth / imgHeight;
        const maxWidth = Math.min(holderWidth || containerWidth, containerWidth);
        const maxHeight = window.innerHeight * 0.7; // 70% of window height
    
        let canvasWidth, canvasHeight;
    
        // Calculate dimensions maintaining aspect ratio
        if (aspectRatio > maxWidth / maxHeight) {
            canvasWidth = Math.min(maxWidth, containerWidth * 0.8); // Max 80% of container width
            canvasHeight = canvasWidth / aspectRatio;
        } else {
            canvasHeight = maxHeight;
            canvasWidth = Math.min(canvasHeight * aspectRatio, maxWidth);
        }
    
        // Ensure minimum toolbar width
        const minToolbarWidth = containerWidth * 0.1; // 20% of container width
        const maxCanvasWidth = containerWidth - (2 * minToolbarWidth); // Leave space for both toolbars
    
        // Adjust canvas width if it exceeds the maximum allowed
        if (canvasWidth > maxCanvasWidth) {
            canvasWidth = maxCanvasWidth;
            canvasHeight = canvasWidth / aspectRatio;
        }
    
        // Calculate percentages
        let canvasPercentage = (canvasWidth / containerWidth) * 100;
        let toolbarPercentage = (100 - canvasPercentage) / 2; // Split remaining space for two toolbars
    
        // Ensure toolbar percentage is at least 10%
        if (toolbarPercentage < 10) {
            toolbarPercentage = 10;
            canvasPercentage = 100 - (toolbarPercentage * 2);
        }
    
        return {
            canvasPercentage: Math.round(canvasPercentage),
            toolbarPercentage: Math.round(toolbarPercentage),
            canvasWidth: Math.floor(canvasWidth),
            canvasHeight: Math.floor(canvasHeight)
        };
    };


    const handleResize = useCallback(() => {

     
        if (
            originalSrc &&
            containerRef.current &&
            holderRef.current &&
            originalDimensions.width &&
            originalDimensions.height
        ) {
            const imgWidth = originalDimensions.width;
            const imgHeight = originalDimensions.height;
    
            const containerWidth = containerRef.current.clientWidth;
            const holderWidth = holderRef.current.clientWidth ;
            const referenceWidth = Math.max(containerWidth, holderWidth);
    
            const percentages = calculateAreaPercentages(
                imgWidth,
                imgHeight,
                referenceWidth,
                window.innerHeight,
                holderWidth
            );

        
            
    
            setImageDimensions({
                width: Math.floor(percentages.canvasWidth),
                height: Math.floor(percentages.canvasHeight),
            });
            setCanvasWidthPercentage(percentages.canvasPercentage);
            setToolbarWidthPercentage(percentages.toolbarPercentage);
    
            // Update dimensions of canvases
            if (webGLCanvasRef.current && webGLCanvasRef.current.updateDimensions) {
                webGLCanvasRef.current.updateDimensions({
                    width: percentages.canvasWidth,
                    height: percentages.canvasHeight,
                });
            }
            if (fabricCanvasRef.current && fabricCanvasRef.current.updateDimensions) {
                fabricCanvasRef.current.updateDimensions({
                    width: percentages.canvasWidth,
                    height: percentages.canvasHeight,
                });
            }
    
            // Update the frame overlay dimensions with userTier and userMainName
            if (frameCanvasRef.current) {
                updateFrameOverlay(
                    frameCanvasRef.current,
                    {
                        width: percentages.canvasWidth,
                        height: percentages.canvasHeight,
                    },
                    frameThickness,
                    userTier,           // Pass userTier
                    userMainName       // Pass userMainName
                );
                getStoredText();
            }
        }
    }, [originalSrc, originalDimensions.width, originalDimensions.height, frameThickness, userTier, userMainName]);
    
    
    
      

    

    const handleImageUpload = useCallback((src, originalDimensions) => {
        // Check refs first 
        if (!containerRef.current || !holderRef.current) {
            console.error('Container refs not ready');
            return;
        }
    
        // Clear states
        setSrc(null);
        setIsImageLoaded(false);
        setImageUploaded(false);  // Set this to false first
        setOriginalDimensions(originalDimensions);
    
        const img = new Image();
        img.onload = () => {
            // Calculate dimensions with validated refs
            const dimensions = calculateCanvasWidthPercentage(
                img.naturalWidth,
                img.naturalHeight,
                containerRef.current.clientWidth,
                holderRef.current.clientWidth,
            );
    
            // Update dimensions first
            setImageDimensions({ width: dimensions.width, height: dimensions.height });
            setCanvasWidthPercentage(dimensions.widthPercentage);
            setToolbarWidthPercentage(100 - dimensions.widthPercentage);
    
            // Then update canvas dimensions
            if (webGLCanvasRef.current?.updateDimensions) {
                webGLCanvasRef.current.updateDimensions({
                    width: dimensions.width,
                    height: dimensions.height
                });
            }
            if (fabricCanvasRef.current?.updateDimensions) {
                fabricCanvasRef.current.updateDimensions({
                    width: dimensions.width,
                    height: dimensions.height
                });
            }
    
            // Finally set image states
            setOriginalSrc(src);
            setSrc(src);
            setIsImageLoaded(true);
            setImageUploaded(true);
    
            // **Add this line to reset the WebGL context**
            if (webGLCanvasRef.current && webGLCanvasRef.current.reset) {
                webGLCanvasRef.current.reset();
            }
       
            
        };
        img.src = src;
     
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 100);
      
    }, [calculateCanvasWidthPercentage]);


    
    useEffect(() => {
        if (imageUploaded && imageDimensions.width && imageDimensions.height) {
            if (frameVisible || !isAuthenticated) {
                // Initialize the frame overlay if it's not already initialized
                if (!frameCanvasRef.current) {
                    frameCanvasRef.current = initializeFrameOverlay(
                        canvasFrameRef.current,
                        imageDimensions,
                        frameThickness,
                        userTier,           // Pass userTier
                        userMainName       // Pass userMainName
                    );
                }
            } else {
                // Remove the frame overlay if it exists
                if (frameCanvasRef.current && canvasFrameRef.current) {
                    canvasFrameRef.current.removeChild(frameCanvasRef.current);
                    frameCanvasRef.current = null;
                }
            }
        }
    }, [imageUploaded, imageDimensions, frameThickness, userTier, userMainName, frameVisible]);
    
    



    useEffect(() => {
        if (originalSrc && originalDimensions.width && originalDimensions.height) {
          handleResize();
        }
      }, [originalSrc, originalDimensions.width, originalDimensions.height]);
    
      useEffect(() => {
        const debouncedHandleResize = () => {
          // debounce logic
          if (resizeTimeout) clearTimeout(resizeTimeout);
          resizeTimeout = setTimeout(handleResize, 20);
        };
    
        let resizeTimeout;
    
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
          window.removeEventListener('resize', debouncedHandleResize);
          if (resizeTimeout) clearTimeout(resizeTimeout);
        };
      }, [handleResize]);


          // Prevent right click on container
    const handleContextMenu = useCallback((e) => {
        e.preventDefault();
        return false;
    }, []);

    // Add event listener when component mounts
    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, [handleContextMenu]);

    
      const handleSave = async () => {
        const title = getStoredText();
        if ((!title.trim() && frameVisible) || (!frameVisible && !isAuthenticated)) {
            alert("Please enter a title before downloading.");
            return;
        }
    
        const { width, height } = originalDimensions;
        const frameThicknessRatio = 0.07; // Frame thickness is 7% of the smaller dimension
        const finalFrameThickness = frameVisible ? Math.floor(Math.min(width, height) * frameThicknessRatio) : 0;
        const newWidth = width + 2 * finalFrameThickness;
        const newHeight = height + 2 * finalFrameThickness;
    
        const combinedCanvas = document.createElement('canvas');
        combinedCanvas.width = newWidth;
        combinedCanvas.height = newHeight;
        const context = combinedCanvas.getContext('2d');
    
        // Clear the canvas with a transparent background
        context.clearRect(0, 0, combinedCanvas.width, combinedCanvas.height);
    
        // Draw layers with offset to accommodate the frame if frame is visible
        await drawLayers(context, finalFrameThickness, userTier, userMainName); // Pass user data
    
        if ((frameVisible && finalFrameThickness > 0) || isAuthenticated) {
            // Add the frame and text outside the image area using the utility function
            addFrameAndText(
                context, 
                combinedCanvas.width, 
                combinedCanvas.height, 
                finalFrameThickness, 
                userTier, 
                userMainName
            ); // Pass user data
        }
    
        // Check if transparency is needed
        const imageData = context.getImageData(0, 0, combinedCanvas.width, combinedCanvas.height);
        const hasTransparency = imageData.data.some((value, index) => (index + 1) % 4 === 0 && value < 255);
    
        // Determine format and quality
        let format, quality, fileName;
        if (hasTransparency) {
            format = 'image/png';
            fileName = 'combined-image.png';
        } else {
            format = 'image/jpeg';
            quality = 1;
            fileName = 'combined-image.jpg';
        }
    
        // Create download link
        const dataUrl = combinedCanvas.toDataURL(format, quality);
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = fileName;
        link.click();
    };
    
    

    // Helper function to draw layers
    async function drawLayers(context, frameThickness) {
        const { width, height } = originalDimensions;
        const scaleX = width / imageDimensions.width;
        const scaleY = height / imageDimensions.height;
    
        // Step 1: Draw the liquify layer (WebGL or 2D canvas)
        let backgroundImageData;
        if (isWebGLAvailable && webGLCanvasRef.current) {
            const webGLCanvas = webGLCanvasRef.current;
            webGLCanvas.updateDimensions(originalDimensions);
            backgroundImageData = webGLCanvas.toDataURL('image/png');
            webGLCanvas.updateDimensions(imageDimensions);
        } else if (canvasRef.current) {
            const img = new Image();
            await new Promise(resolve => {
                img.onload = () => {
                    const tempCanvas = document.createElement('canvas');
                    tempCanvas.width = width;
                    tempCanvas.height = height;
                    const tempCtx = tempCanvas.getContext('2d');
                    tempCtx.drawImage(img, 0, 0, width, height);
                    backgroundImageData = tempCanvas.toDataURL('image/png');
                    resolve();
                    
                };
                img.src = originalSrc;
            });
        }
    
        // Draw the background image
        const bgImg = new Image();
        await new Promise(resolve => {
            bgImg.onload = () => {
                //context.drawImage(bgImg, 0, 0, width, height);
                resolve();
            };
            bgImg.src = backgroundImageData;
        });
    
        // Step 2: Prepare SVG content for text and canvas for other objects
        let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">`;
        svgContent += `<image href="${backgroundImageData}" x="0" y="0" width="100%" height="100%"/>`;
    
        let nonTextObjects = [];
    
        if (fabricCanvasRef.current) {
            const fabricCanvas = fabricCanvasRef.current.getCanvas();
            const objects = fabricCanvas.getObjects();
            const scaleX = width / imageDimensions.width;
            const scaleY = height / imageDimensions.height;
    
            objects.forEach(obj => {
                if (obj.type === 'i-text' || obj.type === 'text') {
                    const scaledLeft = obj.left * scaleX;
                    const scaledTop = obj.top * scaleY;
                    const scaledFontSize = obj.fontSize * Math.min(obj.scaleX * scaleX, obj.scaleY * scaleY);
                    // Calculate shadow offset based on the text's scale
                    const shadowScale = Math.min(obj.scaleX, obj.scaleY);
                    const shadowOffsetX = -1.6 * shadowScale;
                    const shadowOffsetY = 1.6 * shadowScale;
            
                    // Apply text styles
                    const fontStyle = obj.fontStyle ? `font-style="${obj.fontStyle}"` : '';
                    const fontWeight = obj.fontWeight ? `font-weight="${obj.fontWeight}"` : '';
                    const textDecoration = obj.textDecoration ? `text-decoration="${obj.textDecoration}"` : '';
            
                    svgContent += `<text x="${scaledLeft}" y="${scaledTop}" 
                        font-family="${obj.fontFamily}" 
                        font-size="${scaledFontSize}px" 
                        fill="${obj.fill}"
                        text-anchor="${obj.textAlign === 'center' ? 'middle' : obj.textAlign === 'right' ? 'end' : 'start'}"
                        dominant-baseline="text-before-edge"
                        ${fontStyle}
                        ${fontWeight}
                        ${textDecoration}
                        filter="drop-shadow(${shadowOffsetX}px ${shadowOffsetY}px 0px black)"
                        transform="rotate(${obj.angle} ${scaledLeft} ${scaledTop})"
                        style="white-space: pre;">${obj.text}</text>`;
                } else {
                    nonTextObjects.push(obj);
                }
            });
    
            // Create a temporary Fabric canvas for non-text objects
            if (nonTextObjects.length > 0) {
                const tempFabricCanvas = new fabric.Canvas(null, { width, height });
                nonTextObjects.forEach(obj => {
                    const clonedObj = fabric.util.object.clone(obj);
                    clonedObj.scaleX *= scaleX;
                    clonedObj.scaleY *= scaleY;
                    clonedObj.left *= scaleX;
                    clonedObj.top *= scaleY;
                    clonedObj.setCoords();
                    tempFabricCanvas.add(clonedObj);
                });
    
                // Render non-text objects to data URL
                const nonTextImageData = tempFabricCanvas.toDataURL({
                    format: 'png',
                    quality: 1,
                    width: width,
                    height: height
                });
    
                svgContent += `<image href="${nonTextImageData}" x="0" y="0" width="100%" height="100%"/>`;
            }
        }
    
        svgContent += '</svg>';
    
        // Step 3: Render final SVG to canvas
        const finalCanvas = document.createElement('canvas');
        finalCanvas.width = width;
        finalCanvas.height = height;
        const finalCtx = finalCanvas.getContext('2d');
    
        const img = new Image();
        const svgBlob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);
    
        await new Promise(resolve => {
            img.onload = () => {
                finalCtx.drawImage(img, 0, 0);
                URL.revokeObjectURL(url);
                resolve();
            };
            img.src = url;
        });
    
        // Draw the final result to the provided context with offset for the frame
        context.drawImage(finalCanvas, 0, 0, width, height,
            frameThickness, frameThickness, width, height);
    }
    
    // Helper function to draw image to context
    function drawImageToContext(src, context) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);
                resolve();
            };
            img.src = src;
        });
    }


    const handleReset = () => {
        if (canvasRef.current) {
            const context = canvasRef.current.getContext('2d');
            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        }
        if (webGLCanvasRef.current && webGLCanvasRef.current.reset) {
            webGLCanvasRef.current.reset();
        }
        if (fabricCanvasRef.current) {
            fabricCanvasRef.current.resetFabricCanvas();
        }
        setImageUploaded(false);
        setSrc(null);
        setOriginalSrc(null);
        setActiveLayer('liquify');  // Reset to default layer
        setActiveButton('morfer');
        setOriginalDimensions({ width: 0, height: 0 });
        setCanvasWidthPercentage(80);
        setToolbarWidthPercentage(20);
        setOriginalDimensions({ width: 0, height: 0 });
        setImageUploaded(false);
    
        // Remove the frame overlay if it exists
        if (frameCanvasRef.current) {
            canvasFrameRef.current.removeChild(frameCanvasRef.current); // Updated here
            frameCanvasRef.current = null;
        }
    };
    


    const resetCanvasToOriginal = () => {
        setSrc(null);
        setTimeout(() => {
            setSrc(originalSrc);
            if (fabricCanvasRef.current) {
                fabricCanvasRef.current.resetFabricCanvas();
            }
            if (isWebGLAvailable && webGLCanvasRef.current && webGLCanvasRef.current.reset) {
                webGLCanvasRef.current.reset();
                
            } else if (canvasRef.current) {
                const context = canvasRef.current.getContext('2d');
                const img = new Image();
                img.onload = () => {
                    context.drawImage(img, 0, 0, canvasRef.current.width, canvasRef.current.height);
                };
                img.src = originalSrc;
            }
            setActiveLayer('liquify');  // Reset to default layer
            setActiveButton('morfer');
        }, 0);
    };

    const addText = () => {
        if (fabricCanvasRef.current) {
            const fabricCanvas = fabricCanvasRef.current.getCanvas();
            setActiveLayer('fabric');
            const text = new fabric.IText('ORDER OF FER', {
                left: 100,
                top: 100,
                fontFamily: 'Arial',
                fontSize: 24,
                fontWeight: 800,
                fill: 'white',
                selectable: true,
                shadow: {          // Changed from new fabric.Shadow to direct object
                    color: 'black',  // Changed from rgba to simple color
                    offsetX: -1,     // Integer value
                    offsetY: 1,      // Integer value
                    blur: 0
                }
            });
    
            fabricCanvas.add(text);
            fabricCanvas.setActiveObject(text);
            setSelectedText(text);
            setTextContent(text.text);
            setTextFontSize(text.fontSize);
            setTextColor(text.fill);
            setTextFontFamily(text.fontFamily);
        }
    };

    const deleteSelected = () => {
        if (fabricCanvasRef.current) {
            const fabricCanvas = fabricCanvasRef.current.getCanvas();
            const activeObject = fabricCanvas.getActiveObject();
            if (activeObject) {
                if (activeObject.type === 'activeSelection') {
                    activeObject.forEachObject((obj) => {
                        fabricCanvas.remove(obj);
                    });
                    fabricCanvas.discardActiveObject();
                } else {
                    fabricCanvas.remove(activeObject);
                }
                fabricCanvas.renderAll();
                setSelectedText(null);
            }
        }
    };


    const [authToken, setAuthToken] = useState(null);
    useEffect(() => {
        if (isAuthenticated && userAddress) {
            const token = getAuthToken(userAddress);
            setAuthToken(token);
        }
    }, [isAuthenticated, userAddress]);


    const [userToken, setUserToken] = useState(null);



    
const fetchAndCacheImage = useCallback(async (imageId) => {
    if (imageCache[imageId]) {
        return imageCache[imageId];
    }

    // Construct image URL based on authentication status
    const imageUrl = isAuthenticated && userToken
        ? `${process.env.REACT_APP_API_URL}/public/serve_image.php?id=${imageId}&token=${encodeURIComponent(userToken)}`
        : `${process.env.REACT_APP_API_URL}/public/serve_image.php?id=${imageId}`; // No token for unauthenticated

    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        
        setImageCache(prevCache => ({
            ...prevCache,
            [imageId]: objectUrl
        }));

        return objectUrl;
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    } finally {
        setLoadedImagesCount(prevCount => prevCount + 1);
    }
}, [userToken, isAuthenticated]);






useEffect(() => {
    const cacheAllImages = async () => {
      // Get the IDs of all images (from all categories)
      const allImageIds = libraryData.categories?.flatMap(category =>
        category.items.map(item => item.id)
      ) || [];
  
      // Filter out the ones we’ve already cached
      const uncachedImageIds = allImageIds.filter(id => !imageCache[id]);
  
      setTotalImagesCount(uncachedImageIds.length);
      setLoadedImagesCount(0);
  
      // BATCH SIZE (concurrency limit)
      const BATCH_SIZE = 5;
  
      // Process images in smaller batches of, e.g., 5
      for (let i = 0; i < uncachedImageIds.length; i += BATCH_SIZE) {
        const batch = uncachedImageIds.slice(i, i + BATCH_SIZE);
  
        // Fetch all items in this batch in parallel
        await Promise.all(batch.map(id => fetchAndCacheImage(id)));
        
        // Optional: add a short delay between batches to give the server a break
        // await new Promise(resolve => setTimeout(resolve, 100)); // 100ms delay
        // 100ms delay after each batch
        await new Promise(resolve => setTimeout(resolve, 100));

      }
  
      setIsLibraryLoaded(true);
    };
  
    if (libraryData.categories) {
      cacheAllImages();
    }
  }, [libraryData, userToken, fetchAndCacheImage]);
  

    const loadingPercentage = totalImagesCount > 0 
        ? Math.min(100, Math.round((loadedImagesCount / totalImagesCount) * 100))
        : 0;

    
        const addImageToCanvas = useCallback(async (imageId) => {
            setPaintEnabled(false);
            setActiveLayer('fabric');
            if (fabricCanvasRef.current) {
                const fabricCanvas = fabricCanvasRef.current.getCanvas();
                fabricCanvas.discardActiveObject();
        
                const imageSrc = await fetchAndCacheImage(imageId);
                if (!imageSrc) {
                    console.error('Failed to load image');
                    return;
                }
        
                // Find the category and item for the selected image
                const category = libraryData.categories.find(cat => 
                    cat.items.some(item => item.id === imageId)
                );
                const item = category ? category.items.find(item => item.id === imageId) : null;
        
                fabric.Image.fromURL(imageSrc, (img) => {
                    img.scaleToWidth(fabricCanvas.width / 4);
                    if (category && item) {
                        img.category = category.name;
                        img.name = item.name;
                    }
                    fabricCanvas.add(img);
                    img.bringToFront();
                    fabricCanvas.setActiveObject(img);
                    fabricCanvas.renderAll();
                }, { crossOrigin: 'anonymous' });
            }
        }, [fetchAndCacheImage, libraryData]);
        


    useEffect(() => {
        const fetchImageLibrary = async () => {

            try {
                let body = {};
                if (isAuthenticated && userAddress) {
                    const tokenObj = getAuthToken();
                    if (tokenObj && typeof tokenObj.value === 'string') {
                        body.address = userAddress;
                        body.token = tokenObj.value;
                    }
                }
    
                const response = await fetch(`${process.env.REACT_APP_API_URL}/public/get_image_library.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                });
    
                const data = await response.json();
                if (data.isValid || !isAuthenticated) { // Assuming backend returns public images when not authenticated
                    setLibraryData(data.imageLibrary);
                    if (isAuthenticated) {
                        setUserToken(body.token);
                    }
                } else {
                    console.error('Failed to fetch image library:', data.message);
                    // Optionally handle invalid session (e.g., redirect to login)
                }
            } catch (error) {
                console.error('Error fetching image library:', error);
            }
        };
    
        fetchImageLibrary();
    }, [isAuthenticated, userAddress]);
    
    

    const handleImageSelect = useCallback((imageId) => {
      
            addImageToCanvas(imageId);
   
        setIsLibraryModalOpen(false);
    }, [addImageToCanvas, userToken]);




    useEffect(() => {
        // Pre-fetch and cache all images when libraryData changes
        const cacheAllImages = async () => {
            const allImageIds = libraryData.categories?.flatMap(category => 
                category.items.map(item => item.id)
            ) || [];

            for (const imageId of allImageIds) {
                await fetchAndCacheImage(imageId);
            }
        };

        if (libraryData.categories && userToken) {
            cacheAllImages();
        }
    }, [libraryData, userToken, fetchAndCacheImage]);


    const handleMergeLayers = async () => {

        setTriggerDeselect(true);
    
        const { width, height } = originalDimensions;
        const frameThickness = 0; // No frame when merging layers

              // Remove watermarks before merging
              if (fabricCanvasRef.current) {
                const canvas = fabricCanvasRef.current.getCanvas();
                const watermarks = canvas.getObjects().filter(obj => obj.isWatermark || obj.isAuthenticatedText);
                watermarks.forEach(mark => canvas.remove(mark));
            }
        
    
        const combinedCanvas = document.createElement('canvas');
        combinedCanvas.width = width;
        combinedCanvas.height = height;
        const context = combinedCanvas.getContext('2d');
    
        // Clear the canvas with a transparent background
        context.clearRect(0, 0, combinedCanvas.width, combinedCanvas.height);
    
        // Draw layers without frameThickness
        await drawLayers(context, frameThickness, userTier, userMainName); // Pass user data

    
        // Get the merged image as a data URL
        const mergedImageDataUrl = combinedCanvas.toDataURL();
    
        // Reset the canvas state
        handleReset();
    
        // Load the merged image as the new base image
        handleImageUpload(mergedImageDataUrl, { width, height });
        setActiveLayer('liquify');
        setActiveButton('morfer');
        setTriggerDeselect(false);
    };
    
    


    return (
        <>
   
         <div className='largecomponentholder'>
         
            <div className='MorFERcontainer' style={{marginBottom: imageUploaded ? '3%' : '0'}}>
            <OrientationOverlay />
                <div className='title MorFER'>
                <span className='titletext large'>
    {isMobileDevice() ? ( // Mobile view
        'MorFER'
    ) : ( // Desktop view
        <> 
            Mor&#8202;&#8202;&#8202;&#8202;
            <span style={{ fontWeight:'800', transform:'rotate(90deg) translate(70%, 4%)', zIndex:'-1'}}>F</span>
            <span style={{position:'absolute', fontWeight:'700', transform:'translate(55%, -35%)', zIndex:'-1'}} >..</span>
            ER 
        </>
    )}
</span>
               
                </div>
          
                <div className='top-toolbar'>
                {imageUploaded && (
    <>
        <div>
            <button className='editorbutn newfer' onClick={handleReset}>New</button>
            <button className='editorbutn reset' onClick={resetCanvasToOriginal}>Refet</button>
            <button 
                className={`editorbutn frame ${frameVisible ? 'active' : ''}`}
                onClick={() => setFrameVisible(!frameVisible)}
                disabled={!isAuthenticated}
            >
                {frameVisible ? 'No Frame' : 'Frame'}
            </button>
            <button 
                className={`editorbutn save ${frameVisible && !getStoredText()?.trim() ? 'disabled' : ''}`}
                onClick={handleSave}
                disabled={frameVisible && !getStoredText()?.trim()}
            >
                {frameVisible && !getStoredText()?.trim() ? 'Title needed' : 'Download'}
            </button>
            {/* Toggle Frame Visibility Button */}
      
        </div>
    </>
)}

</div>

            <div className='MorFERholder' ref={holderRef}>
          
                <div className='editor-wrapper'>
                {imageUploaded && (
                                                <div className='toolbar-area right' style={{ 
                                                    width: `${toolbarWidthPercentage}%`,
                                                    padding: '10px',
                                                    maxWidth:'25%'
                                                }}>
                            <EditorToolbar
                                canvas={fabricCanvasRef.current ? fabricCanvasRef.current.getCanvas() : null}
                                activeLayer={activeLayer}
                                setActiveLayer={setActiveLayer}
                                addText={addText}
                                deleteSelected={deleteSelected}
                                selectedText={selectedText}
                                textContent={textContent}
                                setTextContent={setTextContent}
                                textFontSize={textFontSize}
                                setTextFontSize={setTextFontSize}
                                textColor={textColor}
                                setTextColor={setTextColor}
                                textFontFamily={textFontFamily}
                                setTextFontFamily={setTextFontFamily}
                                addImageToCanvas={addImageToCanvas}
                                morferBrushSize={morferBrushSize}
                                setMorferBrushSize={setMorferBrushSize}
                                paintBrushSize={paintBrushSize}
                                setPaintBrushSize={setPaintBrushSize}
                                smoothing={smoothing}
                                setSmoothing={setSmoothing}
                                brushColor={brushColor}
                                setBrushColor={setBrushColor}
                                paintEnabled={paintEnabled}
                                setPaintEnabled={setPaintEnabled}
                                setTextboxVisible={setTextboxVisible}
                                setTextboxValue={setTextboxValue}
                                libraryData={libraryData}
                                setIsLibraryModalOpen={setIsLibraryModalOpen}
                                setSelectedCategory={setSelectedCategory}
                                strength={strength}
                                setStrength={setStrength}
                                handleUndo={handleUndo} 
                                handleRedo={handleRedo}
                                activeButton={activeButton}
                                setActiveButton={setActiveButton}                                  
                                userTier= {userTier}
                            />
                            </div>
                            
                        )}
                
                <div
  className='canvas-area'
  style={{
    width: `${canvasWidthPercentage}%`,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '80%',
    marginTop: frameVisible
      ? `${Math.min(imageDimensions.width, imageDimensions.height) * 0.07}px`
      : '0px',
    marginLeft: frameVisible
      ? `${Math.min(imageDimensions.width, imageDimensions.height) * 0.07}px`
      : '0px',
    marginRight: frameVisible
      ? `${Math.min(imageDimensions.width, imageDimensions.height) * 0.07}px`
      : '0px',
  }}
  ref={containerRef}
  onMouseMove={handleMouseMove}
>

    {!imageUploaded ? (
        <ImageUploader onUpload={handleImageUpload} />
    ) : (
        <div
    className='canvasframe'
    style={{ position: 'relative' }}
    ref={canvasFrameRef} 
>
                                {isWebGLAvailable ? (
                                    <WebGLLiquify
                                        ref={webGLCanvasRef}
                                        imageUrl={isImageLoaded && imageDimensions.width > 0 && src ? src : null}
                                        width={imageDimensions.width}
                                        height={imageDimensions.height}
                                        brushSize={morferBrushSize}
                                        strength={strength}
                                        smoothing={smoothing}
                                        activeLayer={activeLayer}
                                        onWebGLAvailability={handleWebGLAvailability}
                                        userTier={userTier}
                                    />
                                ) : (
                                    <CanvasLiquify
                                        src={src}
                                        canvasRef={canvasRef}
                                        imageRef={imageRef}
                                        dimensions={imageDimensions}
                                        brushSize={morferBrushSize}
                                        smoothing={smoothing}
                                        activeLayer={activeLayer}
                                    />
                                )}
                                <FabricCanvas
                                    key={`fabric-${src}`}
                                    ref={fabricCanvasRef}
                                    activeLayer={activeLayer}
                                    setActiveLayer={setActiveLayer}
                                    triggerDeselect={triggerDeselect}
                                    dimensions={imageDimensions}
                                    paintBrushSize={paintBrushSize}
                                    setPaintBrushSize={setPaintBrushSize}
                                    smoothing={smoothing}
                                    setSmoothing={setSmoothing}
                                    setSelectedText={setSelectedText}
                                    setTextContent={setTextContent}
                                    setTextFontSize={setTextFontSize}
                                    setTextColor={setTextColor}
                                    setTextFontFamily={setTextFontFamily}
                                    brushColor={brushColor}
                                    setBrushColor={setBrushColor}                                    
                                    paintEnabled={paintEnabled}
                                    textboxVisible={textboxVisible}
                                    setTextboxVisible={setTextboxVisible}
                                    textboxPosition={textboxPosition}
                                    setTextboxPosition={setTextboxPosition}
                                    textboxValue={textboxValue}
                                    setTextboxValue={setTextboxValue}
                                    onLayersChange={handleLayersChange}
                                    onSelectionChange={handleSelectionChange}
                                    
                                />
            <WaterMarkText 
                fabricCanvas={fabricCanvasRef.current?.getCanvas()}
                isAuthenticated={isAuthenticated}
                frameVisible={frameVisible} // Pass the frameVisible state
            />
{activeLayer === 'fabric' && paintEnabled && (
                                            <div
                                                className="paint-brush-indicator"
                                                style={{
                                                    width: `${paintBrushSize}px`,
                                                    height: `${paintBrushSize}px`,
                                                    borderRadius: '50%',
                                                    borderStyle: 'solid',
                                                    borderWidth: '1px',
                                                    borderColor:`${brushColor}`,
                                                    backgroundColor: `${brushColor}`, // Use 20% opacity
                                                    position: 'absolute',
                                                    pointerEvents: 'none',
                                                    left: `${cursorPosition.x * 100}%`,
                                                    top: `${cursorPosition.y * 100}%`,
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1000,
                                                }}
                                            />
                                        )}

                            </div>
                        )}

                    </div> 

                    {imageUploaded && (

<div className='toolbar-area left'
 style={{ 
    width: `${toolbarWidthPercentage}%`,
    padding: '10px',
    maxWidth:'25%'
}}>


<button 
    className='toolbarbutn clofet'
    onClick={() => {
        setActiveLayer('select');
        setIsLibraryModalOpen(true);
        setActiveLayer('fabric');
        setActiveButton('select');
    }}
    disabled={!isLibraryLoaded}
>
    <img 
        src={process.env.PUBLIC_URL + '/clofet.png'}
        alt='clofet'
        className='toolbarbutn-image'
    />
    {!isLibraryLoaded && loadingPercentage < 100 ? (
        <span className="toolbarbutn-text">
            Clofet: {loadingPercentage}%
        </span>
    ) : null}
</button>







<LayerList 
layers={layers}
onSelectLayer={handleLayerSelect}
selectedLayers={selectedLayers}
onToggleVisibility={handleToggleLayerVisibility}
onDeleteLayers={handleDeleteLayers}
handleMergeLayers={handleMergeLayers}
/>

<button className='toolbarbutn' onClick={deleteSelected}>
                <img 
                    src={process.env.PUBLIC_URL +'/delete.png'}
                    alt="felect Icon" 
                    className='toolbarbutn-image'
                />

            </button>

</div>


)}


                        
                        </div>
                </div>

                <ImageLibraryModal
                isOpen={isLibraryModalOpen}
                onClose={() => {
                    setIsLibraryModalOpen(false);
                    setSelectedCategory(null);
                }}
                libraryData={libraryData}
                onSelectImage={handleImageSelect}
                selectedCategory={selectedCategory}
                imageCache={imageCache}
                setActiveLayer={setActiveLayer}
                setActiveButton={setActiveButton}
            />
            </div>
            </div>
           
        </>
    );
};

export default ImageEditorContainer;

