// config.js

const config = {
  chain: parseInt(process.env.REACT_APP_CHAIN_ID, 10), // Sepolia testnet chain ID
  adminAddress: process.env.REACT_APP_ADMIN_ADDRESS,
  readOnlyRpcUrl:process.env.REACT_APP_ETHERS_RPC_URL,
  mainnet: {
    chainId: parseInt(8453, 10),
    name: process.env.REACT_APP_MAINNET_NAME,
    currency: process.env.REACT_APP_CURRENCY,
    explorerUrl: process.env.REACT_APP_EXPLORER_URL,
    rpcUrl: process.env.REACT_APP_RPC_URL,
  },
  metadata: {
    name: process.env.REACT_APP_METADATA_NAME,
    description: process.env.REACT_APP_METADATA_DESCRIPTION,
    url: process.env.REACT_APP_METADATA_URL,
    icons: [process.env.REACT_APP_METADATA_ICONS],
  },
  projectId: process.env.REACT_APP_PROJECT_ID, // Web3 project ID
  ethersConfig: {
    enableEIP6963: process.env.REACT_APP_ETHERS_ENABLE_EIP6963 === 'true',
    enableInjected: process.env.REACT_APP_ETHERS_ENABLE_INJECTED === 'true',
    enableCoinbase: process.env.REACT_APP_ETHERS_ENABLE_COINBASE === 'true',
    rpcUrl: process.env.REACT_APP_ETHERS_RPC_URL,
    defaultChainId: parseInt(8453, 10),
  },
};

export default config;
