// src/components/VotingComponentLogic.js

import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { WalletContext } from '../../WalletContext';
import { DataContext } from '../../DataProvider';
import { formatEther, convertToNumber } from '../../utils/formatUtils';

const useVotingComponentLogic = () => {
  // ---------------------- State Variables ----------------------
  const [availableNames, setAvailableNames] = useState([]);
  const [selectedNameTokenId, setSelectedNameTokenId] = useState(null);
  const [candidateNickname, setCandidateNickname] = useState('');
  const [pointsToAllocate, setPointsToAllocate] = useState('');
  const [seasonId, setSeasonId] = useState(null);
  const [currentSeason, setCurrentSeason] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [seasonStatus, setSeasonStatus] = useState('loading');
  const [timeLeft, setTimeLeft] = useState('');
  const [claimableRewards, setClaimableRewards] = useState('0');
  const [claimableRewardsDetails, setClaimableRewardsDetails] = useState([]);
  const [isClaimingRewards, setIsClaimingRewards] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [availablePoints, setAvailablePoints] = useState(0);
  const [usedPoints, setUsedPoints] = useState(0);
  const [seasons, setSeasons] = useState([]);
  const [votingHistory, setVotingHistory] = useState([]);
  const [rewardsSummary, setRewardsSummary] = useState({ totalClaimed: '0', pending: '0' });
  const [blacklistStatus, setBlacklistStatus] = useState(false);
  const [participationPercent, setParticipationPercent] = useState(0);
  const [isLoadingSeasonData, setIsLoadingSeasonData] = useState(true);
  const [valuePerPoint, setValuePerPoint] = useState(0);
  const [isLoadingValuePerPoint, setIsLoadingValuePerPoint] = useState(true);
  const [totalVotesReceived, setTotalVotesReceived] = useState(0);
  const [votesPerName, setVotesPerName] = useState([]);
  const [isLoadingVotesReceived, setIsLoadingVotesReceived] = useState(false);
  const [votingData, setVotingData] = useState([]);
  const [isVotingDataLoading, setIsVotingDataLoading] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [inputName, setInputName] = useState('');
  const [isNameValid, setIsNameValid] = useState(null);
  const [timeLeftPercent, setTimeLeftPercent] = useState(0);

  const [votesReceivedPerSeason, setVotesReceivedPerSeason] = useState({});
const [rewardsPerSeason, setRewardsPerSeason] = useState({});

const dataFetchedRef = useRef(false);






const [isLoadingSeasons, setIsLoadingSeasons] = useState(false);

const [isLoadingClaimableRewards, setIsLoadingClaimableRewards] = useState(false);
const [isLoadingRewardsSummary, setIsLoadingRewardsSummary] = useState(false);
const [isLoadingVotingHistory, setIsLoadingVotingHistory] = useState(false);


  // ------------------------- Context ---------------------------
  const {
    isAuthenticated,
    userAddress,
    contracts,
    contractsReady,
  } = useContext(WalletContext);

  const { presaleEnded, seasonActive, userMainName } = useContext(DataContext);


  const [actualClaimableRewards, setActualClaimableRewards] = useState([]);
  const [estimatedRewards, setEstimatedRewards] = useState([]);



  

  // ---------------------- useEffect Hooks ----------------------
  useEffect(() => {
    if (contractsReady && !dataFetchedRef.current) {
      dataFetchedRef.current = true;
      fetchData();
    }
  }, [isAuthenticated, contractsReady]);


  useEffect(() => {
    if (inputName.trim() === '') {
      setIsNameValid(null);
      setSelectedNameTokenId(null);
      setCandidateNickname('');
      return;
    }

    const handler = setTimeout(() => {
      checkNameExists(inputName);
    }, 1000); // 1-second debounce

    return () => {
      clearTimeout(handler);
    };
  }, [inputName]);

  // ------------------------ Functions --------------------------

  const resetState = () => {
    setAvailableNames([]);
    setSelectedNameTokenId(null);
    setCandidateNickname('');
    setSeasonStatus('loading');
    setTimeLeft('');
    setClaimableRewards('0');
    setClaimableRewardsDetails([]);
    setTotalPoints(0);
    setAvailablePoints(0);
    setUsedPoints(0);
    setSeasons([]);
    setVotingHistory([]);
    setVotingData([]);
    setRewardsSummary({ totalClaimed: '0', pending: '0' });
    setBlacklistStatus(false);
  };

  
  const checkNameExists = useCallback(async (name) => {
    try {
      if (!contracts.nameRegistryContract || !name.trim()) {
        setIsNameValid(null);
        setSelectedNameTokenId(null);
        setCandidateNickname('');
        return;
      }
  
      const normalizedInput = name.trim().toUpperCase();
      const nameId = await contracts.nameNFTContract.getTokenIdByName(normalizedInput);
      const nameInfo = await contracts.nameRegistryContract.getName(nameId);
  
      const isValid = nameInfo.name.toUpperCase() === normalizedInput && nameInfo.minted && !nameInfo.delisted;
      setIsNameValid(isValid);
      setSelectedNameTokenId(isValid ? nameId : null);
      setCandidateNickname(isValid ? nameInfo.name : '');
  
    } catch {
      setIsNameValid(false);
      setSelectedNameTokenId(null);
      setCandidateNickname('');
    }
  }, [contracts.nameRegistryContract, contracts.nameNFTContract]);

  const determineSeasonStatus = useCallback((season) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const startTime = season.startTime;
    const endTime = season.endTime;
    const isActive = season.isActive;
  
    try {
      let status;
      let timeLeft;
      let timeLeftPercent;
  
      if (currentTime < startTime) {
        status = 'not_active';
        timeLeft = startTime - currentTime;
        timeLeftPercent = 100;
      } else if (currentTime >= startTime && currentTime < endTime && isActive) {
        status = 'active';
        const totalDuration = endTime - startTime;
        const timeRemaining = endTime - currentTime;
        timeLeftPercent = totalDuration > 0
          ? Math.max((timeRemaining / totalDuration) * 100, 0)
          : 0;
        timeLeft = timeRemaining;
      } else if (currentTime >= endTime && isActive) {
        status = 'concluding';
        timeLeft = currentTime - endTime; // Time since it ended
        timeLeftPercent = 0;
      } else if (!isActive && !endTime) {
        status = 'not ftarted';
        timeLeft = startTime - currentTime;
        timeLeftPercent = 0;
      } else {
        status = 'ended';
        timeLeft = currentTime - endTime; // Time since it ended
        timeLeftPercent = 0;
      }
  
      return { status, timeLeft, timeLeftPercent };
    } catch (error) {
      console.error('Error in determineSeasonStatus:', error);
      return { status: 'error', timeLeft: 0, timeLeftPercent: 0 };
    }
  }, []);


  useEffect(() => {
    let timer;
    if (seasonStatus === 'active' || seasonStatus === 'concluding') {
      timer = setInterval(() => {
        if (seasonId && contracts.votingManagerContract) {
          contracts.votingManagerContract.getSeasonData(seasonId)
            .then(seasonData => {
              const startTime = convertToNumber(seasonData.startTime);
              const endTime = convertToNumber(seasonData.endTime);
              const isActive = seasonData.isActive;
  
              // Create a season object to pass to determineSeasonStatus
              const season = {
                startTime: startTime,
                endTime: endTime,
                isActive: isActive,
              };
  
              // Use determineSeasonStatus to get the current status and timeLeft
              const { status, timeLeft, timeLeftPercent } = determineSeasonStatus(season);
  
              // Update state accordingly
              setSeasonStatus(status);
              setTimeLeft(timeLeft);
              setTimeLeftPercent(timeLeftPercent);
            })
            .catch(error => {
              console.error('Error updating season data:', error);
            });
        }
      }, 60000); // Update every minute
    }
  
    return () => clearInterval(timer);
  }, [seasonStatus, seasonId, contracts.votingManagerContract, determineSeasonStatus]);
  
  
  

  const getUserNameTokenIds = async () => {
    try {
      const balance = await contracts.nameNFTContract.balanceOf(userAddress);
      const tokenIds = [];
      for (let i = 0; i < balance; i++) {
        const tokenId = await contracts.nameNFTContract.tokenOfOwnerByIndex(userAddress, i);
        tokenIds.push(tokenId.toString());
      }
      return tokenIds;
    } catch (error) {
      console.error('Error fetching user name token IDs:', error);
      return [];
    }
  };

  const fetchData = async () => {
    if (!contractsReady) {
     
      return;
    }

    setIsProcessing(true);

    try {
      // Fetch current season
      const currentSeasonValue = await contracts.votingManagerContract.currentSeason();
      const currentSeasonId = Math.min(Number(currentSeasonValue), 5);

      

      // Set current season
      setCurrentSeason(currentSeasonId);
      setSeasonId(currentSeasonId);

      // Fetch season data
      const seasonData = await contracts.votingManagerContract.getSeasonData(currentSeasonId);
      const startTime = convertToNumber(seasonData.startTime);
      const endTime = convertToNumber(seasonData.endTime);
      const isActive = seasonData.isActive;
      const seasonVotingPotBN = seasonData.seasonVotingPot;

      // Determine season status
      const season = {
        startTime: startTime,
        endTime: endTime,
        isActive: isActive,
      };
      const { status, timeLeft, timeLeftPercent } = determineSeasonStatus(season);
      setSeasonStatus(status);
      setTimeLeft(timeLeft);
      setTimeLeftPercent(timeLeftPercent);
     

      // Fetch total points and used points
      const totalUserPointsBN = await contracts.memeNFTContract.getUserPoints(userAddress);
      const totalUserPoints = Number(totalUserPointsBN);
      setTotalPoints(totalUserPoints);

      let pointsUsed = 0;
      if (status === 'active' || status === 'concluding') {
        const fetchedUsedPoints = await contracts.votingManagerContract.userVotesInSeason(currentSeasonValue, userAddress);
        pointsUsed = Number(fetchedUsedPoints);
        setUsedPoints(pointsUsed);

      } else {
        setUsedPoints(0);
      }
      setAvailablePoints(totalUserPoints - pointsUsed);

      // Fetch total votes and total voting power
      const totalVotesBN = seasonData.totalVotes;
      const totalVotingPowerStoredBN = seasonData.totalVotingPower;

      let totalVotingPowerBN = BigInt(totalVotingPowerStoredBN.toString());

      // Use snapshotted totalVotingPower if available
      if (totalVotingPowerBN === 0n) {
        // Fetch current total voting power from MemeNFT
        const freshTotalPoints = await contracts.memeNFTContract.totalPoints();
        totalVotingPowerBN = BigInt(freshTotalPoints.toString());
      }

      const totalVotes = BigInt(totalVotesBN.toString());
      const totalVotingPower = totalVotingPowerBN;
      const seasonVotingPot = BigInt(seasonVotingPotBN.toString());
      const PRECISION = BigInt(1e18);

      if (totalVotingPower > 0n) {
        const participationRate = (totalVotes * PRECISION) / totalVotingPower;
        const participationPercentValue = Number(participationRate) / Number(PRECISION) * 100;
        setParticipationPercent(participationPercentValue);

        const adjustedSeasonPot = (seasonVotingPot * participationRate) / PRECISION;
        const valuePerPointBN = adjustedSeasonPot / totalVotingPower;
        const valuePerPointValue = Number(formatEther(valuePerPointBN.toString()));
        setValuePerPoint(valuePerPointValue);
      } else {
        setParticipationPercent(0);
        setValuePerPoint(0);
      }

      // Fetch seasons
      const totalVotingPot = BigInt(await contracts.votingManagerContract.getTotalVotingPot());
      const seasonAllocations = await contracts.tokenomicsManagerContract.getSeasonAllocations();
      
      // Replace the existing seasons fetching code:
      setIsLoadingSeasons(true);
      const totalSeasons = Number(await contracts.votingManagerContract.getTotalSeasons());
      const seasonsData = [];
      for (let i = 1; i <= totalSeasons; i++) {
        try {
          const [
            totalVotes,
            totalVotingPower,
            seasonVotingPot,
            startTime,
            endTime,
            isActive
          ] = await contracts.votingManagerContract.getSeasonData(i);
      
          // If seasonVotingPot is zero, calculate expected pot from allocations
          let finalSeasonVotingPot = seasonVotingPot;
          if (finalSeasonVotingPot.toString() === '0') {
            const allocationPercentage = Number(seasonAllocations[i - 1]);
            finalSeasonVotingPot = (totalVotingPot * BigInt(allocationPercentage)) / BigInt(100);
          }
      
          const season = {
            id: i,
            totalVotes: totalVotes.toString(),
            totalVotingPower: totalVotingPower.toString(),
            seasonVotingPot: formatEther(finalSeasonVotingPot),
            startTime: convertToNumber(startTime),
            endTime: convertToNumber(endTime),
            isActive: isActive,
          };
      
          const { status, timeLeft, timeLeftPercent } = determineSeasonStatus(season);
          season.status = status;
          season.timeLeft = timeLeft;
          season.timeLeftPercent = timeLeftPercent;
      
          seasonsData.push(season);
        } catch (error) {
          console.error(`Error fetching data for season ${i}:`, error);
        }
      }
      setSeasons(seasonsData);
      setIsLoadingSeasons(false);


      setIsLoadingClaimableRewards(true);
try {
  const claimableDetails = [];
  const estimatedDetails = [];
  let totalClaimable = BigInt(0);
  const nameTokenIds = await getUserNameTokenIds();



  let totalSeasons = seasonsData.length; // Use seasonsData.length
  if (totalSeasons === 0) {
    totalSeasons = Number(await contracts.votingManagerContract.getTotalSeasons());
  }

  for (let i = 1; i <= totalSeasons; i++) {
    for (const nameTokenId of nameTokenIds) {
      try {
        const [votes, claimed] = await contracts.votingManagerContract.getSeasonVotes(i, nameTokenId);

        if (BigInt(votes) > BigInt(0)) {
          const estimatedReward = await contracts.votingManagerContract.getEstimatedReward(i, nameTokenId);

          // Check if the season has ended
          const season = seasonsData.find(s => s.id === i); // Use seasonsData instead of seasons
          const seasonStatus = season ? season.status : 'unknown';

          if (!claimed && seasonStatus === 'ended') {
            totalClaimable += BigInt(estimatedReward);

            claimableDetails.push({
              seasonId: i,
              nameTokenId: nameTokenId,
              reward: estimatedReward.toString()
            });
          }

          // Collect estimated rewards for active or concluding seasons
          if (seasonStatus === 'active' || seasonStatus === 'concluding') {
            
            estimatedDetails.push({
              seasonId: i,
              nameTokenId: nameTokenId,
              reward: estimatedReward.toString()
            });
          }
        }
      } catch (error) {
        console.error(`Error processing nameTokenId ${nameTokenId} in season ${i}:`, error);
      }
    }
  }

  const votesReceivedPerSeasonTemp = {};
const rewardsPerSeasonTemp = {};

// Fetch votes received per season
for (const nameTokenId of nameTokenIds) {
  for (let seasonId = 1; seasonId <= totalSeasons; seasonId++) {
    const [votes, claimed] = await contracts.votingManagerContract.getSeasonVotes(seasonId, nameTokenId);
    
    // Initialize seasonId object if it doesn't exist
    if (!votesReceivedPerSeasonTemp[seasonId]) {
      votesReceivedPerSeasonTemp[seasonId] = {};
      rewardsPerSeasonTemp[seasonId] = BigInt(0);
    }
    
    // Store votes for each nameTokenId within the season
    if (BigInt(votes) > 0) {
      votesReceivedPerSeasonTemp[seasonId][nameTokenId] = votes.toString();
      const estimatedReward = await contracts.votingManagerContract.getEstimatedReward(seasonId, nameTokenId);
      rewardsPerSeasonTemp[seasonId] += BigInt(estimatedReward);
    }
  }
}

setVotesReceivedPerSeason(votesReceivedPerSeasonTemp);
setRewardsPerSeason(rewardsPerSeasonTemp);

 
  setClaimableRewards(formatEther(totalClaimable));
  setClaimableRewardsDetails(claimableDetails);
  setEstimatedRewards(estimatedDetails);
  setActualClaimableRewards(claimableDetails);

} catch (error) {
  console.error('Error fetching claimable rewards:', error);
} finally {
  setIsLoadingClaimableRewards(false);
}


      // Fetch voting data
      setIsVotingDataLoading(true);
      try {
        const [nameTokenIds, votes, claimStatuses, claimTimes] =
          await contracts.votingManagerContract.getAllSeasonVotes(currentSeasonId);

        const names = await Promise.all(
          nameTokenIds.map(async (tokenId) => {
            try {
              const info = await contracts.nameRegistryContract.getName(tokenId);
              return info.name;
            } catch (err) {
              console.error(`Error fetching name for token ${tokenId}:`, err);
              return 'Unknown';
            }
          })
        );

        const formattedData = nameTokenIds.map((tokenId, index) => ({
          tokenId: tokenId.toString(),
          name: names[index],
          votes: votes[index]?.toString() || '0',
          claimed: claimStatuses[index] || false,
          claimTime: claimTimes[index]?.toString() || '0'
        }));

        formattedData.sort((a, b) => Number(b.votes) - Number(a.votes));

        setVotingData(formattedData);
      } catch (error) {
        console.error('Error fetching voting data:', error);
        setVotingData([]);
      } finally {
        setIsVotingDataLoading(false);
      }

      // Fetch blacklist status
      const isBlacklisted = await contracts.votingManagerContract.blacklistedUsers(userAddress);
      setBlacklistStatus(isBlacklisted);

    


      // Fetch rewards summary
      setIsLoadingRewardsSummary(true);
      try {
        let totalClaimed = BigInt(0);
        let pending = BigInt(0);

        const nameTokenIds = await getUserNameTokenIds();

        for (let i = 1; i <= totalSeasons; i++) {
          for (const nameTokenId of nameTokenIds) {
            const claimed = await contracts.votingManagerContract.hasClaimedRewards(i, nameTokenId);
            if (claimed) {
              const reward = await contracts.votingManagerContract.getEstimatedReward(i, nameTokenId);
              totalClaimed += BigInt(reward);
            }
          }
        }

        pending = BigInt(claimableRewardsDetails.reduce((sum, detail) => sum + BigInt(detail.reward), BigInt(0)));

        setRewardsSummary({
          totalClaimed: formatEther(totalClaimed),
          pending: formatEther(pending),
        });
      } catch (error) {
        console.error('Error fetching rewards summary:', error);
      } finally {
        setIsLoadingRewardsSummary(false);
      }

      // Fetch votes received
      setIsLoadingVotesReceived(true);
      try {
        const nameTokenIds = await getUserNameTokenIds();
        let totalVotes = BigInt(0);
        const votesPerNameArr = [];

        for (const nameTokenId of nameTokenIds) {
          try {
            const nameInfo = await contracts.nameRegistryContract.getName(nameTokenId);
            let votesForName = BigInt(0);

            for (let seasonId = 1; seasonId <= totalSeasons; seasonId++) {
              try {
                const [votes, claimed] = await contracts.votingManagerContract.getSeasonVotes(seasonId, nameTokenId);
                votesForName += BigInt(votes);
              } catch (error) {
                console.error(`Error fetching votes for nameTokenId ${nameTokenId} in season ${seasonId}:`, error);
              }
            }

            votesPerNameArr.push({
              name: nameInfo.name,
              tokenId: nameTokenId.toString(),
              totalVotesReceived: votesForName.toString(),
            });

            totalVotes += votesForName;
          } catch (error) {
            console.error(`Error processing nameTokenId ${nameTokenId}:`, error);
          }
        }

        setVotesPerName(votesPerNameArr);
        setTotalVotesReceived(Number(totalVotes));
      } catch (error) {
        console.error('Error fetching votes received:', error);
      } finally {
        setIsLoadingVotesReceived(false);
      }

// Optimized Fetch Voting History
setIsLoadingVotingHistory(true);
try {
  const history = [];

  for (let seasonId = 1; seasonId <= totalSeasons; seasonId++) {
    // Get active candidates
    const nameTokenIds = await contracts.votingManagerContract.getActiveCandidates(seasonId);

    // Batch ownerOf calls
    const ownerOfPromises = nameTokenIds.map((tokenId) =>
      contracts.nameNFTContract.ownerOf(tokenId)
    );
    const candidateOwners = await Promise.all(ownerOfPromises);

    // Pair tokenIds with their owners
    const candidates = nameTokenIds.map((tokenId, index) => ({
      tokenId: tokenId.toString(),
      candidateOwner: candidateOwners[index].toLowerCase(),
    }));

    // Filter out candidates owned by the user
    const otherCandidates = candidates.filter(
      (c) => c.candidateOwner !== userAddress.toLowerCase()
    );

    // Batch voterToCandidateOwnerVotesInSeason calls
    const votesPromises = otherCandidates.map((candidate) =>
      contracts.votingManagerContract.voterToCandidateOwnerVotesInSeason(
        seasonId,
        userAddress,
        candidate.candidateOwner
      )
    );
    const pointsAllocatedArray = await Promise.all(votesPromises);

    // Filter candidates where the user allocated points
    const votedCandidates = otherCandidates
      .map((candidate, index) => ({
        ...candidate,
        pointsAllocated: pointsAllocatedArray[index].toString(),
      }))
      .filter((candidate) => Number(candidate.pointsAllocated) > 0);

    if (votedCandidates.length === 0) continue;

    // Batch getSeasonVotes calls
    const seasonVotesPromises = votedCandidates.map((candidate) =>
      contracts.votingManagerContract.getSeasonVotes(seasonId, candidate.tokenId)
    );
    const seasonVotesResults = await Promise.all(seasonVotesPromises);

    // Get season data once per season
    const seasonData = await contracts.votingManagerContract.getSeasonData(seasonId);
    const [totalVotes, totalVotingPower, seasonVotingPot, startTime] = seasonData;
    const timestampNumber = convertToNumber(startTime);

    // Build history
    votedCandidates.forEach((candidate, index) => {
      const [votes, claimed, claimTime, names] = seasonVotesResults[index];
      const displayName = names && names.length > 0 ? names[0] : 'Unknown';

      history.push({
        seasonId: seasonId.toString(),
        nameTokenId: candidate.tokenId,
        name: displayName,
        candidateOwner: candidate.candidateOwner,
        pointsAllocated: candidate.pointsAllocated,
        timestamp: timestampNumber,
      });
    });
  }

  // Sort history
  history.sort((a, b) => b.timestamp - a.timestamp);
  setVotingHistory(history);
} catch (error) {
  console.error('Error fetching voting history:', error);
  setVotingHistory([]);
} finally {
  setIsLoadingVotingHistory(false);
}



    } catch (error) {
      console.error('Error in fetchData:', error);
    } finally {
      setIsProcessing(false);
    }
  };


  const handleAllocatePoints = async () => {
    if (!selectedNameTokenId) {
      alert('Please select a candidate name.');
      return;
    }

    const points = parseInt(pointsToAllocate, 10);
    if (isNaN(points) || points <= 0) {
      alert('Please enter a valid number of points.');
      return;
    }

    if (points > availablePoints) {
      alert(`You can allocate up to ${availablePoints} points.`);
      return;
    }

    setIsProcessing(true);
    try {
      const tx = await contracts.votingManagerContract.castVote(points, selectedNameTokenId);
      await tx.wait();
      alert('Vote cast successfully.');

      await fetchData();
      setPointsToAllocate('');
    } catch (error) {
      console.error('Error casting vote:', error);
      alert(`Failed to cast vote: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleClaimReward = async (seasonId, nameTokenId) => {
    if (!contracts.votingManagerContract || !contracts.nameNFTContract || !userAddress) return;

    const rewardDetail = claimableRewardsDetails.find(detail => detail.seasonId === seasonId && detail.nameTokenId === nameTokenId);
    if (!rewardDetail) {
      alert('No such reward available to claim.');
      return;
    }


    setIsClaimingRewards(true);
    try {
      const tx = await contracts.votingManagerContract.claimVotingRewards(seasonId, nameTokenId);
      await tx.wait();
      alert(`Successfully claimed rewards for Season ${seasonId}, Token ID ${nameTokenId}.`);

      await fetchData();
    } catch (error) {
      console.error(`Error claiming rewards for Season ${seasonId}, Token ID ${nameTokenId}:`, error);
      alert(`Failed to claim rewards: ${error.message}`);
    } finally {
      setIsClaimingRewards(false);
    }
  };

  const handleClaimAllRewards = async () => {
    if (!contracts.votingManagerContract || !contracts.nameNFTContract || !userAddress) return;

    const confirmClaim = window.confirm(`Are you sure you want to claim all available rewards?`);
    if (!confirmClaim) return;

    setIsClaimingRewards(true);
    try {
      for (const { seasonId, nameTokenId } of actualClaimableRewards) {
        try {
          const tx = await contracts.votingManagerContract.claimVotingRewards(seasonId, nameTokenId);
          await tx.wait();
          
        } catch (error) {
          console.error(`Error claiming rewards for Season ${seasonId}, Token ID ${nameTokenId}:`, error);
        }
      }
      alert('All rewards claimed successfully!');
      await fetchData();
    } catch (error) {
      console.error('Error claiming all rewards:', error);
      alert('Failed to claim all rewards. Please try again.');
    } finally {
      setIsClaimingRewards(false);
    }
  };

  const handleRowClick = (tokenId, name) => {
    setSelectedCandidate(tokenId);
  };

  // ------------------------ Return --------------------------

  return {
    // State Variables
    availableNames,
    selectedNameTokenId,
    candidateNickname,
    pointsToAllocate,
    seasonId,
    currentSeason,
    isProcessing,
    seasonStatus,
    timeLeft,
    claimableRewards,
    claimableRewardsDetails,
    isClaimingRewards,
    totalPoints,
    availablePoints,
    usedPoints,
    seasons,
    votingHistory,
    rewardsSummary,
    blacklistStatus,
    valuePerPoint,
    isLoadingValuePerPoint,
    participationPercent,
    totalVotesReceived,
    votesPerName,
    isLoadingVotesReceived,
    presaleEnded,
    seasonActive,
    userAddress,
    userMainName,
    inputName,
    isNameValid,
    setInputName,
    timeLeftPercent,
    actualClaimableRewards,
    estimatedRewards,
    votesReceivedPerSeason,
    rewardsPerSeason,

    // Functions
    handleAllocatePoints,
    handleClaimReward,
    handleClaimAllRewards,
    setPointsToAllocate,
    votingData,
    isVotingDataLoading,
    selectedCandidate,
    handleRowClick,

    isLoadingSeasons,
    isLoadingClaimableRewards,
    isLoadingRewardsSummary,
    isLoadingVotingHistory
  };
};



export default useVotingComponentLogic;
