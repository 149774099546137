import React, { useState, useContext, useEffect } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from '../WalletContext';
import { DataContext } from '../DataProvider';
import { useImage } from './ImageContext';
import CurtainComponent from './CurtainComponent';
import ImageViewer from './ImageViewer';
import NFTPreviewImage from './NFTPreviewImage';
import { formatAmount } from '../utils/formatUtils';
import LegalDisclaimer from './LegalDisclaimer'; // Ensure this path is correct

import ferCoinLogo from '../fer_coin_logo.png';

// Removed static imports and will use images from context instead
// import tier1 from '../tier1.png';
// import tier2 from '../tier2.png';
// import tier3 from '../tier3.png';

// Removed static tierImages object
// const tierImages = {
//   1: tier1,
//   2: tier2,
//   3: tier3
// };

const PostPresaleDashboard = ({ setIsImageViewerOpen }) => {
  // Existing State Variables
  const [isProcessing, setIsProcessing] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const { 
    isAuthenticated, 
    isCorrectChain, 
    signer, 
    contracts,
    contractsReady, 
    userAddress, 
  } = useContext(WalletContext);

  const {  
    refreshNFTData,
    allTierImages,
    imagesLoaded,
    refreshData,
    optimizedTierImages,
    nftData 
  } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [tierCounts, setTierCounts] = useState({ 1: 0, 2: 0, 3: 0 });
  const [totalCost, setTotalCost] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [closing, setClosing] = useState(false);

  const [viewerOpen, setViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { setImage } = useImage(); // Using ImageContext for dynamic image handling
  const [referrerName, setReferrerName] = useState('');

  const [presaleEnded, setPresaleEnded] = useState(false);

  const [claimableRewards, setClaimableRewards] = useState('0');
  const [isClaimingRewards, setIsClaimingRewards] = useState(false);

  // New State Variables for Disclaimer and Checkboxes
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
  });

  const [hasConfirmedPurchase, setHasConfirmedPurchase] = useState(false);

  const TIER_PRICES = {
    1: 1000000, // Tier 1 price in tokens
    2: 100000,  // Tier 2 price in tokens
    3: 10000    // Tier 3 price in tokens
  };

  const tierNames = {
    1: "Gold Memberfip",
    2: "Filver Memberfip",
    3: "Bronf Memberfip"
  };

  // Added optimizedTierImages to use dynamic images
  const tierImages = optimizedTierImages || {
    1: 'defaultTier1.png', // Fallback images
    2: 'defaultTier2.png',
    3: 'defaultTier3.png'
  };

  // Functions for Disclaimer and Checkboxes
  const openDisclaimer = () => {
    setIsDisclaimerOpen(true);
  };

  const closeDisclaimer = () => {
    setIsDisclaimerOpen(false);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const allChecked = Object.values(checkboxes).every(Boolean);

  // Existing useEffect Hooks
  useEffect(() => {
    if (contracts.memeNFTContract && contractsReady) {
      fetchPresaleEndedStatus();
      checkClaimableRewards();
    }
  }, [isAuthenticated, isCorrectChain, contractsReady, contracts.memeNFTContract, userAddress]);

  const fetchPresaleEndedStatus = async () => {
    setIsLoading(true);
    try {
      const presaleEndedStatus = await contracts.memeNFTContract.presaleEnded();
      setPresaleEnded(presaleEndedStatus);
    } catch (error) {
      console.error('Error fetching presaleEnded status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkClaimableRewards = async () => {
    if (contracts.memeNFTContract && userAddress) {
      try {
        const rewards = await contracts.memeNFTContract.getClaimableRewards(userAddress);
        setClaimableRewards(ethers.formatUnits(rewards, 18));
      } catch (error) {
        console.error('Error checking claimable rewards:', error);
      }
    }
  };

  useEffect(() => {
    calculateTotalCost();
  }, [tierCounts]);

  const calculateTotalCost = () => {
    let cost = 0;
    for (const tier in tierCounts) {
      const count = tierCounts[tier];
      const price = TIER_PRICES[tier];
      cost += price * count;
    }
    setTotalCost(cost);
  };

  const updateTierCount = (tier, operation) => {
    setTierCounts(prev => ({
      ...prev,
      [tier]: operation === 'increase' ? prev[tier] + 1 : Math.max(0, prev[tier] - 1)
    }));
  };

  const handleClosePreview = () => {
    if (!isProcessing && (transactionStatus === 'Confirmed' || transactionStatus === 'Failed')) {
      setClosing(true);
      setIsHovering(false);
      setTimeout(() => {
        setShowPreview(false);
        setClosing(false);
        setTransactionHash(null);
        setTransactionStatus(null);
      }, 500);
    } else {
      setClosing(true);
      setIsHovering(false);
      setTimeout(() => {
        setShowPreview(false);
        setClosing(false);
      }, 500);
    }
  };

  const handleConfirmPurchase = async () => {
    if (!allChecked) {
      alert('Please agree to all terms before confirming the purchase.');
      return;
    }

    setIsProcessing(true);
    setTransactionHash(null);
    setTransactionStatus(null);

    try {
      const presaleEndedStatus = await contracts.memeNFTContract.presaleEnded();
      if (!presaleEndedStatus) {
        throw new Error('Presale has not ended yet');
      }

      const totalCostBigInt = ethers.parseUnits(totalCost.toString(), 18);
      const userBalance = await contracts.coreERC20Contract.balanceOf(userAddress);

      if (userBalance < totalCostBigInt) {
        throw new Error('Insufficient token balance');
      }

      const allowance = await contracts.coreERC20Contract.allowance(userAddress, contracts.memeNFTContract.target);
      if (allowance < totalCostBigInt) {
        const approveTx = await contracts.coreERC20Contract.connect(signer).approve(contracts.memeNFTContract.target, totalCostBigInt);
        await approveTx.wait();
      }

      const tiers = Object.keys(tierCounts).filter(tier => tierCounts[tier] > 0);
      const quantities = tiers.map(tier => tierCounts[tier]);

      const transaction = await contracts.memeNFTContract.mintNFTs(tiers, quantities, referrerName);
      setTransactionHash(transaction.hash);
      setTransactionStatus('Pending');

      const receipt = await transaction.wait();
      if (receipt.status === 1) {
        setTransactionStatus('Confirmed');
        await refreshData();
        setHasConfirmedPurchase(true);
      } else {
        setTransactionStatus('Failed: Transaction reverted');
      }
    } catch (error) {
      console.error('Error minting NFTs:', error);
      setTransactionStatus(`Failed: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  // Updated image logic to use tierCounts similar to BuyTokens
  useEffect(() => {
    let imageName = 'fer.png';
    if (tierCounts[1] > 0) {
      imageName = 'fer_lafer_eyef.png';
    } else if (tierCounts[2] > 0) {
      imageName = 'fer_big_fmile.png';
    } else if (tierCounts[3] > 0) {
      imageName = 'fer_fmile.png';
    } else {
      imageName = 'fer.png';
    }
    setImage(imageName);
  }, [tierCounts, setImage]);

  useEffect(() => {
    setIsImageViewerOpen(viewerOpen);
  }, [viewerOpen, setIsImageViewerOpen]);

  let messageLine1 = 'Looking for $FER?';
  let messageLine2 = 'Go to Unifwap!';

  // Converted amount based on totalCost similar to BuyTokens' amount logic
  let convertedAmount = totalCost / 1000000;

  if (viewerOpen) {
    if (currentImageIndex === 0) {
      messageLine1 = "Gold Memberfip, Fer!";
      messageLine2 = "Very Effective!";
    } else if (currentImageIndex === 1) {
      messageLine1 = "Filver Memberfip, Fer!";
      messageLine2 = "Fine!";
    } else if (currentImageIndex === 2) {
      messageLine1 = "Bronf Memberfip, Fer!";
      messageLine2 = "Fleefy!";
    }
  } else if (convertedAmount === 9.99) {
    messageLine1 = 'FULLY';
    messageLine2 = 'LOCKED IN, FER?';
  } else if (convertedAmount === 6.66) {
    messageLine1 = 'Careful, Fer...';
    messageLine2 = '';
  } else if (convertedAmount === 4.20) {
    messageLine1 = 'Will you take the hit, Fer?';
    messageLine2 = '';
  } else if (convertedAmount === 4.04) {
    messageLine1 = '<not found>';
    messageLine2 = '';
  } else if (convertedAmount === 3.14) {
    messageLine1 = 'Pie, Fer?';
    messageLine2 = '';
  } else if (convertedAmount === 1.23) {
    messageLine1 = 'A, B, Fee';
    messageLine2 = '';
  } else if (convertedAmount === 1.11) {
    messageLine1 = 'Gonna caf em all, Fer?';
    messageLine2 = '';
  } else if (convertedAmount === 0.99) {
    messageLine1 = 'Interefting Ftrategy, Fer!';
    messageLine2 = '';
  } else if (convertedAmount === 0.90) {
    messageLine1 = 'Full Silver, Fer? Excellent!';
    messageLine2 = '';
  } else if (convertedAmount === 0.42) {
    messageLine1 = 'The Anfer,';
    messageLine2 = 'Fer';
  } else if (convertedAmount === 0.09) {
    messageLine1 = 'Full Bronf, Fer? Nice!';
    messageLine2 = '';
  } else if (convertedAmount >= 1) {
    messageLine1 = 'Are you worfy, Fer?';
    messageLine2 = '';
  } else if (convertedAmount >= 0.1) {
    messageLine1 = 'Ekfellent, Fer!';
    messageLine2 = '';
  } else if (convertedAmount >= 0.01) {
    messageLine1 = 'Nife, Fer';
    messageLine2 = '';
  } else if (transactionStatus === 'Confirmed') {
    messageLine1 = 'Welcome, Fer!';
    messageLine2 = '';
  } else if (totalCost === TIER_PRICES[1] * 9) {
    messageLine1 = 'All in, Fer?';
    messageLine2 = '';
  } else if (totalCost === TIER_PRICES[2] * 9) {
    messageLine1 = 'Full Filver, Fer?';
    messageLine2 = 'Nife!';
  } else if (totalCost === TIER_PRICES[3] * 9) {
    messageLine1 = 'Full Bronfe, Fer?';
    messageLine2 = 'Nice!';
  } else if (tierCounts[1] > 0) {
    messageLine1 = 'Are you worfy, Fer?';
    messageLine2 = '';
  } else if (tierCounts[2] > 0) {
    messageLine1 = 'Ekfellent, Fer!';
    messageLine2 = '';
  } else if (tierCounts[3] > 0) {
    messageLine1 = 'Nife, Fer';
    messageLine2 = '';
  } else {
    messageLine1 = 'Need $FER?';
    messageLine2 = 'Go to a Dekf!';
  }

  if (isLoading) {
    messageLine1 = 'Connecting to fmart contract...';
    messageLine2 = 'Please wait, Fer...';
  } else if (!isLoading && !imagesLoaded) {
    messageLine1 = '...one fecond pleafe,';
    messageLine2 = 'Fer...';
  }

  // Speech bubble animation similar to BuyTokens
  const [renderSpeechBubble, setRenderSpeechBubble] = useState(false);

  useEffect(() => {
    setRenderSpeechBubble(true);
    const timer = setTimeout(() => {
      setRenderSpeechBubble(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [messageLine1, messageLine2]);

  // Handle body overflow when disclaimer is open
  useEffect(() => {
    if (isDisclaimerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isDisclaimerOpen]);

  // Handle Escape key for closing disclaimer
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        closeDisclaimer();
      }
    };
    if (isDisclaimerOpen) {
      window.addEventListener('keydown', handleEsc);
    }
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isDisclaimerOpen]);

  const handleTouchMove = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {/* Legal Disclaimer Modal */}
      {isDisclaimerOpen && (
        <>
          <div
            className={`blurer ${closing ? 'close' : 'open'} ${
              tierCounts[1] > 0
                ? 'gold'
                : tierCounts[2] > 0
                ? 'silver'
                : tierCounts[3] > 0
                ? 'bronze'
                : ''
            }`}
          />
          <div
            className="preview-window disclaimer"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            onTouchMove={handleTouchMove}
            aria-labelledby="disclaimer-title"
          >
            <button
              className="close-preview-button"
              onClick={closeDisclaimer}
              aria-label="Close Disclaimer"
            >
              &times;
            </button>
            <div className="disclaimer-content" id="disclaimer-title">
              <LegalDisclaimer />
            </div>
          </div>
        </>
      )}

      {/* Preview Modal */}
      {showPreview && !isDisclaimerOpen && (
  <>
    {/* Blurer Overlay */}
    <div
      className={`blurer ${closing ? 'close' : 'open'} ${
        tierCounts[1] > 0
          ? 'gold'
          : tierCounts[2] > 0
          ? 'silver'
          : tierCounts[3] > 0
          ? 'bronze'
          : 'null'
      }`}
    />

    {/* Preview Window */}
    <div className={`preview-window ${closing ? 'close' : 'open'}`}>
      {/* Preview Content */}
      <div
        className={`preview-content ${
          tierCounts[1] > 0
            ? 'gold'
            : tierCounts[2] > 0
            ? 'silver'
            : tierCounts[3] > 0
            ? 'bronze'
            : 'null'
        }`}
      >
        {/* Header */}
        <h2
          className={`header smol preview ${
            tierCounts[1] > 0
              ? 'gold'
              : tierCounts[2] > 0
              ? 'silver'
              : tierCounts[3] > 0
              ? 'bronze'
              : 'null'
          }`}
        >
          Preview
        </h2>

        {/* Tier Holder */}
        <div className='tierholder'>
          {Object.entries(tierCounts).map(([tier, count]) => (
            count > 0 && (
              <div key={tier} className="tier-preview">
                <h3 className="textmedium">{tierNames[tier]}: x{count}</h3>
                <div className="nft-grid">
                  {[...Array(count)].map((_, index) => (
                    <NFTPreviewImage
                      key={`${tier}-${index}`}
                      src={optimizedTierImages[tier]}
                      alt={`Tier ${tier}`}
                    />
                  ))}
                </div>
              </div>
            )
          ))}
        </div>

        {/* Token Summary */}
        <div className="token-summary">
          <p className="textmedium">
            <span
              className={`titletext ${
                tierCounts[1] > 0
                  ? 'gold'
                  : tierCounts[2] > 0
                  ? 'silver'
                  : tierCounts[3] > 0
                  ? 'bronze'
                  : 'null'
              }text`}
            >
              +{formatAmount(totalCost)}
              <img
                src={ferCoinLogo}
                alt="FER Logo"
                style={{
                  height: '0.8em',
                  filter: 'drop-shadow(0.05vw 0.05vw 2px black)',
                  verticalAlign: 'middle',
                  position: 'relative',
                  left: '4px',
                  bottom: '0.1em',
                }}
              />
              <span className='textmedium' style={{ marginLeft: '1%', color: '#fff' }}>
                $FER
              </span>
            </span>
          </p>

          <p className="textmediumsmall">50% received now: {formatAmount(totalCost * 0.5)} $FER</p>
          <p className="textmediumsmall">50% vested: {formatAmount(totalCost * 0.5)} $FER</p>

          {transactionStatus && (
            <p className={`textmedium ${transactionStatus.toLowerCase()}`}>
              Transaction {transactionStatus}
              {transactionHash && (
                <a
                  href={`https://etherscan.io/tx/${transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Etherscan
                </a>
              )}
            </p>
          )}
        </div>
      </div>

      {/* Checkboxes Section */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '20px',
          padding: '10px',
          
        
     
          backgroundColor: 'rgb(28 10 0 / 42%)',
          alignItems: 'flex-start'
        }}
         className=' textmediumsmall'
      >
              <span
            onClick={openDisclaimer}
            style={{
            
            
              cursor: 'pointer',
             
              width: 'auto',
              alignSelf: 'center'
            }}
          >
            <u>Read full Difclaimer here</u>
          </span>

        {/* Checkbox 1 */}
        <label className='checkbox-label textmediumsmall' >
        <input
          type="checkbox"
          name="checkbox1"
          checked={checkboxes.checkbox1}
          onChange={handleCheckboxChange}
          className={!checkboxes.checkbox1 ? 'checkbox-red' : ''}
          style={{ marginRight: '10px', cursor: 'pointer' }}
        />
          Memberfip NFTf are "foulbound" and I am NOT ALLOWED to fell, fend or diftribute them.
        </label>

        {/* Checkbox 2 */}
        <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
            className={!checkboxes.checkbox2 ? 'checkbox-red' : ''}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          Af a member of Order of Fer, I will act end behave like a FER both within the community and to otherf.
        </label>

        {/* Checkbox 3 with Disclaimer Link */}
        <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            className={!checkboxes.checkbox3 ? 'checkbox-red' : ''}
            onChange={handleCheckboxChange}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          $FER if a non-fecurity digital token that if diftributed for free when buying Order of Fer Memberfipf, af well af other automatic diftribufion mechanicf within the ecofyftem.
          
        </label>
  
        {/* Checkbox 4 */}
        <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox4"
            checked={checkboxes.checkbox4}
            onChange={handleCheckboxChange}
            className={!checkboxes.checkbox4 ? 'checkbox-red' : ''}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          50% of my free $FER will be vefted for 2 months + 20% of the elapfed prefale time.
        </label>

        {/* Checkbox 5 */}
        <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox5"
            checked={checkboxes.checkbox5}
            className={!checkboxes.checkbox5 ? 'checkbox-red' : ''}
            onChange={handleCheckboxChange}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
          Order of Fer fyftem if built on fmart contractf that are completely defentralifed, and carry the rifk of not being fikfable. If there if a problem, the problem if likely forever.
        </label>

          {/* Checkbox 6 */}
                <label className='checkbox-label textmediumsmall' >
          <input
            type="checkbox"
            name="checkbox6"
            checked={checkboxes.checkbox6}
            className={!checkboxes.checkbox6 ? 'checkbox-red' : ''}
            onChange={handleCheckboxChange}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
      I have read the Difclaimer
      </label>
      </div>

      {/* Referrer Section */}
      {nftData.length === 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            marginBottom: '1%',
            borderRadius: '0.4vw',
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            backgroundColor: 'rgba(28, 10, 0, 0.42)',
          }}
        >
          <label
            className='textmedium'
            htmlFor="referrerName"
            style={{
              marginRight: '10px',
              fontWeight: 'bold',
              fontSize: '16px',
              width: '100px',
            }}
          >
            Referred?
          </label>
          <span
            className='textmedium'
            style={{
              marginRight: '5px',
            }}
          >
            FER
          </span>
          <input
            id="referrerName"
            type="text"
            value={referrerName}
            onChange={(e) => setReferrerName(applyFerSpeakForg(e.target.value.toUpperCase()))}
            placeholder="ENTER FERFONA"
            className='textmedium goldtext'
            style={{
              flex: '1',
              padding: '8px 12px',
              backgroundColor: 'rgba(28, 10, 0, 0.42)',
              borderRadius: '0.15vw',
              textTransform: 'uppercase',
              outline: 'none',
              border: 'none',
              maxWidth: '48%',
            }}
          />
        </div>
      )}

      {/* Preview Buttons */}
      <div className="preview-buttons">
        {!isProcessing && (transactionStatus === 'Confirmed' || transactionStatus === 'Failed') ? (
          <button className="butn close" onClick={handleClosePreview}>
            Close
          </button>
        ) : (
          <>
            <button
              className="butn close"
              onClick={handleClosePreview}
              disabled={isProcessing}
            >
              Close
            </button>
            <button
              className="butn confirm"
              onClick={handleConfirmPurchase}
              disabled={
                isProcessing ||
                transactionStatus === 'Confirmed' ||
                transactionStatus === 'Failed' ||
                !allChecked
                // Removed ethBalance check
              }
            >
              {isProcessing
                ? 'Processing...'
                : `Pay ${formatAmount(totalCost)} $FER`}
            </button>
          </>
        )}
      </div>
    </div>
  </>
)}


      {/* Image Viewer */}
      {viewerOpen ? (
        <ImageViewer
          images={Object.values(tierImages)} // Using dynamic tierImages from context
          initialIndex={currentImageIndex}
          onClose={() => setViewerOpen(false)}
        />
      ) : (
        <>
          <CurtainComponent startAnimation={(!isLoading && imagesLoaded && contractsReady)} Standby={false} />

          <div className={`prefalefer ${renderSpeechBubble ? 'bounce' : ''}`}>
            {messageLine1} <br /> {messageLine2}
          </div>

          <div className='row'>
            {/* Add any additional rows if needed */}
          </div>
          <div className='row'>
            <div className='col-sm signin'>
              <div className='prefale'>
                {isLoading || !imagesLoaded ? (
                  <p></p>
                ) : presaleEnded ? (
                  <>
                    <div className='input-unit-wrapper'>
                      <div className='total' style={{
                        backgroundColor:
                          tierCounts[1] > 0 ? 'rgba(255, 221, 0, 0.65)' :
                            tierCounts[2] > 0 ? 'rgba(227, 227, 227, 0.65)' :
                              tierCounts[3] > 0 ? 'rgba(114, 53, 10, 0.65)' :
                                'rgba(94, 94, 94, 0.65)'
                      }}>
                        <div className='nftpreview'>
                          {Object.keys(tierCounts).map(tier => (
                            <span key={tier} className='nfttier'>
                              <img
                                src={tierImages[tier]} // Using dynamic tierImages
                                alt={`Tier ${tier}`}
                                className={`previewimg ${tierCounts[tier] === 0 ? 'disabled' : ''}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentImageIndex(parseInt(tier) - 1);
                                  setViewerOpen(true);
                                }}
                              />
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <button className='butn mini minus'
                                  onClick={() => updateTierCount(parseInt(tier), 'decrease')}
                                  disabled={tierCounts[tier] === 0}
                                >
                                  -
                                </button>
                                <span className={`totalnft textmega ${tierCounts[tier] === 0 ? 'disabled' : ''}`}>{tierCounts[tier]}</span>
                                <button className='butn mini plus'
                                  onClick={() => updateTierCount(parseInt(tier), 'increase')}
                                  disabled={tierCounts[tier] === 9}
                                >
                                  +
                                </button>
                              </div>
                            </span>
                          ))}
                        </div>
                        <div className={`header mini ${totalCost === 0 ? 'disabled' : ''} ${tierCounts[1] > 0 ? 'gold' : tierCounts[2] > 0 ? 'silver' : tierCounts[3] > 0 ? 'bronze' : 'null'}`}>
                          <span className='textlarge'>{formatAmount(totalCost)}</span>
                          <img src={ferCoinLogo} style={{ height: '0.8em', filter: 'drop-shadow(0.05vw 0.05vw 2px black)', verticalAlign: 'middle', position: 'absolute', marginLeft: '1%', bottom: '10%' }} />
                        </div>
                      </div>
                    </div>
                    <div className='input-unit-wrapper'>
                      <button
                        type='button'
                        className={`butn buyfer ${isProcessing || totalCost === 0 || showPreview ? 'disabled' : ''}`}
                        onClick={() => setShowPreview(true)}
                        onMouseEnter={() => !showPreview && setIsHovering(true)}
                        onMouseLeave={() => !showPreview && setIsHovering(false)}
                        disabled={isProcessing || totalCost === 0}
                      >
                        <span className='textmedium'>
                          {isHovering && !showPreview ? 'Preview' : showPreview ? 'Previewing...' : `Total ${formatAmount(totalCost)} $FER`}
                        </span>
                      </button>
                    </div>
                  </>
                ) : (
                  <p>Could not connect to Smart Contract, please refresh and try again, Fer.</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PostPresaleDashboard;
