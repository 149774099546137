// frameUtils.js

let storedPlaqueText = '';
let storedLogoImg = null; // Global variable to store the logo image

/**
 * Sets the plaque text.
 * @param {string} text 
 */
export function setPlaqueText(text) {
    storedPlaqueText = text;
}

/**
 * Retrieves the plaque text.
 * @returns {string}
 */
export function getPlaqueText() {
    return storedPlaqueText;
}

/**
 * Sets the logo image to be used in addFrameAndText.
 * @param {HTMLImageElement} img 
 */
export function setLogoImage(img) {
    storedLogoImg = img;
}

/**
 * Draws a rounded rectangle on the canvas.
 *
 * @param {CanvasRenderingContext2D} ctx 
 * @param {number} x 
 * @param {number} y 
 * @param {number} width 
 * @param {number} height 
 * @param {number} radius 
 */
export function drawRoundedRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
}

/**
 * Adds a stylized black frame with decorative inner lines, text, and logos to the canvas.
 *
 * @param {CanvasRenderingContext2D} ctx 
 * @param {number} width 
 * @param {number} height 
 * @param {number} frameThickness 
 * @param {string} userTier 
 * @param {string} userMainName 
 * @param {string} plaqueTextParam 
 * @param {number} overlap 
 * @param {HTMLImageElement} logoImg 
 */
export function addFrameAndText(
    ctx,
    width,
    height,
    frameThickness,
    userTier,
    userMainName,
    plaqueTextParam,
    overlap = 0,
    logoImg = null // Optional logo image
){
    const plaqueText = plaqueTextParam !== undefined ? plaqueTextParam : getPlaqueText();

    // Draw the outer frame using solid black
    ctx.fillStyle = '#000000'; // Solid Black
    ctx.fillRect(0, 0, width, frameThickness); // Top Frame
    ctx.fillRect(0, height - frameThickness, width, frameThickness); // Bottom Frame
    ctx.fillRect(0, frameThickness, frameThickness, height - 2 * frameThickness); // Left Frame
    ctx.fillRect(width - frameThickness, frameThickness, frameThickness, height - 2 * frameThickness); // Right Frame

    // ------------------------------
    // Top Text: "ORDER OF FER"
    // ------------------------------

    // Set text properties for top text
    const topFontSize = Math.floor(frameThickness * 0.8); // Original font size
    ctx.font = `bold ${topFontSize}px "Segoe UI", sans-serif`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Define colors
    const topTextColor = 'white';
    const topTextShadowColor = 'rgba(0, 0, 0, 0.7)'; // Semi-transparent shadow for better contrast

    // Add text shadow to enhance readability
    ctx.shadowColor = topTextShadowColor;
    ctx.shadowBlur = Math.floor(frameThickness * 0.1);
    ctx.shadowOffsetX = Math.floor(frameThickness * 0.05);
    ctx.shadowOffsetY = Math.floor(frameThickness * 0.05);

    // Top Text: "ORDER OF FER"
    const topText = 'ORDER OF FER';
    const topTextX = width / 2;
    const topTextY = (frameThickness / 2) * 1.1;

    // Draw top text directly on the frame
    ctx.fillStyle = topTextColor;
    ctx.fillText(topText, topTextX, topTextY);

    // ------------------------------
    // Bottom Texts: Dynamic Text and "FER.MEME"
    // ------------------------------

    // Set text properties for bottom texts
    const bottomFontSize = Math.floor(frameThickness * 0.4); // Half of the original font size
    ctx.font = `bold ${bottomFontSize}px "Segoe UI", sans-serif`;
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';

    // Define colors
    const bottomTextDefaultColor = 'white';
    const bottomTextShadowColor = 'rgba(0, 0, 0, 0.7)'; // Same shadow for consistency

    // Add text shadow to enhance readability
    ctx.shadowColor = bottomTextShadowColor;
    ctx.shadowBlur = Math.floor(frameThickness * 0.05);
    ctx.shadowOffsetX = Math.floor(frameThickness * 0.025);
    ctx.shadowOffsetY = Math.floor(frameThickness * 0.025);

    // Define a color map for user tiers
    const tierColors = {
        GOLD: '#FFD700',   // Gold
        BRONF: 'rgba(114, 53, 10)', // Bronze
        FILVER: '#C0C0C0', // Silver
        // Add more tiers and their corresponding colors if needed
    };

    // Normalize the userTier string to uppercase to ensure case-insensitive matching
    const normalizedTier = userTier ? userTier.trim().toUpperCase() : '';

    // Function to determine if the tier is valid (i.e., not "NOT A MEMBER")
    const hasValidTier = normalizedTier && normalizedTier !== 'NOT A';

    // Get the color based on userTier, default to white if tier not recognized or invalid
    const dynamicTextColor = hasValidTier ? (tierColors[normalizedTier] || bottomTextDefaultColor) : bottomTextDefaultColor;

    // Construct the dynamic bottom text based on tier and main name
    let bottomTextDynamic = '';
    if (hasValidTier) {
        if (userMainName && userMainName.trim() !== '') {
            bottomTextDynamic = `CRAFTED BY ${normalizedTier.charAt(0) + normalizedTier.slice(1).toUpperCase()} MEMBER FER ${userMainName}`;
        } else {
            bottomTextDynamic = `CRAFTED BY ANONYMOUF ${normalizedTier.charAt(0) + normalizedTier.slice(1).toUpperCase()} MEMBER`;
        }
    } else {
        if (userMainName && userMainName.trim() !== '') {
            bottomTextDynamic = `CRAFTED BY FER ${userMainName}`;
        } else {
            bottomTextDynamic = `ARTIFT IF NOT YET A FER. BECOME A FER AT:`;
        }
    }

    const bottomTextStatic = 'FER.MEME';

    // Calculate positions with left margin equal to frameThickness
    const bottomMargin = frameThickness; // Left margin
    const bottomTextXLeft = bottomMargin * 2.2;
    const bottomTextYDynamic = height - frameThickness * 0.65; // Slightly above the exact bottom
    const bottomTextYStatic = height - frameThickness * 0.25; // Slightly below the exact bottom

    // Draw dynamic bottom text with tier-specific color or default color
    ctx.fillStyle = dynamicTextColor;
    ctx.fillText(bottomTextDynamic, bottomTextXLeft, bottomTextYDynamic);

    // Draw static bottom text with default color
    ctx.fillStyle = bottomTextDefaultColor;
    ctx.fillText(bottomTextStatic, bottomTextXLeft, bottomTextYStatic);

    // ------------------------------
    // Plaque Text with Black Background
    // ------------------------------

    if (plaqueText) {
        ctx.save();

        const plaqueFontSize = Math.floor(frameThickness * 0.8);
        ctx.font = `bold ${plaqueFontSize}px "Segoe UI", sans-serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        const textMetrics = ctx.measureText(plaqueText);
        const textWidth = textMetrics.width;
        const textHeight = plaqueFontSize;
        const padding = Math.floor(frameThickness * 0.3);

        // Updated Y position without scaling factor
        const plaqueTextYPos = height - (frameThickness * 0.8) - overlap - (textHeight / 2) - padding;
        const plaqueTextX = width / 2;

        const rectX = plaqueTextX - textWidth / 2 - padding;
        const rectY = plaqueTextYPos - textHeight / 2 - padding;
        const rectWidth = textWidth + 2 * padding;
        const rectHeight = textHeight + 1.8 * padding;

        ctx.fillStyle = 'black';
        ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

        ctx.shadowColor = 'rgba(0, 0, 0, 0.7)';
        ctx.shadowBlur = Math.floor(frameThickness * 0.05);
        ctx.shadowOffsetX = Math.floor(frameThickness * 0.025);
        ctx.shadowOffsetY = Math.floor(frameThickness * 0.025);

        ctx.fillStyle = 'white';
        ctx.fillText(plaqueText, plaqueTextX, plaqueTextYPos);

        ctx.restore();
    }

    // ------------------------------
    // Render the Logo in the Four Corners
    // ------------------------------

    if (logoImg || storedLogoImg) { // Ensure logo exists
        const logo = logoImg || storedLogoImg;
        if (logo) {
            // Calculate the logo size (80% of frame thickness)
            const cornerLogoSize = frameThickness * 1;

            // Define positions for the four corners
            const cornerPositions = [
                { x: frameThickness / 0.9, y: frameThickness / 0.9 }, // Top-left
                { x: width - frameThickness / 0.9, y: frameThickness / 0.9 }, // Top-right
                { x: frameThickness / 0.9, y: height - frameThickness / 0.9 }, // Bottom-left
                { x: width - frameThickness / 0.9, y: height - frameThickness / 0.9 }, // Bottom-right
            ];

            // Draw the logo in each corner
            cornerPositions.forEach(pos => {
                ctx.drawImage(
                    logo,
                    pos.x - cornerLogoSize / 2, // x-coordinate
                    pos.y - cornerLogoSize / 2, // y-coordinate
                    cornerLogoSize,
                    cornerLogoSize
                );
            });
        }
    }

    // Reset shadow settings
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;

    // Reset text alignment and baseline if needed for future drawings
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
}
