import React, { useMemo, useRef, useState, useEffect } from 'react';
import tier1 from '../../goldmember.png'
import tier2 from '../../filvermember.png';
import tier3 from '../../bronfmember.png';
import tier4 from '../../nomember.png';

const ImageLibraryModal = ({ 
  isOpen, 
  onClose, 
  libraryData, 
  onSelectImage, 
  imageCache,
  setActiveLayer,
  setActiveButton
}) => {
  const categories = useMemo(() => libraryData?.categories || [], [libraryData]);
  const categoryRefs = useRef({});
  const modalContentRef = useRef(null);
  const [activeCategories, setActiveCategories] = useState(new Set());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (modalContentRef.current) {
        const newActiveCategories = new Set();
        const { top: containerTop, bottom: containerBottom, height: containerHeight } = modalContentRef.current.getBoundingClientRect();
        const visibilityThreshold = containerHeight * 0.15;

        categories.forEach(category => {
          const element = categoryRefs.current[category.categoryImage];
          if (element) {
            const { top, bottom } = element.getBoundingClientRect();
            const visibleHeight = Math.min(containerBottom, bottom) - Math.max(containerTop, top);
            if (visibleHeight > visibilityThreshold && top < containerBottom && bottom > containerTop) {
              newActiveCategories.add(category.categoryImage);
            }
          }
        });

        setActiveCategories(newActiveCategories);
      }
    };

    const modalContent = modalContentRef.current;
    if (modalContent) {
      modalContent.addEventListener('scroll', handleScroll);
      // Initial check
      handleScroll();
    }

    return () => {
      if (modalContent) {
        modalContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [categories, isOpen]);

  useEffect(() => {
    if (isOpen) {
      // Force a re-render after the modal is opened
      const timer = setTimeout(() => {
        setActiveCategories(new Set(activeCategories));
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleImageSelect = (item) => {
    if (item.src !== item.grayedSrc) {
      onSelectImage(item.id);
      setActiveLayer('fabric');
      setActiveButton('select');
    }
  };

  const getTierBadge = (tier) => {
    switch (tier) {
      case 1: return tier1;
      case 2: return tier2;
      case 3: return tier3;
      case 4: return tier4;
      default: return null;
    }
  };

  const getTierName = (tier) => {
    switch (tier) {
      case 1: return 'gold';
      case 2: return 'filver';
      case 3: return 'bronf';
      case 3: return 'not-a'
      default: return '';
    }
  };

  const scrollToCategory = (categoryImage) => {
    if (modalContentRef.current && categoryRefs.current[categoryImage]) {
      const containerRect = modalContentRef.current.getBoundingClientRect();
      const elementRect = categoryRefs.current[categoryImage].getBoundingClientRect();
      modalContentRef.current.scrollTop += elementRect.top - containerRect.top - 20; // 20px offset for the header
    }
  };

  return (
    <div className="image-library-modal">
      {!isMobile && (
        <div className="category-navigation">
          {categories.map((category) => (
            <button
              key={category.categoryImage}
              className={`category butn ${activeCategories.has(category.categoryImage) ? 'active' : ''}`}
              onClick={() => scrollToCategory(category.categoryImage)}
            >
              {category.name}
            </button>
          ))}
          <button className="category butn red close" onClick={onClose}>Close</button>
        </div>
      )}
      <div className="image-modal-content" ref={modalContentRef}>
        <div className="categories-grid">
          {categories.map((category) => (
            <div 
              key={category.categoryImage} 
              className="category-box"
              ref={el => categoryRefs.current[category.categoryImage] = el}
            >
              <div className="category-header">
                <img 
                  src={process.env.PUBLIC_URL + '/' + category.categoryImage}
                  alt={category.name}
                  className='category-image'
                />
               
              </div>
              <div className="category-items">
                {category.items ? (
                  category.items.map((item) => (
                    <div 
                      key={item.id} 
                      className={`image-item ${getTierName(item.tier)} ${item.src === item.grayedSrc ? 'grayed-item' : ''}`}
                      onClick={() => handleImageSelect(item)}
                    >
                      {imageCache[item.id] ? (
                        <>
                          <img src={imageCache[item.id]} alt={item.name} className={`item-image ${item.src === item.grayedSrc ? 'grayed-image' : ''} `} />
                          <span className={`item-name textmedium ${getTierName(item.tier)}text `} >{item.name}</span>
                          <img 
                            src={getTierBadge(item.tier)} 
                            alt={`Tier ${item.tier}`} 
                            className="tier-badge"
                          />
                        </>
                      ) : (
                        <div className="textmedium">Loading...</div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="textmedium">No images available in this category</div>
                )}
              </div>
            </div>
          ))}
        </div>
        
      </div>
    </div>
  );
};

export default React.memo(ImageLibraryModal);