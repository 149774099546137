// LayerList.jsx
import React, { useState, useEffect } from 'react';

const LayerList = ({
  layers,
  onSelectLayer,
  selectedLayers,
  onToggleVisibility,
  onDeleteLayers,
  handleMergeLayers,
}) => {
  const [isHoveringMerge, setIsHoveringMerge] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [internalLayers, setInternalLayers] = useState(layers);

  // Update internal layers when props change
  useEffect(() => {
    setInternalLayers(layers);
  }, [layers]);

  const getLayerIcon = (layer) => {
    const { type, fabricObject } = layer;
    const category = fabricObject.category
      ? fabricObject.category.toLowerCase()
      : '';

    if (type === 'image') {
      if (category.includes('hat')) return '🎩';
      if (category.includes('teef')) return '🦷';
      if (category.includes('eyewear')) return '🕶';
      return '🖼️';
    }

    switch (type) {
      case 'i-text':
        return '🔤';
      case 'path':
        return '🖌️';
      default:
        return '📄';
    }
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.currentTarget.classList.add('dragging');
  };

  const handleDragEnter = (e, index) => {
    e.preventDefault();
    if (draggedIndex === null || draggedIndex === index) return;

    // Swap layers in internalLayers
    const newLayers = [...internalLayers];
    const [movedLayer] = newLayers.splice(draggedIndex, 1);
    newLayers.splice(index, 0, movedLayer);

    setDraggedIndex(index);
    setInternalLayers(newLayers);

    // Now update the canvas objects order
    const canvas = newLayers[0]?.fabricObject.canvas;
    if (!canvas) return;

    const totalObjects = newLayers.length;
    newLayers.forEach((layer, idx) => {
      const desiredIndex = totalObjects - 1 - idx;
      canvas.moveTo(layer.fabricObject, desiredIndex);
    });

    canvas.renderAll();
  };

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove('dragging');
    setDraggedIndex(null);
  };

  return (
    <>
      <div className={`layerholder ${isHoveringMerge ? 'active' : ''}`}>
        {internalLayers.map((layer, index) => (
          <div
            className={`layer ${
              selectedLayers.includes(layer.fabricObject) ? 'active' : ''
            } ${draggedIndex === index ? 'dragging' : ''}`}
            key={index}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragEnter={(e) => handleDragEnter(e, index)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={handleDragEnd}
            onClick={(e) =>
              onSelectLayer(
                layer.fabricObject,
                e.ctrlKey || e.metaKey || e.shiftKey
              )
            }
          >
            <span>{getLayerIcon(layer)}</span>
          </div>
        ))}
      </div>

      <button
        className='toolbarbutn merg'
        onClick={handleMergeLayers}
        disabled={internalLayers.length < 1}
        onMouseEnter={() => setIsHoveringMerge(true)}
        onMouseLeave={() => setIsHoveringMerge(false)}
      >
        <span className='toolbarbutn-text merg'>Merg</span>
      </button>
    </>
  );
};

export default LayerList;
