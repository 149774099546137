// Content.jsx
import React, { useState, useEffect, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom';

import TogglePresale from './TogglePresale';
import BuyTokens from './BuyTokens';
import PostPresaleDashboard from './PostPresaleDashboard';
import BurnRemaining from './BurnRemaining';
import WithdrawETH from './WithdrawEth';
import TransferOwnership from './TransferOwnership';
import config from '../config';
import { useImage } from './ImageContext';
import Dashboard from './Dashboard';
import { WalletContext } from '../WalletContext';
import VotingComponent from './Voting/VotingComponent';
import NameComponent from './NameComponent';
import AdminVoting from './AdminVoting';
import SetPicaffoWinner from './AchievementManager';
import TokenomicsChart from './TokenomicsChart';
import ImageEditorContainer from './imageEditor/ImageEditorContainer';
import Navigation from './Navigation';
import Footer from './Footer';
import CurtainComponent from './CurtainComponent';

import ferWithSign from '../fer.png';
import ferWithSignSmile from '../fer_fmile.png';
import ferWithSignSmileBig from '../fer_big_fmile.png';
import ferWithSignLaserEyes from '../fer_lafer_eyef.png';
import ferWithSignLaserEyesFpliff from '../fer_lafer_eyef_fpliff.png';
import fign from '../fign.png';

const ContentWithRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { image } = useImage();
  const { userAddress, isAuthenticated, isConnected, contractsReady, contracts } = useContext(WalletContext);
  const isAdmin = userAddress === config.adminAddress;

  // State Management
  const [visibleSection, setVisibleSection] = useState('signIn');
  const [isScaled, setIsScaled] = useState(false);
  const [isExtraScaled, setIsExtraScaled] = useState(false);
  const [activeButton, setActiveButton] = useState('signIn');
  const [currentFignImage, setCurrentFignImage] = useState(fign);

  const [imageHolderClass, setImageHolderClass] = useState('fade-in');
  const [contentClass, setContentClass] = useState('fade-in');
  const [buttonHolderClass, setButtonHolderClass] = useState('fade-in');
  const [footerClass, setFooterClass] = useState('fade-in');
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [buyTokensKey, setBuyTokensKey] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [presaleActive, setPresaleActive] = useState(false);
  const [presaleEnded, setPresaleEnded] = useState(true);

  const [currentImage, setCurrentImage] = useState(image);

  const imageOrder = [
    'fer.png',
    'fer_fmile.png',
    'fer_big_fmile.png',
    'fer_lafer_eyef.png',
    'fer_lafer_eyef_fpliff.png'
  ];
  const [imageOpacities, setImageOpacities] = useState({
    'fer.png': 1,
    'fer_fmile.png': 0,
    'fer_big_fmile.png': 0,
    'fer_lafer_eyef.png': 0,
    'fer_lafer_eyef_fpliff.png': 0
  });

  const imageMap = {
    'fer.png': ferWithSign,
    'fer_fmile.png': ferWithSignSmile,
    'fer_big_fmile.png': ferWithSignSmileBig,
    'fer_lafer_eyef.png': ferWithSignLaserEyes,
    'fer_lafer_eyef_fpliff.png': ferWithSignLaserEyesFpliff
  };

  // Fetch Presale Data with Retry Logic
  const fetchPresaleData = async (retries = 3) => {
    setIsLoading(true);
    try {
      const configData = await contracts.presaleManagerContract.getPresaleStatus();
      setPresaleActive(configData[0]);
      setPresaleEnded(configData[1]);
      //console.log("Presale ended? " + presaleEnded)

    } catch (error) {
      console.error('Failed to fetch presale data:', error);
      if (retries > 0) {
  
        setTimeout(() => {
          fetchPresaleData(retries - 1);
        }, 1300);
      } else {
        console.error('Max retries reached, setting presaleActive to false');
        setPresaleActive(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Update visibleSection based on route
  useEffect(() => {
    const path = location.pathname.replace('/', '') || 'signIn';
    setVisibleSection(path);
    setActiveButton(path);
  }, [location]);

  // Update image opacities based on current image
  useEffect(() => {
    const currentIndex = imageOrder.indexOf(image);
    const newOpacities = { ...imageOpacities };
    imageOrder.forEach((img, index) => {
      newOpacities[img] = index <= currentIndex ? 1 : 0;
    });
    setImageOpacities(newOpacities);
  }, [image]);

  // Update scaling based on visible section and image viewer state
  useEffect(() => {
    setIsScaled(
      ['tokenomif', 'dafboard', 'fergiving'].includes(visibleSection) || isImageViewerOpen
    );
  }, [visibleSection, isImageViewerOpen]);

  // Fetch presale data on authentication and contracts readiness
  useEffect(() => {
    if (contracts && contractsReady) {
      fetchPresaleData();
      handleSectionChange('prefale', false);
    }

  }, [isAuthenticated, contracts, contractsReady]);

  // Handle Section Change with Navigation
  const handleSectionChange = (section, withAnimation = true) => {
    navigate(`/${section}`);

    if (section === 'prefale' && visibleSection === 'prefale' && isImageViewerOpen) {
      setBuyTokensKey(prevKey => prevKey + 1);
    }

    const isPrefaleOrTokenomif = ['prefale', 'tokenomif'].includes(section);
    const wasPrefaleOrTokenomif = ['prefale', 'tokenomif'].includes(visibleSection);

    if (isPrefaleOrTokenomif && !wasPrefaleOrTokenomif) {
      setImageHolderClass('fade-in');
      setFooterClass('fade-in');
    } else {
      setImageHolderClass('');
      setFooterClass('');
    }

    if (isPrefaleOrTokenomif || section === 'signIn') {
      setVisibleSection(section);
      setActiveButton(section);
      setIsScaled(section === 'tokenomif');
      setButtonHolderClass(section === 'tokenomif' ? 'fade-in-tokenomif' : 'fade-in');
      setContentClass('fade-in');
      setFooterClass('fade-in');
    } else {
      if (withAnimation) {
        setImageHolderClass('fade-out');
        setButtonHolderClass('fade-out');
        setContentClass('fade-out');
        setFooterClass('fade-out');
        setTimeout(() => {
          setImageHolderClass('');
          setButtonHolderClass('hidden');
          setContentClass('hidden');
          setFooterClass('hidden');
          setVisibleSection(section);
          setActiveButton(section);
          setIsScaled(['dafboard', 'tokenomif', 'fergiving'].includes(section));
          setContentClass('fade-in');
        }, 300);
      } else {
        setVisibleSection(section);
        setActiveButton(section);
        setIsScaled(['dafboard', 'tokenomif', 'fergiving'].includes(section));
        setContentClass('fade-in');
        setButtonHolderClass('fade-in');
        setImageHolderClass('');
        setFooterClass('hidden');
      }
    }
  };

  return (
    <>
      <Navigation
        activeButton={activeButton}
        handleSectionChange={handleSectionChange}
        isMobileMenu={false}
      />

      <Routes>
        {/* Fer Giving Route */}
        <Route
          path="/fergiving"
          element={
            <>
              <div className="fullsize fergiving">
                <div className="col-lg-2 col-md-2 col-sm-3"></div>
                <VotingComponent />
              </div>
              <Footer backgroundColor="#01153b" />
            </>
          }
        />

        {/* Ferfona Route */}
        <Route
          path="/ferfona"
          element={
            <>
              <div className="fullsize ferfona">
                <div className="bannerspacer"></div>
                <div className="fullsize">
                  <div className="col-lg-2 col-md-2 col-sm-3"></div>
                  <NameComponent />
                </div>
              </div>
              <Footer backgroundColor="#06052f" />
            </>
          }
        />

        {/* MorFER Route */}
        <Route
          path="/MorFER"
          element={
            <>
              <div className="fullsize darkgray">
                <ImageEditorContainer />
              </div>
              <Footer backgroundColor="#222" />
            </>
          }
        />

        {/* Prefale, Tokenomif, SignIn Routes */}
        <Route
          path="/prefale"
          element={
            <PrefaleTokenomifSignIn
              visibleSection={visibleSection}
              isAuthenticated={isAuthenticated}
              isConnected={isConnected}
              contractsReady={contractsReady}
              presaleActive={presaleActive}
              presaleEnded={presaleEnded}
              isLoading={isLoading}
              buyTokensKey={buyTokensKey}
              setIsImageViewerOpen={setIsImageViewerOpen}
              isImageViewerOpen={isImageViewerOpen}
              imageHolderClass={imageHolderClass}
              buttonHolderClass={buttonHolderClass}
              imageOpacities={imageOpacities}
              imageMap={imageMap}
              isScaled={isScaled}
              isExtraScaled={isExtraScaled}
              currentFignImage={currentFignImage}
              handleSectionChange={handleSectionChange}
            />
          }
        />

        <Route
          path="/tokenomif"
          element={
            <PrefaleTokenomifSignIn
              visibleSection={visibleSection}
              isAuthenticated={isAuthenticated}
              isConnected={isConnected}
              contractsReady={contractsReady}
              presaleActive={presaleActive}
              presaleEnded={presaleEnded}
              isLoading={isLoading}
              buyTokensKey={buyTokensKey}
              setIsImageViewerOpen={setIsImageViewerOpen}
              imageHolderClass={imageHolderClass}
              buttonHolderClass={buttonHolderClass}
              imageOpacities={imageOpacities}
              imageMap={imageMap}
              isScaled={isScaled}
              isExtraScaled={isExtraScaled}
              currentFignImage={currentFignImage}
              handleSectionChange={handleSectionChange}
            />
          }
        />

        <Route
          path="/signIn"
          element={
            <PrefaleTokenomifSignIn
              visibleSection={visibleSection}
              isAuthenticated={isAuthenticated}
              isConnected={isConnected}
              contractsReady={contractsReady}
              presaleActive={presaleActive}
              presaleEnded={presaleEnded}
              isLoading={isLoading}
              buyTokensKey={buyTokensKey}
              setIsImageViewerOpen={setIsImageViewerOpen}
              imageHolderClass={imageHolderClass}
              buttonHolderClass={buttonHolderClass}
              imageOpacities={imageOpacities}
              imageMap={imageMap}
              isScaled={isScaled}
              isExtraScaled={isExtraScaled}
              currentFignImage={currentFignImage}
              handleSectionChange={handleSectionChange}
            />
          }
        />

        {/* Admin Route */}
        <Route
          path="/admin"
          element={
            <div className={`other-sections ${contentClass}`}>
              <TransferOwnership />
              <TogglePresale />
              <BurnRemaining />
              <WithdrawETH />
              <SetPicaffoWinner />
            </div>
          }
        />

        {/* Admin Voting Route */}
        <Route
          path="/adminVoting"
          element={
            <div className={`other-sections ${contentClass}`}>
              <AdminVoting />
            </div>
          }
        />

        {/* Dashboard Route */}
        <Route
          path="/dafboard"
          element={
            <div className={`other-sections ${contentClass}`}>
              <Dashboard />
            </div>
          }
        />

        {/* Default Route - Redirect to SignIn */}
        <Route
          path="*"
          element={
            <PrefaleTokenomifSignIn
              visibleSection={visibleSection}
              isAuthenticated={isAuthenticated}
              isConnected={isConnected}
              contractsReady={contractsReady}
              presaleActive={presaleActive}
              presaleEnded={presaleEnded}
              isLoading={isLoading}
              buyTokensKey={buyTokensKey}
              setIsImageViewerOpen={setIsImageViewerOpen}
              imageHolderClass={imageHolderClass}
              buttonHolderClass={buttonHolderClass}
              imageOpacities={imageOpacities}
              imageMap={imageMap}
              isScaled={isScaled}
              isExtraScaled={isExtraScaled}
              currentFignImage={currentFignImage}
              handleSectionChange={handleSectionChange}
            />
          }
        />
      </Routes>
    </>
  );
};

// Prefale, Tokenomif, SignIn Component
const PrefaleTokenomifSignIn = ({
  visibleSection,
  isAuthenticated,
  isConnected,
  contractsReady,
  presaleActive,
  presaleEnded,
  isLoading,
  buyTokensKey,
  setIsImageViewerOpen,
  isImageViewerOpen,
  imageHolderClass,
  buttonHolderClass,
  imageOpacities,
  imageMap,
  isScaled,
  isExtraScaled,
  currentFignImage,
  handleSectionChange
}) => {
  const { image } = useImage();
  const imageOrder = [
    'fer.png',
    'fer_fmile.png',
    'fer_big_fmile.png',
    'fer_lafer_eyef.png',
    'fer_lafer_eyef_fpliff.png'
  ];

  return (
    <div className="containers">
      <div className="row justify-content-center">
        {/* Left Column */}
        <div className="col-lg-1 col-md-1 col-sm-2">
          <div className="emptynavigafon"></div>
          <div className="titlestatic">
            <span className="titletext small prefaletext">
              PREFALE
              <div style={{ opacity: '0.3' }}>
                {[...Array(13)].map((_, i) => (
                  <span key={i} className="prefalebannertext">
                    PREFALE
                  </span>
                ))}
              </div>
            </span>
            {/* Stars */}
            {[...Array(30)].map((_, i) => (
              <div key={i} className="star"></div>
            ))}
          </div>
        </div>

        {/* Right Column */}
        <div className="col-lg-6 col-md-6 col-sm-8">
          <div className="content">
            <div className={`imgHolder ${imageHolderClass}`}>
              {/* Fer Image Wrapper */}
              <div className="ferImgWrapper">
                {imageOrder.map((img, index) => (
                  <img
                    key={img}
                    draggable="false"
                    className={`ferImg ${
                      isScaled ? 'twift-up' : isExtraScaled ? 'twift-up-extra' : ''
                    } ${['tokenomif'].includes(visibleSection) || isImageViewerOpen ? 'ferleft' : ''}`}
                    src={imageMap[img]}
                    alt="Fer"
                    style={{
                      opacity: imageOpacities[img],
                      width: '100%',
                      height: '100%',
                      zIndex: index
                    }}
                  />
                ))}
              </div>

              {/* Fign Image */}
              <img
                draggable="false"
                className={`fignImg ${
                  isExtraScaled ? 'scale-up-extra' : isScaled ? 'scale-up' : ''
                }`}
                src={currentFignImage}
                alt="Sign"
              />

              {/* Button Holder */}
              <div
                className={`buttonholder ${
                  isExtraScaled ? 'scale-up-extra' : isScaled ? 'scale-up' : ''
                } ${buttonHolderClass}`}
              >
                {/* SignIn Section */}
                {visibleSection === 'signIn' && (
                  <>
                    {!isConnected ? (
                      <div style={{ zIndex: '99999999999999999999' }} className="prefalefer">
                        Pleafe connect wallet, Fer
                      </div>
                    ) : (
                      <div style={{ zIndex: '99999999999999999999' }} className="prefalefer">
                        And juft a "fign-in", Fer. For fecurity...
                      </div>
                    )}
                    <CurtainComponent startAnimation={false} standby={true} />
                 
                  </>
                )}

                {/* Prefale Section */}
                {visibleSection === 'prefale' && contractsReady && !presaleEnded && (
                  <BuyTokens setIsImageViewerOpen={setIsImageViewerOpen} presaleActive={presaleActive} presaleEnded={presaleEnded} key={buyTokensKey} />
                )}

                {/* Post Presale Dashboard */}
                {visibleSection === 'prefale' && contractsReady && presaleEnded && (
                  <PostPresaleDashboard setIsImageViewerOpen={setIsImageViewerOpen} presaleActive={presaleActive} presaleEnded={presaleEnded} key={buyTokensKey} />
                )}

                {/* Tokenomics Section */}
                {visibleSection === 'tokenomif' && <TokenomicsChart />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer footerClass={imageHolderClass === 'hidden' ? 'hidden' : 'fade-in'} />
    </div>
  );
};

// Main Content Component Wrapped with Router
const Content = () => {
  return (
    <Router>
      <ContentWithRouter />
    </Router>
  );
};

export default Content;
